import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  classes: any;
  history: any;
  verifyIt: () => void;
  accountEmail?: string;
  alreadyExistsMsg?: boolean;
  showLoader?: boolean;
  goToLogIn: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  openPrivacyModal: boolean;
  openTermsModal: boolean;
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  phoneError: boolean;
  presigned_url: any;
  signupStep: string;
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  accountEmail: string;
  uploadRBError: boolean;
  uploadCBError: boolean;
  seconds: number;
  minutes: number;
  loading: boolean;
  alreadyExistsMsg: boolean;
  isOtpExpired: boolean;
  // Customizable Area End
  fileName: any;
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  presignedURLApiCallId: string;
  resumeUploadApiCallId: string;
  coverLetterUploadApiCallId: string;
  createAccountWithEmailApiCallId: string;
  otpVerifyApiCallId: string;
  getProfileDataCallId: string;
  sendOTPtoEmailURLApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      openTermsModal: false,
      openPrivacyModal: false,
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableConfirmPasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      phoneError: false,
      presigned_url: [],
      signupStep: "signup",
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      accountEmail: "",
      uploadRBError: false,
      uploadCBError: false,
      seconds: 0,
      minutes: 5,
      loading: false,
      alreadyExistsMsg: false,
      isOtpExpired: false,
      // Customizable Area End
      fileName: ""
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (responseJson !== undefined && !responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            const errors = responseJson?.errors;
            if (errors && errors[0]?.account) {
              this.openSnackBarHandler("error", errors[0]?.account);
            }
          }
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.presignedURLApiCallId) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ presigned_url: responseJson?.data });
          } else { }
        } else if (apiRequestCallId === this.resumeUploadApiCallId) {
          if (responseJson !== undefined && !responseJson.errors) {
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.coverLetterUploadApiCallId) {

          if (responseJson !== undefined && !responseJson.errors) { } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
          }
        } else if (apiRequestCallId === this.createAccountWithEmailApiCallId) {
          this.setState({ loading: false });
          if (responseJson !== undefined && !responseJson.error && !responseJson.errors) {
            sessionStorage.setItem("Token", responseJson?.meta?.token);
            sessionStorage.setItem('email', responseJson.data?.attributes.email);
            sessionStorage.setItem('first_name', responseJson.data?.attributes.first_name);
            sessionStorage.setItem('last_name', responseJson.data?.attributes.last_name);
            sessionStorage.setItem("profile_image", responseJson.data?.attributes.profile_picture);
            this.setState({ signupStep: "verifyEmail", accountEmail: responseJson?.data?.attributes?.email });
            this.openSnackBarHandler("success", configJSON.successLogin);
          } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            sessionStorage.clear();
            const error = responseJson?.error;
            const errors = responseJson?.errors;
            this.setState({ loading: false });

            let errorText = "";
            if (errors && errors.length) {
              errors.forEach((error: any) => {
                Object.keys(error).forEach((key) => {
                  errorText += `${error[key]}`;
                });
              });
              console.log("errorText", errorText);
              this.openSnackBarHandler("error", errorText);
            }
            // if (errors && errors[0]?.account) {
            //   this.setState({ alreadyExistsMsg: false });
            //   this.openSnackBarHandler("error", errors[0]?.account);
            // } else if (errors && errors.length && errors[0]?.phone_number) {
            //   this.openSnackBarHandler("error", `Phone Number ${errors[0]?.phone_number}`);
            // } 
            else if (error && error?.message) {
              if (error?.message == "already_existed") {
                sessionStorage.setItem("Token", responseJson?.meta?.token);
                this.openSnackBarHandler("warning", "Account already exists");
                this.setState({ signupStep: "verifyEmail", alreadyExistsMsg: true });
              } else {
                this.openSnackBarHandler("error", error?.message);
              }

            } else {
              this.openSnackBarHandler("error", "Please wait. Our team is working on it");
            }
          }
        } else if (apiRequestCallId === this.otpVerifyApiCallId) {
          this.setState({ loading: false });
          if (responseJson?.status != 500) {
            if (responseJson !== undefined && !responseJson.errors) {
              if (sessionStorage.getItem('user_role') == "admin") {
                if (responseJson?.message) {
                  this.openSnackBarHandler("error", responseJson?.message);
                } else {
                  sessionStorage.setItem('Token', responseJson.meta.token);
                  sessionStorage.setItem('final_OTP', responseJson.meta.token);
                  sessionStorage.setItem("setTime", new Date().toJSON());
                  this.openSnackBarHandler("success", "Log In successful");
                  // @ts-ignore
                  const wap_redirect_url = sessionStorage.getItem("wap_redirect_url");
                  if (wap_redirect_url) {
                    sessionStorage.removeItem("wap_redirect_url")
                  }
                  this.setState({ loading: false });
                  this.props?.history?.push(wap_redirect_url ? wap_redirect_url : "/clients-list");
                }
              } else {
                this.openSnackBarHandler("success", configJSON.successOTPVerify);
                if (!sessionStorage.getItem('user_role')) {
                  sessionStorage.setItem('user_role', "candidate");
                }
                this.getProfileData();
              }
            } else {
              if (sessionStorage.getItem('user_role') != "admin") {
                this.openSnackBarHandler("error", "Please Enter Correct OTP");
              }
              //Check Error Response
              // this.parseApiErrorResponse(responseJson);
            }
          } else {
            this.openSnackBarHandler("error", "Some Issue Has Occured. Our Team Is Looking Into It");
          }
        } else if (apiRequestCallId === this.getProfileDataCallId) {
          this.setState({ loading: false });
          if (responseJson !== undefined && !responseJson?.errors) {
            sessionStorage.setItem("first_name", responseJson?.data?.attributes?.full_name.split(" ")[0]);
            sessionStorage.setItem("last_name", responseJson?.data?.attributes?.full_name.split(" ")[1]);
            sessionStorage.setItem("email", responseJson?.data?.attributes?.email);
            sessionStorage.setItem("current_city", responseJson?.data?.attributes?.current_city);
            sessionStorage.setItem("phone_number", responseJson?.data?.attributes?.phone_number);
            sessionStorage.setItem("profile_image", responseJson?.data?.attributes?.photo);
            sessionStorage.setItem("resume", responseJson?.data?.attributes?.resume);

            setTimeout(() => { this.props.history.push("/profile-setting"); }, 1500);
          } else {
            this.openSnackBarHandler("error", "Some Issue Has Occured. Our Team Is Looking Into It.");
          }
        } else if (apiRequestCallId === this.sendOTPtoEmailURLApiCallId) {
          this.setState({ loading: false, signupStep: "otpVerify" });
          if (responseJson !== undefined && !responseJson.errors) {
            this.openSnackBarHandler("success", responseJson?.message);
          } else {
            //Check Error Response
            // this.parseApiErrorResponse(responseJson);
            this.setState({ loading: false });
            const error = responseJson?.error;
            const errors = responseJson?.errors;
            if (errors && errors[0]?.account) {
              this.openSnackBarHandler("error", errors[0]?.account);
            } else if (error && error?.message) {
              this.openSnackBarHandler("error", error?.message);
            } else {
              this.openSnackBarHandler("error", "Please wait. Our team is working on it");
            }
          }
        }
      }
    } else {
      // this.parseApiCatchErrorResponse(errorReponse);
      const errors = responseJson?.errors;
      if (errors && errors[0]?.account) {
        this.openSnackBarHandler("error", errors[0]?.account);
      } else if (errors && errors[0]?.message) {
        this.openSnackBarHandler("error", errors[0].message);
      } else if (errors && errors[0]?.properties) {
        this.openSnackBarHandler("error", errors[0].properties);
      }
    }
    // }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField, enableConfirmPasswordField: !this.state.enableConfirmPasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableConfirmPasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // To create presigned URL in backend 
  // Send two file names for resume and cover letter
  // Will return presigned URL and public URL
  doPresignedUrl(): boolean {
    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      file_names: ["resume.pdf", "coverLetter.pdf"]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.presignedURLApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.presignedURLAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To upload document
  uploadDocument(url: string, file: any, type: string): boolean {
    var reader = new FileReader();
    reader.onload = function () { };
    reader.readAsBinaryString(file);
    const header = {
      "Content-Type": "application/json"
    };

    const attrs = {
      file_names: ["resume.pdf", "coverLetter.pdf"]
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (type == "resume") {
      this.resumeUploadApiCallId = requestMessage.messageId;
    } else if (type == "coverLetter") {
      this.coverLetterUploadApiCallId = requestMessage.messageId;
    }


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reader.result
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To create candidate account with email
  doCreateAccountWithEmail(values: any, resume_url: string, coverLetter_url: string): boolean {
    const header = {
      // "Content-Type": "application/json"
    };
    let formData = new FormData();
    formData.append("resume", values.resume);
    formData.append("[data][type]", "email_account");
    formData.append("[data][user_type]", "candidate");
    formData.append("[data][attributes][email]", values.email);
    formData.append("[data][attributes][first_name]", values.first_name);
    formData.append("[data][attributes][last_name]", values.last_name);
    formData.append("[data][attributes][password]", values.password);
    formData.append("[data][attributes][password_confirmation]", values.repeat_pass);
    formData.append("[data][attributes][current_city]", values.city);
    formData.append("[data][attributes][user_role]", "candidate");
    formData.append("[data][attributes][phone_number]", values.phone_number);
    if (values.coverLetter) {
      formData.append("[data][attributes][cover_letter]", values.coverLetter);
    }
    const attributes = {
      email: values.email,
      phone_number: values.phone_number,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      password_confirmation: values.repeat_pass,
      current_city: values.city,
      resume_url: resume_url,
      cover_letter_url: coverLetter_url ? coverLetter_url : "",
      user_role: "candidate"
    }

    const data = {
      type: "email_account",
      user_type: "candidate",
      attributes: attributes
    }
    const httpBody = {
      data: data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountWithEmailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountWithEmailAPIEndPOINT
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    this.setState({ loading: true, accountEmail: values.email });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To verify OTP
  doOTPVerification(values: any): boolean {
    const header = {
      "Content-Type": "application/json",
      "token": sessionStorage.getItem("Token")
    };

    const httpBody = {
      otp: +values
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.otpVerifyApiCallId = requestMessage.messageId;


    let endPoint;
    if (sessionStorage.getItem("user_role") == "admin") {
      endPoint = configJSON.adminOtpVerificationAPIEndPOINT;
    } else {
      endPoint = configJSON.otpVerificationAPIEndPOINT;
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );
    this.setState({ loading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }



  // To validate uploaded file size
  fileHandler = (event: any, setFieldValue: any, type: string) => {
    let file = event?.target?.files[0];
    if (type == "resume") {
      if (file.type.split('/')[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type.split('/')[1] === "pdf" ||
        file.type.split('/')[1] === "msword" ||
        file.type.split('/')[1] === "rtf") {
        this.setState({ fileName: event?.target?.files[0].name });
      }
      else {
        this.openSnackBarHandler("error", "only .doc, .docx, .pdf, .rtf is accepted.");
      }
    }
    else if (type == "coverLetter") {
      if (file.type.split('/')[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type.split('/')[1] === "pdf" ||
        file.type.split('/')[1] === "msword" ||
        file.type.split('/')[1] === "rtf") {

      }
      else {
        this.openSnackBarHandler("error", "only .doc, .docx, .pdf, .rtf is accepted.");
      }

    };

    let size = file?.size / 1000000;
    if (size > 2) {
      if (type == "resume") this.setState({ uploadRBError: true });
      else if (type == "coverLetter") this.setState({ uploadCBError: true });

    } else {
      if (type == "resume") this.setState({ uploadRBError: false });
      else if (type == "coverLetter") this.setState({ uploadCBError: false });
      setFieldValue(type, file);
    }
  }

  // To handle signup flow steps
  handleSteps = (type: string) => this.setState({ signupStep: type })

  // To send OTP to email and redirect to OTP verification screen
  handleToOtpScreen = () => {
    // this.setState({ signupStep: "otpVerify" });
    this.sendOTPtoEmail();
  }

  // To handle different key fuctions in OTP input fields
  inputfocus = (event: any) => {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (event.key === "Delete" || event.key === "Backspace") {
      const next = event.target.tabIndex - 2;
      if (next > -1) {

        event.target.form.elements[next].focus()
      }
    } else {
      if (event.key == "Tab") {
        const next = event.target.tabIndex - 1;
        if (next < 6) {
          event.target.form.elements[next].focus()
        }
      } else if (regex.test(key)) {
        const next = event.target.tabIndex;
        if (next < 6) {
          event.target.form.elements[next].focus()
        }
      }
    }
  }

  // To handle 5 min OTP timer
  otpTimer = () => {
    let myInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState(prev => ({ seconds: prev.seconds - 1 }));
      }
      if (this.state.seconds === 0) {
        if (this.state.minutes === 0) {
          clearInterval(myInterval);
          this.setState({ isOtpExpired: true });
        } else {
          this.setState(prev => ({ minutes: prev.minutes - 1 }));
          this.setState({ seconds: 59 });
        }
      }
    }, 1000);
  }

  // To send OTP to candidate email
  sendOTPtoEmail(): boolean {
    const header = {
      "Content-Type": "application/json",
      "token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOTPtoEmailURLApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOTPtoEmailURLAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    // requestMessage.addData(
    //   getName(MessageEnum.RestAPIRequestBodyMessage),
    //   JSON.stringify(attrs)
    // );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodTypeAddDetail
    );
    this.setState({ loading: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get candidate profile data
  getProfileData = () => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const httpBody = {
      token: sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDataAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
