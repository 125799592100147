import React from "react";

import { Box, Card, CardContent, Container, Typography, withStyles, createTheme, ThemeProvider } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import Analytics3Controller, { Props, configJSON } from "./Analytics3Controller";
import { createMarkup } from "../../LandingPage2/src/LandingPage2.web";

const muiStyles = (theme: any) => ({
  blogBackground: {
    backgroundColor: '#f8f8f8',
  },
  container: {
    maxWidth: '88%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    }
  },

  heading: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    }
  },

  cardContent: {
    paddingLeft: "20px",
    paddingRight: "20px",

    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },

  details: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: "1rem",
    },

    "& h6": {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',

        "&:last-child": {
          marginLeft: 'auto',
        }
      }
    }
  },

  mainContent: {
    maxWidth: "75%",
    textAlign: "justify",

    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    }
  }
});

const BlogTheme = createTheme({
  typography: {
    h1: {
      fontSize: '45px',
      fontFamily: 'Poppins-Medium'
    },
    subtitle1: {
      fontFamily: 'Poppins-Italic',
      fontSize: 18
    },
    subtitle2: {
      fontFamily: 'Poppins-Medium-Italic',
      fontSize: 18,
    },
    h2: {
      fontFamily: "Poppins-Regular",
      fontSize: "20px",
      lineHeight: "30px"
    }
  },
  palette: {
    primary: {
      main: "#23325d"
    },
    secondary: {
      main: "#8b8a92"
    },
    text: {
      primary: "#363636"
    }
  },
  overrides: {
    MuiCard: {
      root: {
        borderRadius: "30px",
        boxShadow: "none"
      }
    }
  }
})

class Analytics3 extends Analytics3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Scroll to TOP
    window.scrollTo(0, 0);
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;
    const contData = this.props?.history?.location?.state?.contentData;

    if(!contData) {
      this.props.history.push('/');
      return null;
    };

    return (
      // Customizable Area Start
      <ThemeProvider theme={BlogTheme}>
        <Box pt={3} pb={3} className={classes.blogBackground}>
          <Container className={classes.container}>
            {/* Blog */}
            <Card>
              <CardContent>
                <Box pt={5} pb={5} className={classes.cardContent}>
                  <Typography variant="h1" color="primary" className={classes.heading}>
                    {contData.headline}
                  </Typography>
                  <Box mt={5}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    fontStyle="italic"
                    className={classes.details}
                  >
                    <Typography variant="subtitle1" style={{ color: "lightslategray" }}>
                      Written by Ashutosh Gupta
                    </Typography>
                    <Typography variant="subtitle2" style={{ color: "darkgray" }}>
                      &nbsp; &nbsp;Posted on 22/12/2021
                    </Typography>
                  </Box>
                  <Box mt={6}>
                    <Container className={classes.mainContent}>
                      <Typography variant="h2" color="textPrimary">
                        <div dangerouslySetInnerHTML={createMarkup(contData.content)} />
                      </Typography>
                    </Container>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(withRouter(Analytics3));
// Customizable Area End
