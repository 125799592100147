// Customize Area Start
import { Box, Button, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import DetailTabs from './DetailsTabs/DetailTabs.web';
import DetailCandidateStyles from './Styles.module.scss';
const { baseURL } = require("../../../framework/src/config");
// Customize Area End
// Customize Area Start
interface Detail {
    candidateData: any;
}
// Customize Area End

// Customize Area Start
const PROD_URL = "https://admin.xcelyst.ai";
const FRONTEND_URL = "https://www.xcelyst.ai";
class DetailCandidateParent extends React.Component<Detail>{
    constructor(props: Detail) {
        super(props);
    }

    render() {
        // Customize Area Start
        let currentPositionData, currentCompanyData, currentLocationData;
        let previousPositions: any = [];
        let otherJobs: any = [];
        let currentJobData: any = [];
        currentLocationData = this.props.candidateData?.location;
        this.props.candidateData?.temporary_user_profile?.work_experience?.map((item: any) => {
            if (item?.current) {
                if (item?.position) {
                    currentPositionData = item?.position;
                    currentCompanyData = item?.company;
                    let start = moment(item.started?.split("T")?.[0], 'YYYY/MM/DD');
                    let startMonYear = start.format('MMM') + " " + start.format("YYYY");
                    currentJobData = [{ ...item, started: startMonYear, ended: "Current time" }];
                } else {
                    currentPositionData = "-";
                }
            } else {
                if (item?.position) {
                    previousPositions.push(`${item?.position}${item.company ? " at " + item.company : ""}`);

                    let start = moment(item.started?.split("T")?.[0], 'YYYY/MM/DD');
                    let startMonYear = start.format('MMM') + " " + start.format("YYYY");
                    let end = moment(item.ended?.split('T')?.[0], 'YYYY/MM/DD');
                    let endMonYear = end.format('MMM') + " " + end.format("YYYY");
                    let endjob = { ...item, started: startMonYear, ended: endMonYear };
                    otherJobs.push(endjob);
                }
            }
        });
        let allJobs = [...currentJobData, ...otherJobs];


        return (
            <Box className={`${DetailCandidateStyles.card} ${DetailCandidateStyles.candidateDetailFromListWrap}`}>
                <Grid container className={DetailCandidateStyles.detailWrap}>
                    <Grid item style={{ width: "100%" }}>
                        <Box className={DetailCandidateStyles.headerTitle} mb={2} display='flex' justifyContent='space-between'>
                            <Typography variant='h5'>{this.props.candidateData?.full_name || '-'}</Typography>
                            <a
                                style={{ textDecoration: "none" }}
                                href={`${window.location.origin === FRONTEND_URL ? PROD_URL : baseURL}/bx_block_database/download_pdf/${this.props.candidateData?.temporary_user_profile?.temporary_user_database_id}.pdf`}
                                target='_blank'
                                download
                            >
                                <Button className={DetailCandidateStyles.darkButton}>Download PDF</Button>
                            </a>
                        </Box>
                        {currentPositionData && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Position:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {currentPositionData}
                                </Typography>
                            </Box>
                        </Box>}
                        {currentCompanyData && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Company:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {currentCompanyData}
                                </Typography>
                            </Box>
                        </Box>
                        }
                        {this.props.candidateData?.experience?.trim() && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Experience:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props.candidateData?.experience} years
                                </Typography>
                            </Box>
                        </Box>
                        }
                        {this.props.candidateData?.degree && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Degree:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props.candidateData?.degree}
                                </Typography>
                            </Box>
                        </Box>
                        }
                        {/* {this.props.candidateData?.job_projects && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Job Projects:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props.candidateData?.job_projects}
                                </Typography>
                            </Box>
                        </Box>
                        } */}

                        {this.props.candidateData?.linkedin_url && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Linkdin:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props.candidateData?.linkedin_url }
                                </Typography>
                            </Box>
                        </Box>}
                        {this.props.candidateData?.score && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Xcelyst AI Score:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props.candidateData?.score}
                                </Typography>
                            </Box>
                        </Box>}
                        {currentLocationData && <Box display="flex" className={DetailCandidateStyles.detailData} mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>location:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {currentLocationData}
                                </Typography>
                            </Box>
                        </Box>}
                    </Grid>
                    {/* <Grid item lg={12}>
                        <Box display='flex' alignItems='center' justifyContent='start' mb={3} >
                            <Typography className={`${DetailCandidateStyles.detailTitle} ${DetailCandidateStyles.marginRight}`}>Job Projects:</Typography>
                            <Button className={DetailCandidateStyles.actionButton}>Create a Project</Button>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='start' mb={3} >
                            <Typography className={`${DetailCandidateStyles.detailTitle} ${DetailCandidateStyles.marginRight}`}>Lead Lists:</Typography>
                            <Button className={DetailCandidateStyles.actionButton}>Add a Lead List</Button>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='start' mb={3} >
                            <Button className={DetailCandidateStyles.actionButton}>Send Email</Button>
                            <Button className={DetailCandidateStyles.actionButton}>Export PDF</Button>
                        </Box>
                    </Grid> */}
                </Grid>
                <Box className={DetailCandidateStyles.tabWrap}>
                    {this.props.candidateData && <DetailTabs candidateData={this.props.candidateData} allJobExperiences={allJobs} />}
                </Box>
            </Box>
        );
        // Customize Area End
    }
}
// Customize Area End
// Customize Area Start
export default DetailCandidateParent;
// Customize Area End
