import React from "react";
// Customizable Area Start
import { Container, Box, Typography, withStyles, Button, Grid, Modal, TextField, Select, MenuItem, makeStyles } from "@material-ui/core";
import { Formik } from "formik";
import QuestionBankController, { Props } from "./QuestionBankController.web";
import 'react-phone-number-input/style.css'
import { Location, SalaryImg, calenderImg } from './assets';
// Customizable Area End

// Customizable Area Start
const muiStyles = (theme: any) => ({
    textWhite: {
        color: "#ffffff"
    },
    button: {
        color: "#ffffff",
        borderColor: "#ffffff",
        borderRadius: 7,
        padding: "1rem 2rem"
    },
    candidateArea: {
        [theme.breakpoints.down('xs')]: {
            marginTop: "3rem",
        },
    },
    loginBtn: {
        marginRight: "1rem"
    },
    featureIcons: {
        margin: "30px auto",
        display: "flex"
    },
    featureTxt: {
        lineHeight: "1.6rem",
        fontWeight: 400,
        marginTop: "1.2rem",
        marginBottom: "1.2rem"
    },
    testimonialsContainer: {
        backgroundColor: theme.palette.secondary.main + '30'
    },
    clientTxt: {
        color: "#000",
        lineHeight: "1.8rem"
    },
    clientDes: {
        marginTop: "1.1rem",
        fontStyle: "italic",
        fontSize: "0.8rem",
        fontWeight: 500
    },
    clientName: {
        fontSize: "1rem",
    },
    demo: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: "center",
        },
    },
    separatorBox: {
        justifyContent: "center",
        display: "flex"
    },
    separator: {
        height: "100%",
        width: 1,
        backgroundColor: theme.palette.secondary.main
    },
    playIcon: {
        width: 64,
        height: 64,
        cursor: 'pointer'
    },
    player: {
        borderRadius: "30px"
    },
    overlay: {
        border: "20px solid #ececec",
        borderRadius: "70px",
        top: "-21px",
        left: "-20px",
    },
    Bgrey: {
        backgroundColor: "#ececec"
    },
    link: {
        textDecoration: 'none'
    },
    phoneNumberContainer: {
        "& .PhoneInputInput": {
            border: 0,
            borderBottom: "1px solid #949494",
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "rgb(125, 124, 132)",
            letterSpacing: "0.15008px"
        }
    },
    fullBtnCancel: {
        backgroundColor: "#EBEBEB",
        marginRight: "0.5em"
    },
    fullBtnSubmit: {
        marginLeft: "0.5em"
    },
    modalContainer: {
        top: "50%",
        left: "50%",
        width: "37.125em",
        padding: "32px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
        borderRadius: "10px",
        background: "#fff",

        [theme.breakpoints.down('sm')]: {
            width: "22.5rem",
        }
    }
});

// placeholder for month 
const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#d4d4d7",
        marginLeft: 5
    }
}));

const Placeholder = ({ children }: any) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};
// Customizable Area End

// Customizable Area End
class EditOtherDetailModal extends QuestionBankController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    allDropdown = (n: number) => {
        let data = [];
        for (let i = 0; i <= n; i++) {
            data.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return data;
    }
    // Customizable Area End

    crrData: any = Object.values(this.props.currancyData);

    render() {
        const { classes } = this.props;
        return (
            <Modal
                open={this.props.showEditOtherDetailModal}
                onClose={this.props.handleCloseEditOtherDetailModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalContainer}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box flex="1">
                            <Typography color="primary" align="center" variant="h5">
                                Edit Other Details
                            </Typography>
                        </Box>
                        {/* <IconButton onClick={this.props.handleCloseEditOtherDetailModal} aria-label="Close">
                            <CloseIcon />
                        </IconButton> */}
                    </Box>
                    <Container maxWidth="sm">
                        <Box>
                            <Formik
                                initialValues={{
                                    currency: this.props.profileData?.currency || "",
                                    current_compensation: this.props.profileData?.other_details?.current_compensation || "",
                                    expected_compensation: this.props.profileData?.other_details?.expected_compensation || "",
                                    months: this.props.profileData?.other_details?.notice_period?.month,
                                    location_preference: this.props.profileData?.other_details?.location_preference?.join(',') || "",
                                    days: this.props.profileData?.other_details?.notice_period?.data,
                                    preferred_role_ids: this.props.profileData?.preferred_role_ids

                                }}
                                // validationSchema={this.state.updateOtherDetailValidationSchema}
                                onSubmit={(values: any) => {
                                    let formatLoc = values.location_preference.trim().split(",");
                                    const currency = values.currency;
                                    const current_compensation = values.current_compensation;
                                    const expected_compensation = values.expected_compensation;
                                    this.props.sendRequestForUpdateOtherDetail({ ...values, location_preference: formatLoc, current_compensation, expected_compensation, currency }, "other")
                                }}>
                                {(formikProps) => (
                                    <form onSubmit={formikProps.handleSubmit}>
                                        <Box my={5}>
                                            <Select
                                                className="calenderImg-Select-data"
                                                style={{ width: "100%" }}
                                                id="currency"
                                                name="currency"
                                                onChange={(e) => {
                                                    formikProps.setFieldValue("currency", e.target.value);
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.currency}
                                                error={formikProps.touched.currency && Boolean(formikProps.errors.currency)}
                                                inputProps={{
                                                    startadornment: <img width="16px" style={{ marginRight: 15 }} src={calenderImg} />,
                                                }}
                                                placeholder="Currency"
                                                displayEmpty={true}
                                                renderValue={value => value || value === 0 ? formikProps.values.currency : <Placeholder>Currency</Placeholder>}
                                            >
                                                {this.crrData?.map((i: any) => (<MenuItem value={i}>{i}</MenuItem>))}
                                            </Select>
                                        </Box>
                                        <Box my={5}>
                                            <TextField
                                                style={{ width: "100%" }}
                                                type="text"
                                                id="current_compensation"
                                                name="current_compensation"
                                                onChange={(e: any) => {
                                                    const regex = /^[0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        formikProps.setFieldValue('current_compensation', e.target.value)
                                                    }
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.current_compensation}
                                                error={formikProps.touched.current_compensation && Boolean(formikProps.errors.current_compensation)}
                                                helperText={"Enter your per annum current compensation"}
                                                InputProps={{
                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={SalaryImg} />,
                                                }}
                                                inputProps={{ maxLength: 10 }}
                                                placeholder="Current Compensation" />
                                        </Box>
                                        <Box my={5}>
                                            <TextField
                                                style={{ width: "100%" }}
                                                id="expected_compensation"
                                                type="text"
                                                name="expected_compensation"
                                                onChange={(e: any) => {
                                                    const regex = /^[0-9]*$/;
                                                    if (e.target.value === "" || regex.test(e.target.value)) {
                                                        formikProps.setFieldValue('expected_compensation', e.target.value)
                                                    }
                                                }}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.expected_compensation}
                                                error={formikProps.touched.expected_compensation && Boolean(formikProps.errors.expected_compensation)}
                                                helperText={"Enter your per annum expected compensation"}
                                                InputProps={{
                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={SalaryImg} />,
                                                }}
                                                inputProps={{
                                                    maxLength: 10
                                                }}
                                                placeholder="Expected Compensation" />
                                        </Box>
                                        <Box my={5}>
                                            <TextField
                                                className={classes.message}
                                                style={{ width: "100%" }}
                                                id="location_preference"
                                                name="location_preference"
                                                onChange={formikProps.handleChange}
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.location_preference}
                                                error={formikProps.touched.location_preference && Boolean(formikProps.errors.location_preference)}
                                                helperText={formikProps.touched.location_preference && formikProps.errors.location_preference}
                                                InputProps={{
                                                    startAdornment: <img width="16px" style={{ marginRight: 15 }} src={Location} />,
                                                }}
                                                placeholder="Location Preference" />
                                        </Box>
                                        <Box my={5}>
                                            <p className="notice-p">Notice Period</p>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6} className="calenderSelect-Wrap">
                                                    <img width="16px" className="calenderImg-icon" src={calenderImg} />
                                                    <Select
                                                        className="calenderImg-Select-data"
                                                        style={{ width: "100%" }}
                                                        id="months"
                                                        name="months"
                                                        onChange={(e) => {
                                                            formikProps.setFieldValue("months", e.target.value);
                                                        }}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.months}
                                                        error={formikProps.touched.months && Boolean(formikProps.errors.months)}
                                                        inputProps={{
                                                            startadornment: <img width="16px" style={{ marginRight: 15 }} src={calenderImg} />,
                                                        }}
                                                        placeholder="Months"
                                                        displayEmpty={true}
                                                        renderValue={value => value || value === 0 ? formikProps.values.months : <Placeholder>Months</Placeholder>}

                                                    >
                                                        {this.allDropdown(12)}
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={6} className="calenderSelect-Wrap">
                                                    <img width="16px" className="calenderImg-icon" src={calenderImg} />
                                                    <Select
                                                        className="calenderImg-Select-data"
                                                        style={{ width: "100%" }}
                                                        id="days"
                                                        name="days"
                                                        onChange={(e) => { formikProps.setFieldValue("days", e.target.value); }}
                                                        onBlur={formikProps.handleBlur}
                                                        value={formikProps.values.days}
                                                        error={formikProps.touched.days && Boolean(formikProps.errors.days)}
                                                        inputProps={{
                                                            startadornment: <img width="16px" style={{ marginRight: 15 }} src={calenderImg} />,
                                                        }}
                                                        placeholder="Days"
                                                        displayEmpty={true}
                                                        renderValue={value => value || value === 0 ? formikProps.values.days : <Placeholder>Days</Placeholder>}
                                                    >
                                                        {this.allDropdown(30)}
                                                    </Select>
                                                </Grid>
                                            </Grid>
                                        </Box>


                                        <Box justifyContent="center" display="flex">
                                            <Button
                                                type="button" className={classes.fullBtnCancel} disableRipple variant="contained" color="secondary" onClick={this.props.handleCloseEditOtherDetailModal}>
                                                <Typography variant="body2">
                                                    Cancel
                                                </Typography>
                                            </Button>
                                            <Button
                                                type="submit" className={classes.fullBtnSubmit} disableRipple variant="contained" color="primary">
                                                <Typography variant="body2">
                                                    Save Changes
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Container>
                </Box >
            </Modal >
        );
    }
}

// Customizable Area Start
// @ts-ignore
export default withStyles(muiStyles)(EditOtherDetailModal)
// Customizable Area End
