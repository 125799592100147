Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getDatabaseListAPiEndPoint = "bx_block_database/temporary_user_databases";
exports.watchedCandidateDataAPiEndPoint = 'bx_block_database/watched_records';
exports.getFilterSuggessionListAPiEndPoint = 'bx_block_database/suggectiones';
exports.getJobFilterSuggessionListAPiEndPoint = 'bx_block_job/suggestions'
exports.getJobListAPiEndPoint = "/bx_block_job/job_database";
exports.advanceSearchApiEndPoint = "bx_block_job/get_candidates_info"
// Customizable Area End