import React, { Component } from "react";
import { Box, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const muiStyles = (theme: Theme) => ({
    container: {
        background: "#fff",
        padding: "1rem",
        borderRadius: "1rem",
        cursor: "pointer",
        boxShadow: "0 1px 4px #0003",
        // width: '20%',

        "& img": {
            width: "3rem",
            height: "3rem",
            borderRadius: ".4rem",
        },

        "& .empty_img": {
            width: "3rem",
            height: "3rem",
        },

        "&:last-child": {
            marginBottom: "3px",
        },

        "& h5": {
            margin: "0 0 .5rem",
            fontSize: "1rem",
        },

        "& span": {
            fontSize: "0.8rem",
            color: "#96969f",
        },

        "& button": {
            padding: ".5rem 2rem",
            margin: "1rem 0",
            borderRadius: ".8rem",

            "& span": {
                color: "#fff",
                fontWeight: "bold",
                fontSize: "0.65rem",
            }
        },

        "& .title": {
            fontSize: ".9rem",
            fontWeight: "bold",
            width: "2rem",
        },

        "& .more": {
            marginTop: ".2rem",
            fontSize: ".8rem",
            color: theme.palette.primary.main
        },

        "&.selectedCandidateSt": {
            border: "4px solid #1049ff45"
        },
        "& .watched-datab-data": {
            background: "#283662",
            color: "#fff",
            padding: "1px 4px",
            borderRadius: "4px",
            height: "1.5em"
        },
        "& .tagsWrap": {
            display: "flex"
        },
        "& .chip": {
            width: "33%",
            borderRadius: ".2rem",
            marginRight: ".5rem",
            marginBottom: ".5rem",
            background: '#F1F1F5',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    }
});

interface Props {
    classes: any;
    // candidateData: CDprops[];
    candidateData: any;
    isLoggedIn?: boolean;
    marginBottom?: number;
    showEmptyImg?: boolean;
    isSelectedCan?: boolean;
    sendSelectedCandidateData: (data: any) => void;
}
export interface CardState {
    skillsShowAll: boolean;
    prevShowAll: boolean;
    selectedCanID: any;
}

class JobsCard extends Component<Props, CardState> {
    state: CardState = {
        skillsShowAll: false,
        prevShowAll: false,
        selectedCanID: null
    };
    render() {
        const { classes } = this.props;

        return (
            <>
                <Box
                    className={`${classes.container} ${this.props.isSelectedCan ? "selectedCandidateSt" : ""}`}
                    mb={this.props.marginBottom}
                    onClick={() => {
                        this.props.sendSelectedCandidateData(this.props.candidateData);
                        this.setState({ selectedCanID: this.props.candidateData?.id });
                    }}>

                    <Box display='flex' >
                        <Box sx={{ marginTop: "8px" }}>
                            <Typography variant="h4" style={{ fontSize: 18 }} >
                                {this.props.candidateData?.attributes?.job_title || '-'}
                            </Typography>
                            <Box mt={2}>
                                <Box style={{ marginBottom: "5px" }}>
                                    <Typography component='span' style={{ fontSize: "0.9rem" }} >
                                        {this.props?.candidateData?.attributes?.company_name ?
                                            this.props?.candidateData?.attributes?.company_name === "null" ? "" : this.props?.candidateData?.attributes?.company_name
                                            : ""}
                                    </Typography>
                                </Box>
                                <Box style={{ marginBottom: "5px" }}>
                                    <Typography component='span' style={{ fontSize: "0.9rem" }} >
                                        {this.props?.candidateData?.attributes?.area_domain ?
                                            this.props?.candidateData?.attributes?.area_domain === "null" ? "" : this.props?.candidateData?.attributes?.area_domain
                                            : ""}
                                    </Typography>
                                </Box>
                                <Box style={{ marginBottom: "5px" }}>
                                    <Typography component='span' style={{ fontSize: "0.9rem" }}>
                                        {this.props?.candidateData?.attributes?.location ?
                                            this.props?.candidateData?.attributes?.location === "null" ? "" : this.props?.candidateData?.attributes?.location
                                            : ""}  {this.props?.candidateData?.attributes?.employment ?
                                                this.props?.candidateData?.attributes?.employment === "null" ? "" : "- " + this.props?.candidateData?.attributes?.employment
                                                : ""}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </>
        )

    }
}

export default withStyles(muiStyles)(JobsCard);