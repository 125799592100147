import React from "react";
import { Box, Card, CardContent, Typography, Chip, CardActions, Button } from "@material-ui/core";
import { createTheme, makeStyles, withStyles, createStyles, ThemeProvider } from "@material-ui/core/styles";
import { Favorite, FavoriteBorderOutlined, FavoriteOutlined } from '@material-ui/icons';
import { JobProps } from "../../blocks/JobListing2/src/mock/dummyJobs";
import { withRouter, Link } from "react-router-dom";
const useStyles = (theme: any) =>
  createStyles({
    ButtonMessage: {
      backgroundColor: "#F1F1F5",
      boxShadow: "none",
      borderRadius: ".8rem",
      fontWeight: 600,
      color: "#83838C",
      fontSize: "0.7em",
      textTransform: "capitalize",
      flex: 1,
      width: "47%",
    },
    ButtonApplyNow: {
      backgroundColor: "#24325F",
      boxShadow: "none",
      borderRadius: ".8rem",
      width: "47%",
      fontWeight: 500,
      fontSize: "0.7em",
      textTransform: "capitalize",
    },
    ButtonAlreadyApplied: {
      "&.Mui-disabled": {
        backgroundColor: "#fff",
        color: "#24325F",
        boxShadow: "none",
        borderRadius: ".8rem",
        width: "45%",
        fontWeight: 600,
        textTransform: "capitalize",
        border: "2px solid #24325F",
        padding: "0",

        "&:hover": {
          backgroundColor: "#fff",
        },

        "& span": {
          fontSize: ".8rem",
          ['@media (min-width: 1200px) and (max-width: 1280px)']: {
            fontSize: ".7rem",
          }
        },
      }
    },
    jobCard: {
      height: "100%",
      borderRadius: ".8rem",
      position: "relative"
    },
    jobCardData: {
      position: "relative",
      padding: "0 1em",
      height: "100%",
    },
    details: {
      padding: "1rem 0",

      "& h5": {
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }
    },
    cardContentDetails: {
      padding: 0,
    },
    salary: {
      fontSize: "1.1rem",
    },
    detailsDesc: {
      height: "3em"
    },
    tagsWrap: {
      display: "flex"
    },
    chip: {
      width: "33%",
      borderRadius: ".2rem",
      marginRight: ".5rem",
      marginBottom: ".5rem",
      background: '#F1F1F5',
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    favoriteIcon: {
      fill: "#24325F",
      background: "#F1F1F5",
      padding: ".4rem .5rem",
      borderRadius: ".8rem",
      cursor: "pointer",
      transition: "all .3s ease-in-out",
      flex: 1,
      width: "100%",
      "&:hover": {
        fill: "#3f51b5",
      }
    },
    action: {
      paddingLeft: 0,
      alignItems: "unset",
      paddingBottom: "1em"
    },

  }
  );

const theme = createTheme({
  typography: {
    h5: {
      fontSize: 20,
      fontFamily: "Poppins-SemiBold",
    },
    body1: {
      fontFamily: "Roboto-Regular",
      fontSize: 15,
      color: "#7b7b7b",
      textAlign: "left",
      fontWeight: 500,
    },
    button: {
      fontFamily: "Poppins-Regular",
    },
  },
});

const tagStyles = makeStyles((theme: any) =>
  createStyles({
    chip: {
      borderRadius: ".2rem",
      marginRight: ".5rem",
      marginBottom: ".5rem",
      background: '#F1F1F5'
    },
  })
);

interface TagsProps {
  tags: string[];
}

export const Tags: React.FC<TagsProps> = ({ tags }) => {
  const classes = tagStyles();

  return (
    <Box>
      {tags.map((tag: string) => (
        <Chip key={tag} className={classes.chip} size="small" label={tag} clickable />
      ))}
    </Box>
  );
};

interface ApplyJobProps {
  applied_job: {}
}
interface JobCardProps {
  job: any;
  showFavorite?: boolean;
  showSalary?: boolean;
  isAppliedForJob: (data: ApplyJobProps) => void;
  handleFavorite: (data: { role_id: number, favourite: boolean }) => void;
  classes?: any;
  history?: any;
  location: any;
  sendEmail: (email: string) => void;
}
interface JobCardStates {
  isApplied: boolean,
  isFavorite: boolean,
}

class JobsCards extends React.Component<JobCardProps, JobCardStates> {
  constructor(props: JobCardProps) {
    super(props);
    this.state = {
      isApplied: this.props?.job?.attributes?.applied,
      isFavorite: false,
    }
  }

  handleFavourite = () => {
    // send the data to the server and update the state.
    this.setState(prev => ({ ...prev, isFavorite: !prev.isFavorite }), () => {
      this.props.handleFavorite(
        { role_id: this.props?.job?.id, favourite: this.state.isFavorite }
      );
    });
  };

  handleRealTimeFavorite = (isFavorite: boolean) => {
    this.setState(prev => ({ ...prev, isFavorite: !isFavorite }), () => {
      this.props.handleFavorite(
        { role_id: this.props?.job?.id, favourite: !isFavorite }
      );
    });
  }

  handleApply = () => {
    // send the data to the server and update the state
    this.setState(prev => ({ ...prev, isApplied: !prev.isApplied }));
    const jobData = {
      applied_job: {
        job_description_id: this.props?.job?.attributes?.job_description?.job_description_id
      }
    }
    this.props.isAppliedForJob(jobData);
  }

  componentDidMount() {
    this.setState({ isFavorite: this.props?.job?.attributes?.saved });
  }

  render = () => {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Card variant="outlined" className={classes.jobCard}>
          <Box className={classes.jobCardData}>
            <CardContent className={classes.cardContentDetails}>
              <Box className={classes.details}>
                {/* <Link to={`/job-detail/${this.props.job?.id}`} style={{ textDecoration: "none", color: "#24325F" }}> */}
                <Typography className={classes.detailsName} variant="h5" gutterBottom onClick={() => {
                  this.props.history.push(`/job-detail/${this.props.job?.id}`)
                }}>
                  {this.props?.job?.attributes?.name}
                </Typography>
                {/* </Link> */}
                {this.props.showSalary && (
                  <Typography variant="body2" gutterBottom className={classes.salary}>
                    {this.props?.job?.attributes?.company_name || "N/A"} - {this.props?.job?.attributes?.job_description?.minimum_salary || 0} {this.props?.job?.attributes?.job_description?.currency}
                  </Typography>
                )}
                <Typography variant="body1" gutterBottom className={classes.detailsDesc}>
                  {this.props?.job?.attributes?.job_description?.company_description ?
                    this.props?.job?.attributes?.job_description?.company_description?.slice(0, 50) + "..."
                    : "-"}
                </Typography>
                <Box className={classes.tagsWrap}>
                  <Chip className={classes.chip} size="small" label={this.props?.job?.attributes?.tags[0] || "-"} title={this.props?.job?.attributes?.tags[0] || ""} />
                  <Chip className={classes.chip} size="small" label={`${this.props?.job?.attributes?.job_description?.preferred_overall_experience?.experiences_year || "0"} Years`} title={`${this.props?.job?.attributes?.job_description?.preferred_overall_experience?.experiences_year || "0"} Years`} />
                  {this.props?.job?.attributes?.job_description?.role_title && <Chip className={classes.chip} size="small" label={this.props?.job?.attributes?.job_description?.role_title || "-"} title={this.props?.job?.attributes?.job_description?.role_title || ""} />}
                </Box>
                {/* <Tags tags={this.props?.job?.attributes?.tags || []} /> */}
              </Box>
            </CardContent>
            <CardActions className={classes.action}>
              <Button
                size="medium"
                color="primary"
                variant="contained"
                className={`${classes.ButtonAlreadyApplied}`}
                // className={`${this.state.isApplied ? classes.ButtonAlreadyApplied : classes.ButtonApplyNow}`}
                // onClick={this.handleApply}
                // disabled={this.state.isApplied}
                disabled={true}
              >
                {"Applied"}
                {/* {this.state.isApplied ? "Applied" : "Apply Now"} */}
              </Button>
              <Button
                size="medium"
                variant="contained"
                className={classes.ButtonMessage}
                onClick={() => this.props.sendEmail(this.props.job.attributes.company_email)}
              >
                Message
              </Button>
              {
                this.props.history.location.pathname === "/profile-setting" ?
                  <Box onClick={() => { this.handleRealTimeFavorite(this.props.job.attributes.saved) }}>
                    {this.props.job.attributes.saved ?
                      <Favorite className={classes.favoriteIcon} /> :
                      <FavoriteBorderOutlined className={classes.favoriteIcon} />
                    }
                  </Box> :
                  <Box onClick={this.handleFavourite}>
                    {this.state.isFavorite ?
                      <Favorite className={classes.favoriteIcon} /> :
                      <FavoriteBorderOutlined className={classes.favoriteIcon} />
                    }
                  </Box>
              }
            </CardActions>
          </Box>
        </Card>
      </ThemeProvider>
    );
  }
}
//@ts-ignore
export default withRouter(withStyles(useStyles)(JobsCards)) as any;