import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import ProfileStyles from '../Styles.module.scss';
interface ProfileTabProps {
    candidateData: any;
    allJobExperiences: any;
}
class ProfileTab extends React.Component<ProfileTabProps>{
    state: any = {
        showAllskills: false
    };
    constructor(props: ProfileTabProps) {
        super(props);
    }

    render() {
        // certifcate
        let newArr: any = [];
        var certifcate = '';
        if (typeof this?.props?.candidateData?.temporary_user_profile?.certificates === "string") {
            certifcate = this?.props?.candidateData?.temporary_user_profile?.certificates;
        } else {
            let pj = this?.props?.candidateData?.temporary_user_profile?.certificates ?
                this?.props?.candidateData?.temporary_user_profile?.certificates?.map((o: any) => {
                    let j: any = [];
                    let z = o?.split(",");
                    let y = z?.map((q: any) => {
                        let we = q?.split("=>")
                        j?.push(we[1]?.replace(/[^a-zA-Z0-9 ]/g, ''));
                    })
                    newArr.push(j);
                })
                : null;
        }
        // course
        let newCourse: any = [];
        var courses = '';
        if (typeof this?.props?.candidateData?.temporary_user_profile?.courses === "string") {
            courses = this?.props?.candidateData?.temporary_user_profile?.courses;
        } else {
            let pj = this?.props?.candidateData?.temporary_user_profile?.courses ?
                this?.props?.candidateData?.temporary_user_profile?.courses?.map((o: any) => {
                    let j: any = [];
                    let z = o?.split(",");
                    let y = z?.map((q: any) => {
                        let we = q?.split("=>")
                        j?.push(we[1]?.replace(/[^a-zA-Z0-9 ]/g, ''));
                    })
                    newCourse.push(j);
                })
                : null;
        }
        // job
        let jobProject: any = [];
        var job_Project = '';
        if (typeof this?.props?.candidateData?.job_projects === "string") {
            job_Project = this?.props?.candidateData?.job_projects;
        } else {
            this?.props?.candidateData?.job_projects?.map((o: any) => {
                jobProject.push(o);
            })
        }


        let allSkills = this.props.candidateData?.temporary_user_profile?.skills ? [...this.props.candidateData?.temporary_user_profile?.skills] : [];
        return (
            <Box className={ProfileStyles.detailTabWrap}>
                <Box className={ProfileStyles.skillSec} mt={2}>
                    {allSkills?.length > 0 &&
                        <>  <Box mb={1}>
                            <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Skills</Typography>
                        </Box>
                            <Box className={ProfileStyles.skillChips} mb={2}>
                                {allSkills?.length > 0 ?
                                    [...allSkills]?.splice(0, this.state.showAllskills ? allSkills?.length : 10)?.map((item: any, index: number) =>
                                        (<Typography variant='caption' key={index}>{item}</Typography>))
                                    : "-"}

                                {(allSkills?.length > 0 && allSkills?.length > 10) && (
                                    <Typography variant='h6' className={ProfileStyles.skillMore} onClick={() => this.setState((prev: any) => ({ showAllskills: !prev.showAllskills }))}>
                                        {this.state.showAllskills ? "Show less" : `${allSkills?.length - 10} more`}
                                    </Typography>)}

                            </Box>
                        </>}
                    <Grid container>

                        {this.props.candidateData?.job_projects && <Box mb={2}>
                            <Box mb={2}>
                                <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}> Projects </Typography>
                            </Box>
                            <Box display="flex" className={ProfileStyles.tabDetailData}>
                                <Box pr={2}>
                                    {jobProject.length === 0
                                        ? <Typography className={ProfileStyles.tabDetailTitle} variant='body1' style={{ marginBottom: 4 }}>
                                            {job_Project}
                                        </Typography>
                                        :
                                        <>
                                            {jobProject?.map((work: any, index: number) => (
                                                <Box display="flex" className={ProfileStyles.tabDetailData} mb={2} key={index}>
                                                    <Grid container>
                                                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                                            <Box pr={2}>
                                                                <Typography className={ProfileStyles.tabDetailTitle}>{work?.startDate || "NA"} - {work?.endDate || "NA"}:</Typography>
                                                            </Box></Grid>
                                                        <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                                            <Box>
                                                                {work.name && <Typography variant='body1' className={ProfileStyles.tabDetailSubTitle}>
                                                                    {work?.name}
                                                                </Typography>}
                                                                {work.description && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                                    {work?.description}
                                                                </Typography>}
                                                                {work.url && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                                    {work?.url}
                                                                </Typography>}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            ))}
                                        </>
                                    }
                                </Box>
                            </Box>
                        </Box>}
                        {this.props.allJobExperiences?.length > 0 &&
                            <Grid item xs={12}>
                                <Box mb={2} display='flex'>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Work Experience</Typography>
                                    {/* <Typography variant='h6' className={ProfileStyles.detailAction}>+Add Experience</Typography> */}

                                </Box>
                                {this.props.allJobExperiences?.map((work: any, index: number) => (

                                    <Box display="flex" className={ProfileStyles.tabDetailData} mb={2} key={index}>
                                        <Grid container>
                                            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                                <Box pr={2}>
                                                    <Typography className={ProfileStyles.tabDetailTitle}>{work.started || "NA"} - {work.ended || "NA"}:</Typography>
                                                </Box></Grid>
                                            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                                                <Box>
                                                    {work.position && <Typography variant='h5' className={ProfileStyles.tabDetailSubTitle}>
                                                        {work.position}
                                                    </Typography>}
                                                    {work.company && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                        {work.company}
                                                    </Typography>}
                                                    {work.summary && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                        {work.summary}
                                                    </Typography>}
                                                    {work.location && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                        {work.location}
                                                    </Typography>}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Grid>}
                        <Grid item xs={12}>
                            {this.props.candidateData?.summary && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Summary</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        <Typography className={ProfileStyles.tabDetailTitle}>{this.props.candidateData?.summary}</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.head_line && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Head Line</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        <Typography className={ProfileStyles.tabDetailTitle}>{this.props.candidateData?.temporary_user_profile?.head_line}</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.courses && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Courses</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {newArr.length === 0
                                            ? <Typography className={ProfileStyles.tabDetailTitle} variant='body1' style={{ marginBottom: 4 }}>
                                                {courses}
                                            </Typography>
                                            :
                                            <>
                                                {
                                                    newCourse?.map((courses: any, index: number) => (
                                                        <Typography className={ProfileStyles.tabDetailTitle} variant='body1' key={index} style={{ marginBottom: 4 }}>
                                                            {courses[0] && "Name: " + courses[0]}
                                                            {courses[1] && <br /> + "License: " + courses[1]}
                                                            {courses[2] && <br /> + "Authority: " + courses[2]}
                                                        </Typography>))
                                                }
                                            </>
                                        }
                                    </Box>
                                </Box>
                            </Box>}
                        </Grid>
                        {this.props.candidateData?.temporary_user_profile?.education && <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Education</Typography>
                            </Box>
                            {this.props.candidateData?.temporary_user_profile?.education.map((educ: any, index: number) => (
                                <Box display="flex" className={ProfileStyles.tabDetailData} mb={2} key={index}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Box pr={2}>
                                                <Typography className={ProfileStyles.tabDetailTitle}>{educ.startedYear || "NA"} - {educ.endedYear || "NA"}:</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Box>
                                                {educ.university && <Typography variant='h5' className={ProfileStyles.tabDetailSubTitle}>
                                                    {educ.university}
                                                </Typography>}
                                                {educ.faculty && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                    {educ.faculty}
                                                </Typography>}
                                                {educ.degree && educ.degree.length > 0 && <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                    {educ.degree?.join(",")}
                                                </Typography>}
                                                {/* <Typography variant='body1' className={ProfileStyles.tabDetailSubValue}>
                                                Administration (MBA)
                                            </Typography> */}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>))
                            }
                        </Grid>}
                        <Grid item xs={12}>
                            {this.props.candidateData?.temporary_user_profile?.languages && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Languages</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.languages.map((language: any, index: number) => language?.name?.trim()?.length > 0 && (
                                            <Typography className={ProfileStyles.tabDetailTitle} key={index}>{language?.name} {language?.proficiency && " - "} {language?.proficiency}</Typography>))
                                        }
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.honor_awards?.length > 0 && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Honors & Awards</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.honor_awards.map((awards: any, index: number) => (
                                            <Typography className={ProfileStyles.tabDetailTitle} key={index}>{awards?.name} - {awards?.date}</Typography>))
                                        }
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.linkedin_url && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Social URL</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        <Typography className={ProfileStyles.tabDetailTitle} variant='caption'>{this.props.candidateData?.temporary_user_profile?.linkedin_url}</Typography>
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.certificates && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Certificates</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {newArr.length === 0
                                            ? <Typography className={ProfileStyles.tabDetailTitle} variant='body1' style={{ marginBottom: 4 }}>
                                                {certifcate}
                                            </Typography>
                                            :
                                            <>
                                                {
                                                    newArr?.map((certificate: any, index: number) => (
                                                        <Typography className={ProfileStyles.tabDetailTitle} variant='body1' key={index} style={{ marginBottom: 4 }}>
                                                            {certificate[0] && "Name: " + certificate[0]}<br />
                                                            {certificate[1] && "License: " + certificate[1]}<br />
                                                            {certificate[2] && "Authority: " + certificate[2]}
                                                        </Typography>))
                                                }
                                            </>
                                        }
                                    </Box>
                                </Box>
                            </Box>}
                            {this.props.candidateData?.temporary_user_profile?.organizations && <Box mb={2}>
                                <Box mb={2}>
                                    <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Organizations</Typography>
                                </Box>
                                <Box display="flex" className={ProfileStyles.tabDetailData}>
                                    <Box pr={2}>
                                        {this.props.candidateData?.temporary_user_profile?.organizations.map((organization: any, index: number) => organization.name?.trim().length > 0 && (
                                            <Typography className={ProfileStyles.tabDetailTitle} variant='caption' key={index}>{organization.name},&nbsp;{organization?.position}</Typography>))
                                        }
                                    </Box>
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

export default ProfileTab;