// Customize Area Start
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import { LocalAtmOutlined, WorkOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { LocationIcon } from '../../../components/src/assets';
import ProfileStyles from './Styles.module.scss';
import DetailTabs from './DetailsTabs/DetailTabs.web';
import DetailCandidateStyles from './Styles.module.scss';
const { baseURL } = require("../../../framework/src/config");

// Customize Area End
// Customize Area Start
interface Detail {
    candidateData: any;
}
// Customize Area End

// Customize Area Start
const PROD_URL = "https://admin.xcelyst.ai";
const FRONTEND_URL = "https://www.xcelyst.ai";
class DetailJobCard extends React.Component<Detail>{
    constructor(props: Detail) {
        super(props);
    }
    state: any = {
        showAllskills: false
    };

    render() {
        // Customize Area Start
        // let currentPositionData, currentCompanyData, currentLocationData;
        // let previousPositions: any = [];
        // let otherJobs: any = [];
        // let currentJobData: any = [];
        // currentLocationData = this.props.candidateData?.location;
        // this.props.candidateData?.temporary_user_profile?.work_experience?.map((item: any) => {
        //     if (item?.current) {
        //         if (item?.position) {
        //             currentPositionData = item?.position;
        //             currentCompanyData = item?.company;
        //             let start = moment(item.started?.split("T")?.[0], 'YYYY/MM/DD');
        //             let startMonYear = start.format('MMM') + " " + start.format("YYYY");
        //             currentJobData = [{ ...item, started: startMonYear, ended: "Current time" }];
        //         } else {
        //             currentPositionData = "-";
        //         }
        //     } else {
        //         if (item?.position) {
        //             previousPositions.push(`${item?.position}${item.company ? " at " + item.company : ""}`);

        //             let start = moment(item.started?.split("T")?.[0], 'YYYY/MM/DD');
        //             let startMonYear = start.format('MMM') + " " + start.format("YYYY");
        //             let end = moment(item.ended?.split('T')?.[0], 'YYYY/MM/DD');
        //             let endMonYear = end.format('MMM') + " " + end.format("YYYY");
        //             let endjob = { ...item, started: startMonYear, ended: endMonYear };
        //             otherJobs.push(endjob);
        //         }
        //     }
        // });
        // let allJobs = [...currentJobData, ...otherJobs];
        let allSkills = this.props.candidateData?.attributes?.skills ? [...this.props.candidateData?.attributes?.skills] : [];

        return (
            <Box className={`${DetailCandidateStyles.card} ${DetailCandidateStyles.candidateDetailFromListWrap}`}>
                <Grid container className={DetailCandidateStyles.detailWrap}>
                    <Grid item style={{ width: "100%" }}>

                        <Box mb={2} display='flex' justifyContent='space-between' alignItems="center" flexWrap='wrap'>
                            <Box display='flex' style={{ marginTop: 5 }}>
                                {/* <Box>
                                    <img style={{ padding: "7px", height: "28px", width: "38px", "borderRadius": "6px", "boxShadow": "rgb(100 100 111 / 20%) 0px 7px 29px 0px", "marginRight": "13px" }} src="https://companieslogo.com/img/orig/TCS.NS-7401f1bd.png?t=1631949260" />
                                </Box> */}
                                <Typography style={{ marginTop: "5px" }} variant='h5' >{this.props?.candidateData?.attributes?.job_title || '-'}</Typography>
                            </Box>
                            <div
                                style={{ textDecoration: "none" }}
                            // href={`${window.location.origin === FRONTEND_URL ? PROD_URL : baseURL}/bx_block_database/download_pdf/${this.props?.candidateData?.temporary_user_profile?.temporary_user_database_id}.pdf`}
                            // target='_blank'
                            // download
                            >
                                {/* <Button className={DetailCandidateStyles.primaryBtn} style={{ marginRight: 5, marginTop: 5 }}> Your Resume Score</Button> */}
                                <Button className={DetailCandidateStyles.primaryBtn} style={{ marginTop: 5, }}><a style={{ color: "white", textDecoration: "none" }} href={this.props?.candidateData?.attributes?.url} target="_blank">Apply Now</a> </Button>

                            </div>
                        </Box>

                        {/* <Box pr={2} mb={2}>
                            <Typography variant='h6' >  {this.props?.candidateData?.attributes?.company_name || "-"}</Typography>
                        </Box> */}

                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Experience :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.job_description?.preferred_overall_experience?.experiences_year || 0} Years
                                </Typography>
                            </Box>
                        </Box> */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Company :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    Tata Consultancy Services
                                </Typography>
                            </Box>
                        </Box> */}
                        {this.props?.candidateData?.attributes?.location ?
                            (this.props?.candidateData?.attributes?.location.trim() === "" || this.props?.candidateData?.attributes?.location === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>
                                    <Typography className={DetailCandidateStyles.primarytitle}>Location : </Typography>
                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {this.props?.candidateData?.attributes?.location}
                                    </Typography>
                                </Box>
                            : ""}

                        {this.props?.candidateData?.attributes?.company_name ?
                            (this.props?.candidateData?.attributes?.company_name.trim() === "" || this.props?.candidateData?.attributes?.company_name === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>

                                    <Typography className={DetailCandidateStyles.primarytitle}>Company Name : </Typography>

                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {this.props?.candidateData?.attributes?.company_name}
                                    </Typography>

                                </Box>
                            : ""}

                        {this.props?.candidateData?.attributes?.area_domain ?
                            (this.props?.candidateData?.attributes?.area_domain.trim() === "" || this.props?.candidateData?.attributes?.area_domain === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>

                                    <Typography className={DetailCandidateStyles.primarytitle}>Area Domain : </Typography>

                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {this.props?.candidateData?.attributes?.area_domain}
                                    </Typography>

                                </Box>
                            : ""}

                        {this.props?.candidateData?.attributes?.business_area ?
                            (this.props?.candidateData?.attributes?.business_area.trim() === "" || this.props?.candidateData?.attributes?.business_area === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>

                                    <Typography className={DetailCandidateStyles.primarytitle}>Business Area : </Typography>

                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {this.props?.candidateData?.attributes?.business_area}
                                    </Typography>

                                </Box>
                            : ""}

                        {this.props?.candidateData?.attributes?.employment ?
                            (this.props?.candidateData?.attributes?.employment.trim() === "" || this.props?.candidateData?.attributes?.employment === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>

                                    <Typography className={DetailCandidateStyles.primarytitle}>Employment Type : </Typography>

                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {this.props?.candidateData?.attributes?.employment}
                                    </Typography>

                                </Box>
                            : ""}

                        {this.props?.candidateData?.attributes?.created_at ?
                            (this.props?.candidateData?.attributes?.created_at.trim() === "" || this.props?.candidateData?.attributes?.created_at === "null") ? "" :
                                <Box style={{ display: '-webkit-box' }} mb={2}>
                                    <Typography className={DetailCandidateStyles.primarytitle}>Job Posted : </Typography>
                                    <Typography style={{ paddingLeft: "10px" }} component="span" className={DetailCandidateStyles.detailValue}>
                                        {moment(this.props?.candidateData?.attributes?.date_published).format('DD-MM-YYYY')}
                                    </Typography>
                                </Box>
                            : ""}


                        {/* {this.props?.candidateData?.attributes?.company_name && <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Company Name :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.company_name}
                                </Typography>
                            </Box>
                        </Box>} */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Area Domain :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.area_domain}
                                </Typography>
                            </Box>
                        </Box> */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Business Area :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.business_area}
                                </Typography>
                            </Box>
                        </Box> */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Employment Type :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.employment}
                                </Typography>
                            </Box>
                        </Box> */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Published Date :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {moment(this.props?.candidateData?.attributes?.date_published).format("DD-MM-YYYY")}
                                </Typography>
                            </Box>
                        </Box> */}
                        <Divider />
                        {/* {allSkills?.length > 0 &&
                            <>  <Box pr={2} my={2}>
                                <Typography variant='h5' className={`${ProfileStyles.detailTitle} ${ProfileStyles.darkBlue}`}>Skills</Typography>
                            </Box>
                                <Box className={ProfileStyles.skillChips} mb={2}>
                                    {allSkills?.length > 0 ?
                                        [...allSkills]?.splice(0, this.state.showAllskills ? allSkills?.length : 10)?.map((item: any, index: number) =>
                                            (<Typography variant='caption' key={index}>{item}</Typography>))
                                        : "-"}

                                    {(allSkills?.length > 0 && allSkills?.length > 10) && (
                                        <Typography variant='h6' className={ProfileStyles.skillMore} onClick={() => this.setState((prev: any) => ({ showAllskills: !prev.showAllskills }))}>
                                            {this.state.showAllskills ? "Show less" : `${allSkills?.length - 10} more`}
                                        </Typography>)}

                                </Box>
                            </>} */}
                        {this.props?.candidateData?.attributes?.skills?.length > 0 ?
                            (this.props?.candidateData?.attributes?.skills[0] === "null" || this.props?.candidateData?.attributes?.skills[0] === "" || this.props?.candidateData?.attributes?.skills[0] === null) ? null :
                                <Box  >

                                    {/* {(this.props?.candidateData?.attributes?.skills?.length > 0 && !this.props?.candidateData?.attributes?.skills[0]) && <Box  > */}

                                    <Box pr={2} my={2}>
                                        <Typography className={DetailCandidateStyles.primarytitle}>Skills</Typography>
                                    </Box>
                                    <Box paddingRight='1em'>
                                        <ul style={{ listStyle: "square" }}>
                                            {this.props?.candidateData?.attributes?.skills?.map((i: any) => {
                                                return <>
                                                    {(i !== "null" || i !== "" || i !== null) && <li> <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                                        {i}
                                                    </Typography></li>}
                                                </>
                                            })}
                                        </ul>

                                    </Box>
                                </Box> : null}
                        {this.props?.candidateData?.attributes?.description?.length > 0 && <Box  >
                            <Box pr={2} my={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Job Description</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <ul style={{ listStyle: "square" }}>
                                    {this.props?.candidateData?.attributes?.description?.map((i: any) => (<>
                                        <li>
                                            <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                                {i}
                                            </Typography>
                                        </li>
                                    </>))}
                                </ul>
                            </Box>
                        </Box>}
                        {this.props?.candidateData?.attributes?.responsibilities?.length > 0 && <Box  >
                            <Box pr={2} my={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Responsibilities</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <ul style={{ listStyle: "square" }}>
                                    {this.props?.candidateData?.attributes?.responsibilities?.map((i: any) => (<>

                                        {i.slice(-1) === ":" ?
                                            <>
                                                <div style={{ margin: "10px 0px" }}>
                                                    <Typography component="body" className={DetailCandidateStyles.detailValue}> <b >{i}</b></Typography>
                                                </div>
                                            </> :
                                            <>
                                                <li>
                                                    <Typography component="span" className={DetailCandidateStyles.detailValue}>  {i}</Typography>
                                                </li>
                                            </>
                                        }
                                    </>))}
                                </ul>
                            </Box>
                        </Box>}

                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Salary :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.job_description?.minimum_salary} {this.props?.candidateData?.attributes?.job_description?.currency}
                                </Typography>
                            </Box>
                        </Box> */}
                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>No. of Position :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.position || 0}
                                </Typography>
                            </Box>
                        </Box>

                        <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Grade :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {`${this.props?.candidateData?.attributes?.job_description?.preferred_overall_experience?.grade || "-"} `}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Level :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {`${this.props?.candidateData?.attributes?.job_description?.preferred_overall_experience?.level || "-"} `}
                                </Typography>
                            </Box>
                        </Box> */}

                        {/* <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Required Experience :</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {`${this.props?.candidateData?.attributes?.job_description?.preferred_overall_experience?.experiences_year || "0"} Years`}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider />

                        <Box  >
                            <Box pr={2} my={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Company Description</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.job_description?.company_description ?
                                        this.props?.candidateData?.attributes?.job_description?.company_description
                                        : "-"}
                                </Typography>
                            </Box>
                        </Box>

                        <Box  >
                            <Box pr={2} my={2}>
                                <Typography className={DetailCandidateStyles.primarytitle}>Job Responsibilities and Duties</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.job_description?.job_responsibility || "-"}
                                </Typography>
                            </Box>
                        </Box>


                        {this.props?.candidateData?.experience?.trim() && <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Experience:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.experience}
                                </Typography>
                            </Box>
                        </Box>
                        }
                        {this.props?.candidateData?.degree && <Box display="flex"  mb={2}>
                            <Box pr={2}>
                                <Typography className={DetailCandidateStyles.detailTitle}>Degree:</Typography>
                            </Box>
                            <Box paddingRight='1em'>
                                <Typography component="span" className={DetailCandidateStyles.detailValue}>
                                    {this.props?.candidateData?.attributes?.degree}
                                </Typography>
                            </Box>
                        </Box>
                        } */}


                    </Grid>


                </Grid>
                {/* <Box className={DetailCandidateStyles.tabWrap}> */}
                {/* {this.props?.candidateData && <DetailTabs candidateData={this.props?.candidateData} allJobExperiences={allJobs} />} */}
                {/* </Box> */}
            </Box >
        );
        // Customize Area End
    }
}
// Customize Area End
// Customize Area Start
export default DetailJobCard;
// Customize Area End
