import React, { FC, useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { NavData } from "./TopNav";
import axios from "axios";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";

import { runEngine } from "../../framework/src/RunEngine";
import { configJSON } from "../../blocks/LandingPage2/src/LandingPage2Controller.web";
import { IBlock } from "../../framework/src/IBlock";


const useStyles = makeStyles((theme) => ({
    sectionDesktop: {
        flex: "0.4 1 0%",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
        [theme.breakpoints.down("md")]: {
            flex: "none",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    links: {
        textDecoration: "none",
        paddingTop: "2rem",
        paddingBottom: "2rem",
        [theme.breakpoints.down("md")]: {
            "&:not(:last-child)": {
                marginRight: "1.5rem",
            }
        },
    },
    activeLink: {
        borderBottomWidth: 3,
        borderBottomStyle: "solid",
        borderBottomColor: theme.palette.primary.main,
    },
    activeLinkText: {
        color: theme.palette.primary.main,
        fontFamily: "Poppins-Semibold",
    },
    linkText: {
        color: theme.palette.text.primary,
        fontFamily: "Poppins-Regular",
        cursor: "pointer"
    },
}));

interface VisitorProps {
    pathName: string;
    toggleModal: () => void;
}

const VisitorMenu: FC<VisitorProps> = ({ pathName, toggleModal }) => {
    const [data, setData] = useState("true")
    const [dataID, setDataID] = useState("")


    const [thisData, setThisData] = useState({})
    const classes = useStyles();
    var id = ""
    useEffect(() => {
        getData();
        const receive = (from: string, message: Message) => {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                var responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );
                if (apiRequestCallId === id) {
                    setData(responseJson?.data[responseJson?.data?.length - 1]?.is_hide_job_menu);
                }
            }
        }

        var subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ]
        runEngine.attachBuildingBlock({ receive } as IBlock, subScribedMessages);

    }, [])

    const getData = async () => {
        const header = {
            "Content-Type": configJSON.jsonApiContentType
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        id = requestMessage.messageId;

        setThisData({
            ...thisData, requestDemoCallId: requestMessage.messageId
        })

        const type = 'Home Page Intro Video';

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchContentAPIEndPoint + type
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getAPIMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    console.log(data, "datadatadatadatadata");


    return (
        <>
            {/* {/ navigation menu /} */}
            <Box
                className={classes.sectionDesktop}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                flex="0.3"
                alignItems="center">
                {NavData.map((each) => (
                    <>
                        {
                            each.route === "Jobs" ? data === "true" ? null : <Link
                                to={each.path}
                                href={each.path}
                                key={each.path}
                                className={clsx([
                                    classes.links,
                                    pathName == each.path ? classes.activeLink : "",
                                ])}>
                                <Typography
                                    variant="body2"
                                    className={
                                        pathName == each.path ? classes.activeLinkText : classes.linkText
                                    }>
                                    {each.route}
                                </Typography>
                            </Link>
                                :
                                <Link
                                    to={each.path}
                                    href={each.path}
                                    key={each.path}
                                    className={clsx([
                                        classes.links,
                                        pathName == each.path ? classes.activeLink : "",
                                    ])}>
                                    <Typography
                                        variant="body2"
                                        className={
                                            pathName == each.path ? classes.activeLinkText : classes.linkText
                                        }>
                                        {each.route}
                                    </Typography>
                                </Link>
                        }
                    </>
                ))}
                <Typography
                    variant="body2"
                    onClick={toggleModal}
                    className={classes.linkText}>
                    Contact Us
                </Typography>
            </Box>
        </>
    );
}

export default VisitorMenu;