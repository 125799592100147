// Customizable Area Start
import React, {Component} from 'react';
import { CircularProgress } from '@material-ui/core';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, InputAdornment, InputBase, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CandidateCard from "../../JobListing2/src/common/CandidateCard.web";
import { SearchIcon } from "../../EmailNotifications/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PersonIcon from '@material-ui/icons/Person';
import { JobIcon } from "../../JobListing2/src/assets";
import CatalogueStyles from "./Styles.module.scss";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { ReactComponent as WorkOutlineOutlined } from "../../JobListing2/assets/job_type.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ExpandMoreOutlined, ErrorRounded } from "@material-ui/icons";
import { title } from "process";
import axios from 'axios'
import Carousel from 'react-elastic-carousel';
import { useHistory } from 'react-router-dom';
import { max } from 'lodash';
// Customizable Area End

const breakPoints = [
    { width: 600, itemsToShow: 1 },
    { width: 900, itemsToShow: 2 },
    { width: 1000, itemsToShow: 3 },
];

// Customizable Area Start
const dummyKms = [
    { value: 15, label: "15km" },
    { value: 40, label: "40km" },
    { value: 55, label: "55km" },
    { value: 80, label: "80km" },
    { value: 120, label: "120km" },
    { value: 160, label: "160km" },
]

const dummyStatus = ['Show "Watched"', 'Exclude "Watched"'];
const dummyCP = ['Current and Past', 'Current', 'Past'];
// Customizable Area End

// Customizable Area Start
export interface TPState {
    location: string;
    title: string;
    keyword_OR_name: string;
    fullName: string;
    km: {
        value: number;
        label: string;
    };
    status: string | 'Show "Watched"' | 'Exclude "Watched"';
    cp: string | 'Current and Past' | 'Current' | 'Past';
    title_boolean_search: boolean;
    title_boolean_location: boolean;
    keyword: boolean;
    minExperience: string;
    maxExperience: string;
    years_of_exp_from: number | "";
    years_of_exp_to: number | "";
    full_name: boolean;
    company: string;
    showText: string;
    showTextLoc: string;
    showkeyword_OR_nameText: string;
    showLocation: any;
    showTitle: any;
    showBooleanTitle: any;
    showBooleanLoc: any;
    showNonBooleanTitle: any;
    showCompany: any;
    showYearErorr: boolean;
    temp: any;
    orOption: boolean;
    orTitle: boolean;
    orCompany: boolean;
    showPopup : boolean;
    searchdata : any;
    Result : MatchingResult[];
    job_description : string;
    keywords : string[];
    jdLocation: string;
    ShowList: boolean; 
    showlistData: any
    jobDescriptionText: string;
    windowWidth: number;
    maxExperienceError: boolean;
    minExperienceError: boolean;
    isFileuploadLoader: boolean;
    skills: string;
    maximum_years_of_experience: any;
    minimum_years_of_experience: any;
    skillsError: boolean;
}

interface Props {
    // classes: any;
    onSearch: (state: TPState) => void;
    onAdvanceSearch: (state: TPState) => void;
    showSaveSearch?: boolean;
    locationList?: any[];
    companyList?: any[];
    titleList?: any[];
    keywordsList?: any[];
    onSuggessionSearch: (type: string, searchText: string) => void;
    onReset: () => void;
    classes?: any; 

}

interface MatchingResult{
    Xcelyst_AI_Score: number;
    id: string;
    fullname: string;
    certification: string | null;
    course: string | null;
    education: string | null;
    experience: string | null;
    experienceyears: number | null;
    headline: string | null;
    linkedin: string | null;
    locations: string | null;
    project: string | null;
    skills: string | null;
    summary: string | null;
}
// Customizable Area End
class Filter extends Component<Props, TPState> {
    fileInputRef = React.createRef<HTMLInputElement>();
    // Customizable Area Start
    initialState: TPState = {
        orOption: true,
        orTitle: true,
        orCompany: true,
        location: '',
        title: "",
        keyword_OR_name: "",
        fullName: "",
        km: dummyKms[0],
        status: '',
        cp: dummyCP[0],
        title_boolean_search: false,
        title_boolean_location: true,
        keyword: true,
        full_name: false,
        company: "",
        showText: "",
        showTextLoc: "",
        years_of_exp_from: "",
        years_of_exp_to: "",
        minExperience: "",
        maxExperience: "",
        maxExperienceError: false,
        minExperienceError: false,
        showkeyword_OR_nameText: "",
        showLocation: [],
        showTitle: [],
        showBooleanTitle: [],
        showBooleanLoc: [],
        showNonBooleanTitle: [],
        showCompany: [],
        showYearErorr: false,
        temp: "",
        showPopup : false,
        searchdata : [],
        Result : [],
        job_description : "",
        keywords : [],
        jdLocation: "",
        ShowList: false,
        jobDescriptionText: "",
        windowWidth: window.innerWidth,
        showlistData: [],
        isFileuploadLoader: false,
        skills: "",
        maximum_years_of_experience: "",
        minimum_years_of_experience: "",
        skillsError: false
    };

    state: TPState = { ...this.initialState };
    // Customizable Area End
    // Customizable Area Start

    togglePopup = () => {
        this.setState({showPopup : !this.state.showPopup});
    }

    toggleListPopup = () => {
        this.setState({ShowList : !this.state.ShowList});
    }

    handleJobDescriptionText = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ jobDescriptionText: event.target.value });
    }


    handleMinExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ minExperience: event.target.value, minExperienceError: false });
    }

    handleMaxExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ maxExperience: event.target.value, maxExperienceError: false },);
    }

    handleKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      const keywordsArray = input.split(',').map(keyword => keyword.trim());
      this.setState({ keywords: keywordsArray });
    };

    handleSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ skills: event.target.value });
    }

    handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ location: event.target.value });
    }

    onSearch = () => {

        // var str = this.state.keyword_OR_name.replaceAll('"AND"', '').replaceAll("'AND'", '')
        //     .replaceAll("'", "").replaceAll('"', ''


        this.props.onSearch(this.state);
    }

    onAdvanceSearch = () => {
        this.props.onAdvanceSearch(this.state)
    }

    advanceSearchValidation = () => {
        if(this.state.minExperience === ""){
            this.setState({minExperienceError: true})
        }else if(this.state.job_description === ""){
            alert("please upload your JD")
        }else if(this.state.skills === ""){
            this.setState({skillsError: true})
        }
        else{
            setTimeout(() => {
                this.onAdvanceSearch()
                this.setState({showPopup: false})
            },1000)
        }
    }

    // onAdvancedSearch = async () => {
    //     try {
    //         const { minExperience, maxExperience, showkeyword_OR_nameText, jobDescriptionText, location, job_description, keywords } = this.state;

    //         const response = await axios.post('https://admin.xcelyst.ai/bx_block_job/get_candidates_info?page=1', {
    //             minExperience,
    //             maxExperience,
    //             skills: showkeyword_OR_nameText,
    //             location,
    //             job_description,
    //             keywords
    //         });

    //         console.log(response.data);
    //         console.log(typeof(response.data));

    //         if (response.status === 200) {
    //             // Assuming response.data contains the JSON array of matching results
    //             const matchingResults: MatchingResult[] = response.data;

    //             // Update the state with the matching results
    //             this.setState({ showlistData: matchingResults ,showPopup: false}, () => {
    //                 this.setState({ShowList: true})
    //             });
    //         } else {
    //             console.error('Failed to fetch filtered data:', response.statusText);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching filtered data:', error);
    //     }
    // }



    onReset = () => {
        this.setState({ ...this.initialState });
        this.props.onReset();
    }



    onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            console.log("No file selected");
            return;
        }

        const file = files[0];
        const formData = new FormData();
        formData.append('file', file);
        this.setState({isFileuploadLoader: true})
        try {
            const response = await axios.post('https://advanceai.xcelyst.ai/jd_parsing', formData);
            
            if (response.status === 200) {
                const { job_description, keywords, location, maximum_years_of_experience, minimum_years_of_experience } = response.data;
                console.log('Result:', { job_description, keywords: keywords, maxExperience: minimum_years_of_experience[0], minExperience: minimum_years_of_experience[0] });
                this.setState({ job_description, keywords: keywords, location: location !== "None" ? location : "",
                    maxExperience: maximum_years_of_experience[0] ? maximum_years_of_experience[0] : ""  , 
                    minExperience: minimum_years_of_experience[0] ? minimum_years_of_experience[0] : "", 
                     isFileuploadLoader: false });
            } else {
                console.error('Failed to upload JD file:', response.statusText);
                this.setState({isFileuploadLoader: true})
            }

        } catch (error) {
            console.error("Error uploading JD file:", error);
            this.setState({isFileuploadLoader: false})
        }
    }



    // Customizable Area End


    render() {
        // Customizable Area Start
        let timeoutVar: any;
        const { classes } = this.props;

        return (
            <Box className={`${CatalogueStyles.card} ${CatalogueStyles.filterWrap}`}>
                <Box className={CatalogueStyles.filterCard}>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Location</Typography>
                        {this.state.title_boolean_location ?
                            <Box display="flex" position='relative' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <SearchIcon />
                                </Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showTextLoc }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHideTitle}
                                        multiline
                                        placeholder="Enter location"
                                        onChange={(event) => {

                                            let receivedText: any = event.target.value;
                                            let boolArray = ['OR', 'or'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item: any) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else return item
                                            });
                                            this.setState({ showTextLoc: forT?.join(" ") || "", showBooleanLoc: receivedText, location: receivedText });
                                        }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.showBooleanLoc}
                                    />
                                </Box>
                            </Box>
                            :
                            <Box display="flex" width='100%' justifyContent='center' alignItems='center' className={CatalogueStyles.titleInput}>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <SearchIcon />
                                </Box>
                                <Autocomplete
                                    className={CatalogueStyles.search2}
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    multiple
                                    disableClearable
                                    noOptionsText="No Data Found"
                                    value={this.state.showLocation}
                                    loading={this.props.locationList === null}
                                    options={this.props.locationList?.map((option: any) => option) || []}
                                    onFocus={() => {
                                        this.setState({ orOption: true })
                                        this.props.onSuggessionSearch("location", "");
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timeoutVar);
                                        timeoutVar = setTimeout(() => {
                                            this.props.onSuggessionSearch("location", newInputValue);
                                        }, 700)
                                    }}
                                    onChange={(event, value) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.setState({ location: value?.join(" OR "), showLocation: [...value] });
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        if (this.state.orOption) {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                        else {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length - 1 &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                    }
                                    }
                                    onBlur={() => { this.setState({ orOption: false }) }}
                                    renderInput={(params) => <TextField {...params}
                                                                        multiline
                                                                        maxRows={3}
                                                                        minRows={1}
                                                                        placeholder="Enter location"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            onKeyDown: (event) => {
                                                                                if (event.key === 'Enter') {
                                                                                    event.stopPropagation();
                                                                                    event.preventDefault();
                                                                                }
                                                                            },
                                                                        }}
                                    />}
                                />
                            </Box>
                        }
                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.title_boolean_location}
                                        onChange={(event) => {
                                            this.setState((prev) => ({ title_boolean_location: !prev.title_boolean_location, }));
                                            event.target.checked ? this.setState({ location: this.state.showBooleanLoc }) : this.setState({ location: this.state.showNonBooleanTitle });
                                        }}
                                        name="location"
                                        color="primary"
                                    />
                                }
                                label="Location boolean search"
                            />
                        </Box>
                    </Box>
                    <Box flex={1} className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Title</Typography>
                        {this.state.title_boolean_search ?
                            <Box display="flex" position='relative' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showText }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHideTitle}
                                        multiline
                                        placeholder="Enter title"
                                        onChange={(event) => {

                                            let receivedText: any = event.target.value;
                                            let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item: any) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else return item
                                            });
                                            this.setState({ showText: forT?.join(" ") || "", showBooleanTitle: receivedText, title: receivedText });
                                        }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.showBooleanTitle}
                                    />
                                </Box>
                            </Box> :
                            <Box display="flex" width='100%' justifyContent='center' alignItems='center' className={CatalogueStyles.titleInput}>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <JobIcon /></Box>
                                <Autocomplete
                                    className={CatalogueStyles.search2}
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    multiple
                                    disableClearable
                                    noOptionsText="No Data Found"
                                    onFocus={() => {
                                        this.setState({ orTitle: true })
                                        this.props.onSuggessionSearch("title", "");
                                    }}
                                    loading={this.props.titleList === null}
                                    options={this.props.titleList?.map((option: any) => option) || []}
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timeoutVar);
                                        timeoutVar = setTimeout(() => {
                                            this.props.onSuggessionSearch("title", newInputValue);
                                        }, 700)
                                    }}
                                    onChange={(event, value) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.setState({ title: value?.join(" OR "), showTitle: [...value], showNonBooleanTitle: value?.join(" OR "), });

                                    }}
                                    renderTags={(value, getTagProps) => {
                                        if (this.state.orTitle) {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                        else {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length - 1 &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                    }

                                    }
                                    onBlur={() => { this.setState({ orTitle: false }) }}
                                    renderInput={(params) => <TextField {...params}
                                                                        multiline
                                                                        maxRows={3}
                                                                        minRows={1}
                                                                        placeholder="Enter title"
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            onKeyDown: (event) => {
                                                                                if (event.key === 'Enter') {
                                                                                    event.stopPropagation();
                                                                                    event.preventDefault();
                                                                                }
                                                                            },
                                                                        }}
                                    />}
                                    value={this.state.showTitle}
                                />
                            </Box>
                        }


                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.title_boolean_search}
                                        onChange={(event) => {
                                            this.setState((prev) => ({ title_boolean_search: !prev.title_boolean_search, }));
                                            event.target.checked ? this.setState({ title: this.state.showBooleanTitle }) : this.setState({ title: this.state.showNonBooleanTitle });
                                        }}
                                        name="title"
                                        color="primary"
                                    />
                                }
                                label="Title boolean search"
                            />
                            <Divider
                                orientation="vertical"
                                style={{ height: "1.6em", width: ".14em", backgroundColor: "#d6d6e5", marginRight: "1em" }}
                            />
                            <FormControl>
                                <Select
                                    disabled={this.state.title_boolean_search ? this.state.showBooleanTitle.length === 0 ? true : false : this.state.showNonBooleanTitle.length === 0 ? true : false}
                                    IconComponent={ExpandMoreOutlined}
                                    className={CatalogueStyles.filterDropDown}
                                    value={this.state.cp}
                                    onChange={(e) => this.setState({ cp: e.target.value as string })}
                                >
                                    {dummyCP.map((cp, index) => (
                                        <MenuItem key={index} value={cp}>{cp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox} >
                        <Box display="flex">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.keyword}
                                        onChange={() => {
                                            this.setState({
                                                keyword: !this.state.keyword, full_name: !this.state.full_name
                                            })


                                        }}
                                        name="keyword"
                                        color="primary"
                                    />
                                }
                                label="Keyword"
                            />
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.full_name}
                                        onChange={() => {
                                            this.setState({
                                                full_name: !this.state.full_name, keyword: !this.state.keyword
                                            })
                                        }}
                                        name="full_name"
                                        color="primary"
                                    />
                                }
                                label="Full Name"
                            />
                        </Box>
                        {this.state.keyword ?
                            <Box display="flex" position='relative' alignItems='center'>
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showkeyword_OR_nameText }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHide}
                                        multiline
                                        maxRows={3}
                                        minRows={1}
                                        placeholder="Enter Keyword"
                                        onChange={(event) => {
                                            let receivedText = event.target.value;
                                            let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else {
                                                    return item;
                                                }
                                            });
                                            this.setState({ showkeyword_OR_nameText: forT?.join(" ") || "", keyword_OR_name: receivedText })
                                        }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.keyword_OR_name}
                                    />
                                </Box>
                            </Box> :
                            <Box display="flex" position='relative' alignItems='center' className={`${CatalogueStyles.titleSearch}`} >
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.nameField}>
                                    <InputBase
                                        placeholder="Enter Name"
                                        classes={{ root: CatalogueStyles.inputRoot }}
                                        inputProps={{ "aria-label": "search" }}
                                        value={this.state.fullName}
                                        onChange={(e) => this.setState({ fullName: e.target.value })}
                                    />
                                </Box>

                            </Box>
                        }
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Manage Profiles</Typography>
                        <Box className={CatalogueStyles.filterIcon} display='flex' alignItems='center'>
                            <PermIdentityIcon />
                            <FormControl>
                                <Select
                                    className={CatalogueStyles.filterDropDown}
                                    IconComponent={ExpandMoreOutlined}
                                    displayEmpty
                                    renderValue={this.state.status !== null ?
                                        undefined : () => "Choose Status"}
                                    value={this.state.status !== null ? this.state.status : dummyStatus[0]}
                                    onChange={(e) => this.setState({ status: e.target.value as string })}
                                >
                                    <MenuItem value={""}>Choose Status</MenuItem>
                                    {dummyStatus.map((status, index) => (
                                        <MenuItem key={index} value={status}>{status}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Company</Typography>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                            <Box className={CatalogueStyles.filterIcon}>
                                <WorkOutlineOutlined /></Box>
                            <Autocomplete
                                className={CatalogueStyles.search}
                                classes={{ root: CatalogueStyles.inputRoot }}
                                multiple
                                disableClearable
                                noOptionsText="No Data Found"
                                loading={this.props.companyList === null}
                                onFocus={() => {
                                    this.setState({ orCompany: true })
                                    this.props.onSuggessionSearch("company", "");
                                }}
                                options={this.props.companyList?.map((option: any) => option) || []}
                                onInputChange={(event, newInputValue) => {
                                    clearTimeout(timeoutVar);
                                    timeoutVar = setTimeout(() => {
                                        this.props.onSuggessionSearch("company", newInputValue);
                                    }, 700)
                                }}
                                onChange={(event, value) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    this.setState({ company: value?.join(" OR "), showCompany: [...value] });

                                }}
                                renderTags={(value, getTagProps) => {
                                    if (this.state.orCompany) {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                    else {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length - 1 &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                }
                                }
                                onBlur={() => { this.setState({ orCompany: false }) }}
                                renderInput={(params) => <TextField {...params}
                                                                    multiline
                                                                    maxRows={3}
                                                                    minRows={1}
                                                                    placeholder="Enter company name"
                                                                    inputProps={{
                                                                        ...params.inputProps,
                                                                        onKeyDown: (event) => {
                                                                            if (event.key === 'Enter') {
                                                                                event.stopPropagation();
                                                                                event.preventDefault();
                                                                            }
                                                                        },
                                                                    }}
                                />}
                                value={this.state.showCompany}
                            />
                        </Box>
                    </Box>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Box display="flex" width='100%' alignItems='center' flexWrap="wrap">
                            <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle} style={{ marginRight: 5 }}>Years of Experience</Typography>
                        </Box>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center' >
                            <TextField
                                value={this.state.years_of_exp_from}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_from: '' })
                                    } else {
                                        this.setState({ years_of_exp_from: Number(e.target.value) > 98 ? 0 : Number(e.target.value) })
                                        // console.log(this.state.years_of_exp_to, this.state.years_of_exp_from, "--====");
                                        // if (e.target.value > this.state.years_of_exp_to && this.state.years_of_exp_to) {
                                        //     this.setState({ years_of_exp_from: '' })
                                        // }
                                    }

                                }}
                                type="number"
                                placeholder="From" fullWidth
                                className={CatalogueStyles.yearsExp}
                                onKeyUp={() => {
                                    let start: any = this.state.years_of_exp_from.toString();
                                    start.includes(".") ? this.setState({ years_of_exp_from: parseInt(start.split('.')) }) : parseInt(start);
                                }}

                            />
                            <TextField
                                value={this.state.years_of_exp_to}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_to: '' })
                                    } else {
                                        this.setState({ years_of_exp_to: Number(e.target.value) > 99 ? 99 : Number(e.target.value) });
                                        // if (e.target.value < this.state.years_of_exp_from && this.state.years_of_exp_from) {
                                        //     this.setState({ years_of_exp_to: '' })
                                        // }
                                    }

                                }}
                                type="number"
                                placeholder="To" fullWidth
                                className={CatalogueStyles.yearsExp}
                                onKeyUp={() => {
                                    let end: any = this.state.years_of_exp_to.toString();
                                    end.includes(".") ? this.setState({ years_of_exp_to: parseInt(end.split('.')) }) : parseInt(end);
                                }}
                            />
                        </Box>

                    </Box>
                    {this.state.showYearErorr && <Typography gutterBottom className={CatalogueStyles.filterTitle} style={{ color: "red", opacity: 0.6, margin: 10 }}><ErrorRounded style={{ fontSize: 16, verticalAlign: "sub" }} /> Minimum year should be less than Maximum year</Typography>}

                </Box>
                <Box
                    className={CatalogueStyles.filterReset}
                    display="flex"
                    alignItems='center'
                    justifyContent="center"
                    onClick={this.onReset}
                >
                    <Typography>
                        Reset
                    </Typography>
                </Box>
                <Box
                    className={CatalogueStyles.filterSearch}
                    display="flex"
                    flexDirection="column"
                    justifyContent={this.props.showSaveSearch ? "flex-end" : "center"}
                    
                >
                    {this.props.showSaveSearch && (
                        <Box>
                            <Typography>
                                Save Search
                            </Typography>
                        </Box>
                    )}
                    <Box
                        className="search"
                        style={{
                            flex: this.props.showSaveSearch ? .5 : 1,
                            
                    
                        }}
                        onClick={this.onSearch}
                        // onClick={() => {
                        //     if (this.state.years_of_exp_to || this.state.years_of_exp_to === 0) {
                        //         this.state.years_of_exp_from > this.state.years_of_exp_to ? this.setState({ showYearErorr: true }) : this.setState({ showYearErorr: false });
                        //     } else {
                        //         this.setState({ showYearErorr: false })
                        //     }
                        // }}

                    >
                        <Typography>
                            Search
                        </Typography>
                    </Box>
                    <Box
                        className='search'
                        display="flex"
                        alignItems='center'
                        justifyContent="center"
                        onClick={() => this.togglePopup()}
                    >
                        <Typography>
                            Advanced Search
                        </Typography>
                    </Box>
                    <Dialog
                        open={this.state.showPopup}
                        onClose={this.togglePopup}
                        fullWidth={true}
                        maxWidth = 'md'>
                        <DialogTitle>Advanced Search</DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="column">
                                <Box mb={2}>
                                    <Box display="flex">
                                    <Typography>Upload JD</Typography>
                                    {this.state.isFileuploadLoader && <CircularProgress color="secondary" size={24} style={{marginLeft: 10}}/>}
                                    </Box>
                                    <input type="file" onChange={this.onUpload} accept=".txt,.docx,.pdf" />
                                </Box>
                                <TextField
                                    label="Job Description"
                                    type="text"
                                    value={this.state.job_description}
                                    disabled
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    error={this.state.minExperienceError}
                                    label="Minimum Experience"
                                    type="number"
                                    value={this.state.minExperience}
                                    onChange={this.handleMinExperienceChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    // error={this.state.maxExperienceError}
                                    label="Maximum Experience"
                                    type="number"
                                    value={this.state.maxExperience}
                                    onChange={this.handleMaxExperienceChange}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    error={this.state.skillsError}
                                    label="Skills"
                                    value={this.state.skills}
                                    disabled={this.state.isFileuploadLoader}
                                    onChange={(e) => this.setState({ skills: e.target.value })}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="keywords"
                                    value={this.state.keywords}
                                    disabled={this.state.isFileuploadLoader}
                                    onChange={this.handleKeywords}
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Location"
                                    value={this.state.location != "None" ? this.state.location : ""}
                                    disabled={this.state.isFileuploadLoader}
                                    onChange={this.handleLocationChange}
                                    fullWidth
                                    margin="normal"
                                />
                            </Box>
                            {this.state.Result.length > 0 && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Xcelyst_AI_Score</TableCell>
                                            <TableCell>id</TableCell>
                                            <TableCell>fullname</TableCell>
                                            <TableCell>summary</TableCell>
                                            <TableCell>experience</TableCell>
                                            <TableCell>experienceyears</TableCell>
                                            <TableCell>headline</TableCell>
                                            <TableCell>skills</TableCell>
                                            <TableCell>certification</TableCell>
                                            <TableCell>course</TableCell>
                                            <TableCell>education</TableCell>
                                            <TableCell>linkedin</TableCell>
                                            <TableCell>locations</TableCell>
                                            <TableCell>project</TableCell>


                                            {/* Add more table headers as needed */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.Result.map((item:any, index:number) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.Xcelyst_AI_Score}</TableCell>
                                                <TableCell>{item.id}</TableCell>
                                                <TableCell>{item.fullname}</TableCell>
                                                <TableCell>{item.summary}</TableCell>
                                                <TableCell>{item.experience}</TableCell>
                                                <TableCell>{item.experienceyears}</TableCell>
                                                <TableCell>{item.headline}</TableCell>
                                                <TableCell>{item.skills}</TableCell>
                                                <TableCell>{item.certification}</TableCell>
                                                <TableCell>{item.course}</TableCell>
                                                <TableCell>{item.education}</TableCell>
                                                <TableCell>{item.linkedin}</TableCell>
                                                <TableCell>{item.locations}</TableCell>
                                                <TableCell>{item.project}</TableCell>
                                                

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.onReset} color="primary">
                                Reset
                            </Button>
                            <Button disabled={this.state.isFileuploadLoader} onClick={this.advanceSearchValidation} color="primary">
                                Search
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.ShowList}
                        onClose={this.toggleListPopup}
                        fullWidth={true}
                        maxWidth = 'md'>
                        <DialogTitle>Candidate list</DialogTitle>
                       
                        <div>
                        {this.state.showlistData.length > 0 ? (
                            <div>
                            {this.state.showlistData.map((item:any) => {
                                return(
                                    <div>
                                    <p>fullname: {item.fullname}</p>
                                    <p>headline: {item.headline}</p>
                                    <p>Xcelyst_AI_Score: {item.score}</p>
                                    <p>linkedin: {item.linkedin}</p>
                                    <p>education: {item.education}</p>
                                    <p>experience: {item.experience}</p>
                                    <p>locations: {item.locations}</p>
                                    <p>skills: {item.skills}</p>
                                    </div>
                                    )
                            })}
                            </div>
                            ): <p>Not Found Data</p>}
                        </div>
                    </Dialog>

                </Box>


            </Box >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default Filter;
// Customizable Area End