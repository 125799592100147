import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { prepareDataForValidation } from "formik";
import { debounce } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  showEditProfileModal: boolean;
  handleCloseEditProfileModal: () => void;
  sendRequestForUpdateProfile: (data: any) => void;
  showEditOtherDetailModal: boolean;
  handleCloseEditOtherDetailModal: () => void;
  sendRequestForUpdateOtherDetail: (data: any, type: string) => void;
  showEditRoleModal: boolean;
  handleCloseEditRoleModal: () => void;
  sendRequestForUpdateRole: (data: any, type: string) => void;
  profileData: any;
  jobDescription: any;
  onCloseJobDescription: () => void;
  currentStep: number;
  setJDValues: (data: any) => void;
  nextStep: () => void;
  previousStep: (value: any) => void;
  fieldData: any;
  jobDescriptionData: any;
  handleSendJDData: (event: any, values: any) => void;
  submitFormData: (event: any, values: any) => void;
  location?: any;
  match?: any;
  onEditJobDescriptionData?: any;
  jdHeadingValue?: string;
  history?: any;
  windowWidth?: number;
  clientID?: number | string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  createRoleValidationSchema: any;
  managersList: any;
  mappedManagersList: any;
  uploadFiles: any;
  showJobDescription: boolean;
  currentStep: number;
  buyerSchemaArray: any
  jdFormData: any;
  jobTitleList: any;
  experienceList: any;
  currencyList: any;
  riskList: any;
  frontOfficeList: any;
  financeList: any;
  businessVerticleList: any;
  jdLoader: boolean;
  jobDescriptionData: {
    itSkills: any;
    degreeList: any;
    fieldOfStudyList: any;
    domainList: any;
    roleList: any;
    softSkillsList: any;
    testsList: any;
    currency: any;
    managers: any;
    overall_experiences: any;
    skill_levels: any;
  },
  businessVerticle: any;
  fetchAllJDData: any;
  requestType: string;
  jobDetailData: any;
  isInitiated: boolean;
  errShow: boolean;
  jdHeading: string;
  compulsoryJDUpload: boolean;
  resetFormikForm: any;
  successSubmit: boolean;
  windowWidth: number;
  userType?: string;
  clientID: number | string;
  FileError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RoleMangementController extends BlockComponent<
  Props,
  S,
  SS
> {
  createNewRoleCallId: string;
  getJDTemaplateDataCallId: string;
  delaySearch: any;
  getRoleAllDataCallId: string;
  getJobDetailsApiCallId: string;
  clientIDLink: any;

  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    const validationSchema = Yup.object({
      role_name: Yup.string()
        .matches(/^[aA-zZ0-9-,()\s]+$/, "Special Characters are not allowed")
        .trim()
        .max(50, "Role name cannot be more than 50 characters")
        .required("Please enter role name"),
      positions: Yup.string().required("Please enter no. of positions"),
      mappedManagers: Yup.array()
        .of(Yup.string())
        .min(1),
    });
    let buyerAppStep1Schema = {
      jobTitle: Yup.string()
        .matches(/^[aA-zZ0-9-,()\s]+$/, "Special Characters are not allowed")
        .trim()
        .max(50, "Job title cannot be more than 50 characters")
        .required("Please enter job title"),
      showExperience: Yup.string().required("Please select experience"),
      minSalary: Yup.string().required("Please enter minimum salary").min(1, "Minimum salary must be more than 0")
        .matches(/^[0-9\s]+$/, "Only numbers allowed")
        .min(1, "Minimum salary must be more than 0"),
      location: Yup.string()
        .matches(/^[aA-zZ0-9\s]+$/, "Special Characters are not allowed")
        .trim()
        .max(50, "Location cannot be more than 50 characters")
        .required("Please enter location"),
    };
    let buyerAppStep2Schema = {};
    let buyerAppStep3Schema = {};
    let buyerAppStep4Schema = {};


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      createRoleValidationSchema: validationSchema,
      managersList: [],
      mappedManagersList: [],
      uploadFiles: [],
      showJobDescription: false,
      buyerSchemaArray: [buyerAppStep1Schema, buyerAppStep2Schema, buyerAppStep3Schema, buyerAppStep4Schema],
      currentStep: 0,
      jdFormData: {},
      jobTitleList: [],
      experienceList: ["Junior(0-2 years)", "Senior(0-2 years)", "Expert(0-2 years)"],
      currencyList: ["INR", "USD", "EUR"],
      riskList: ["Market Risk", "Credit Risk", "Operation Risk", "Stress Testing", "Market Risk", "Credit Risk", "Operation Risk", "Stress Testing", "Market Risk", "Credit Risk", "Operation Risk", "Stress Testing"],
      frontOfficeList: ["Structuring", "Quan/Stats", "Trading", "Reporting", "Structuring", "Quan/Stats", "Trading", "Reporting", "Structuring", "Quan/Stats", "Trading", "Reporting"],
      financeList: ["Product Control", "Valuation Review Group", "Regulatory Changes", "Product Control", "Valuation Review Group", "Regulatory Changes", "Product Control", "Valuation Review Group", "Regulatory Changes"],
      businessVerticleList: ['Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management", 'Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management", 'Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management", "Asset Management", 'Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management", "Asset Management", 'Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management", "Asset Management", 'Investment Banking', "Retail Banking", "Corporate Banking", "Private Banking", "Asset Management"],
      jdLoader: false,
      jobDescriptionData: {
        itSkills: [],
        degreeList: [],
        fieldOfStudyList: [],
        domainList: [],
        roleList: [],
        softSkillsList: [],
        testsList: [],
        currency: [],
        managers: [],
        overall_experiences: [],
        skill_levels: [],
      },
      businessVerticle: "",
      fetchAllJDData: null,
      requestType: "",
      jobDetailData: null,
      isInitiated: false,
      errShow: false,
      jdHeading: "",
      compulsoryJDUpload: false,
      resetFormikForm: () => { },
      successSubmit: false,
      windowWidth: window.innerWidth,
      userType: sessionStorage.getItem("user_role") || "",
      FileError: false,
      clientID: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.delaySearch = debounce((data: any, setFieldValue: any) => { setFieldValue("jobTitle", data); this.getRoleAllData(data) }, 500);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        //Create New Role API
        if (
          apiRequestCallId === this.createNewRoleCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            if (this.state.requestType == "createRole") {
              this.state.resetFormikForm();
              this.setState({ successSubmit: false, uploadFiles: [] });
              this.openSnackBarHandler('success', "Role has successfully been created");
              setTimeout(() => {
                this.props.history.push('/open-roles');
              }, 1000);
            } else {
              this.openSnackBarHandler('success', "Role has successfully been updated");
              setTimeout(() => {
                this.props.history.push(`/job-detail/${this.props?.match?.params?.id}${this.state.userType == 'admin' && this.state.clientID ? "?" + "clientId=" + this.state.clientID : ""}`);
              }, 1000);
            }
          } else {
            this.setState({ successSubmit: false, uploadFiles: [] });
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            } else {
              this.openSnackBarHandler('error', "Our team is looking into it");
            }
          }
        }
        //Job Description API
        if (
          apiRequestCallId === this.getJDTemaplateDataCallId
        ) {
          this.setState({ jdLoader: true });
          if (responseJson !== undefined && !responseJson.errors) {
            let mapedManger = responseJson?.preferred_data?.managers?.map((item: any) => (`${item.name},(${item.email})`));

            this.setState({
              jobDescriptionData: {
                ...this.state.jobDescriptionData,
                currency: responseJson?.preferred_data?.currency,
                managers: responseJson?.preferred_data?.managers,
                overall_experiences: responseJson?.preferred_data?.overall_experiences,
                skill_levels: responseJson?.preferred_data?.skill_levels,
              },
              mappedManagersList: mapedManger,
              managersList: responseJson?.preferred_data?.managers
            }, () => this.state.jobDescriptionData);


            // job_description
            responseJson.job_description.data.map((each: any) => {
              if (each.attributes.name === "IT Skills") {
                this.setState({
                  jobDescriptionData: {
                    ...this.state.jobDescriptionData,
                    itSkills: each.attributes.categories
                  }
                });
              } else if (each.attributes.name.includes("Degree")) {
                each.attributes.categories.map((category: any) => {
                  if (category.category_name === "Degree") {
                    this.setState({
                      jobDescriptionData: {
                        ...this.state.jobDescriptionData,
                        degreeList: category.skilles
                      }
                    });
                  }
                  if (category.category_name === "Field of Study") {
                    this.setState({
                      jobDescriptionData: {
                        ...this.state.jobDescriptionData,
                        fieldOfStudyList: category.skilles
                      }
                    });
                  }
                })
              } else if (each.attributes.name === "Domain") {
                this.setState({
                  jobDescriptionData: {
                    ...this.state.jobDescriptionData,
                    domainList: each.attributes.categories
                  }
                });
              } else if (each.attributes.name === "Role") {
                this.setState({
                  jobDescriptionData: {
                    ...this.state.jobDescriptionData,
                    roleList: each.attributes.categories
                  }
                });
              } else if (each.attributes.name === "Soft Skills") {
                this.setState({
                  jobDescriptionData: {
                    ...this.state.jobDescriptionData,
                    softSkillsList: each.attributes.categories
                  }
                });
              } else if (each.attributes.name === "Tests") {
                this.setState({
                  jobDescriptionData: {
                    ...this.state.jobDescriptionData,
                    testsList: each.attributes.categories
                  }
                });
              }

            });

          } else {
            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              this.openSnackBarHandler('error', errors?.name?.[0]);
            } else if (errors?.[0]) {
              this.openSnackBarHandler('error', "" + errors?.[0]);
            } else {
              this.parseApiCatchErrorResponse(errorReponse);
            }
          }
        }
        //Search Role API
        if (
          apiRequestCallId === this.getRoleAllDataCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            this.setState({ jobTitleList: [...responseJson] });
          } else {

            const errors = responseJson?.errors;
            if (errors?.name?.[0]) {
              this.openSnackBarHandler('error', errors?.name?.[0]);
            } else {
              this.parseApiCatchErrorResponse(errorReponse);
            }
          }
        }
        //Get Job Details API
        if (
          apiRequestCallId === this.getJobDetailsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson.error) {
            this.setState({ jobDetailData: responseJson?.data });
            if (this.state.requestType == "editRole") {
              if (!responseJson?.data?.attributes?.jd_automatic) {
                this.handleFormDataOnEdit(responseJson?.data?.attributes?.job_description);
              } else {
                this.handleJDManagers(responseJson?.data?.attributes?.managers)
              }
            }
          } else {
            this.openSnackBarHandler('error', "Job apply failed");
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  // Customizable Area Start

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  // Customizable Area End


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  // create a unique ID everytime
  getUnquieId = () => {
    const date = new Date();
    return `${date.getDay()}${date.getMonth()}${date.getFullYear()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;
  };


  // To create new role "Maual(Using Job decription matrix)" and "Automated(By uploading Job description)"
  createNewRole = (values: any, jdId?: string | number): boolean => {
    const headerWithoutJFile = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };
    const headerWithJFile = {
      'token': sessionStorage.getItem('Token')
    };
    let otherSkill = this.state.fetchAllJDData?.others?.trim()?.replace(/(^[,\s]+)|([,\s]+$)/g, '');
    let otherSkillToArray = otherSkill?.split(",")?.filter((item: any) => item);
    let httpBody: any;
    let formDate: FormData = new FormData();
    if (values?.isAutomated) {
      let allManager = values.managers?.map((data: any) => {
        formDate.append("managers[]", JSON.stringify(data));
      })
      formDate.append("jd_file", values.jdFile?.file);
      formDate.append("position", values.positions);
      formDate.append("name", values.role_name);
      formDate.append("identifier", this.getUnquieId());
      if (this.state.userType == 'admin' && this.state.clientID) {
        formDate.append("client_id", JSON.stringify(this.state.clientID));
      }
    } else {
      httpBody = {
        data: {
          attributes: {
            name: values.role_name,
            position: values.positions,
            managers: values.managers,
            is_closed: false
          },
          job_description: {
            data: {
              attributes: {
                job_title: this.state.fetchAllJDData?.jobTitle,
                role_title: this.state.fetchAllJDData?.role,
                preferred_overall_experience_id: this.state.fetchAllJDData?.experience,
                minimum_salary: this.state.fetchAllJDData?.minSalary,
                location: this.state.fetchAllJDData?.location,
                company_description: this.state.fetchAllJDData?.company_desc,
                job_responsibility: this.state.fetchAllJDData?.job_responsibility,
                degree: this.state.fetchAllJDData?.degree,
                fieldOfStudy: this.state.fetchAllJDData?.fieldOfStudy,
                others_skills: otherSkillToArray?.length > 0 ? otherSkillToArray : [],
                currency: this.state.fetchAllJDData?.currency,
                identifier: this.getUnquieId()
              },
              skills: [...this.state.fetchAllJDData?.allSkills]
            }
          }
        }
      };
      if (jdId) {
        httpBody["data"]["attributes"]["id"] = jdId;
      }
      if (this.state.userType == 'admin' && this.state.clientID) {
        httpBody["client_id"] = this.state.clientID;
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewRoleCallId = requestMessage.messageId;

    let endPoint;
    if (values?.isAutomated) {
      if (this.state.requestType == "createRole") {
        endPoint = configJSON.createNewRoleWithFileJDAPIEndPoint;
      } else {
        endPoint = configJSON.updateAutomatedJDEndPoint + jdId;
      }
    } else {
      endPoint = configJSON.createNewRoleAPIEndPoint
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      values?.isAutomated ? JSON.stringify(headerWithJFile) : JSON.stringify(headerWithoutJFile)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      values?.isAutomated ? formDate : JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.requestType == "createRole" ? configJSON.postAPIMethod : configJSON.putAPIMethod
    );
    this.setState({ successSubmit: true });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To stop closing Job Description pop up on background clicking
  handleJobDescription = (event: any, reason: any) => {
    if (reason !== 'backdropClick') {
      this.setState((prev) => ({ showJobDescription: !prev.showJobDescription }));
    }
  }



  //For Job Description Form Handle Start
  handleJDFormData = (values: any) => {
    this.setState((prev) => ({
      ...prev,
      jdFormData: { ...prev.jdFormData, ...values }
    }));
  }

  // To handle Job Description step change to go to "Next" step
  handleNextStep = () => {
    this.setState((prev) => ({
      ...prev,
      currentStep: prev.currentStep + 1
    }));

  }

  // To handle Job Description step change to go to "Previous" step
  handlePreviousStep = (values: any) => {
    this.setState((prev) => ({
      ...prev,
      currentStep: prev.currentStep - 1
    }));
    this.setState((prev: any) => ({ jdFormData: { ...prev.jdFormData, ...values } }));
  }

  //For Job Description Form Handle END

  //For Job Description Data API Calls Start
  getJDTemaplateData = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getJDTemaplateDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getJDTemaplateDataAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    if (this.state.userType == 'admin' && this.state.clientID) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ client_id: this.state.clientID })
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get the roles data based on ID while editing for form prepopulation
  getRoleAllData = (role: any): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRoleAllDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRoleAllDataAPIEndPoint + role
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  //For Job Description Data API Calls End

  // To seach role names
  fetchInputData = (data: any, setFieldValue: any) => {
    this.delaySearch(data, setFieldValue);
  }

  handleRoles = (e: any) => {
    this.setState({ businessVerticle: e.target.value });
  }

  handleSelectedData = (data: any, compareData: any) => {

    data?.map((item: any) => {
      if (compareData?.[item?.category_name]) {
        let selectedData = item?.skilles?.filter((skill: any) => compareData?.[item?.category_name].includes(skill?.id))?.map((skill: any) => ({ ...skill, isDisabled: false }));
        let notSelectedData = item?.skilles?.filter((skill: any) => !compareData?.[item?.category_name].includes(skill?.id))?.map((skill: any) => ({ ...skill, isDisabled: true }));
        item["skilles"] = [...selectedData, ...notSelectedData];
        item['totalSelected'] = selectedData.length || 0;
      } else {
        item['totalSelected'] = 0;
        item["skilles"] = item?.skilles?.map((skill: any) => ({ ...skill, isDisabled: true }))
      }
    });
  }
  handleFetchJDData = (event: "", values: any) => {
    this.setState({ fetchAllJDData: values, errShow: true });
    this.handleJobDescription(event, "submitDone");
  }

  getJobDetails(id: string | number): boolean {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getJobDetailsApiCallId = requestMessage.messageId;
    let endpoint = configJSON.getJobDetailsAPiEndPoint + id;
    if (this.state.userType == 'admin' && this.state.clientID) {
      endpoint = endpoint + (this.clientIDLink ? "&" + this.clientIDLink : "")
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To handle form to prepoluate on edit
  handleFormDataOnEdit = (response: any) => {
    let skilsIds: any = [];
    let allSkillsData: any = [];
    response?.skills?.map((item: any) => {
      let allSkills: any = [];
      skilsIds.push(item.id);
      if (item?.overall_expericen.length > 0) {
        item?.overall_expericen?.map((exp: any) => {
          allSkills.push(exp.id);
        });
        allSkillsData.push({ skill_id: item.id, preferred_overall_experience_ids: allSkills });
      } else if (item.skill_level.length > 0) {
        item?.skill_level?.map((skillLevel: any) => {
          allSkills.push(skillLevel.id);
        });
        allSkillsData.push({ skill_id: item.id, preferred_skill_level_ids: allSkills });
      }
    });
    let domainSkills: any = [];
    let businessFunctionShow: any = {};
    let businessVerticleShow: string = "";
    let selectedDomainSkill: any = {};
    let selectedITSkill: any = {};
    this.state.jobDescriptionData?.domainList?.map((domain: any) => {
      let rPASData: any = [];
      domain.skilles.map((skill: any) => {
        if (skilsIds.includes(skill.id)) {
          if (domain?.category_name == "Risk" || domain.category_name == "Front Office" || domain.category_name == "Finance / MO / Operations") {
            businessFunctionShow[domain?.category_name] = skill.name;
            selectedDomainSkill[domain?.category_name] = [skill.id];
          } else if (domain?.category_name == "Business Understanding") {
            businessVerticleShow = skill.name;
            selectedDomainSkill[domain?.category_name] = [skill.id];
          } else if (domain?.category_name == "Regulatory Programs" || domain?.category_name == "Asset Class") {
            rPASData.push(skill.id);
            selectedDomainSkill[domain?.category_name] = rPASData;
          }

        }
      })
    });

    this.state.jobDescriptionData?.itSkills?.map((itSkill: any) => {
      let iTSkillIds: any = [];
      itSkill?.skilles.map((skill: any) => {
        if (skilsIds.includes(skill.id)) {
          iTSkillIds.push(skill.id);
          selectedITSkill[itSkill?.category_name] = iTSkillIds;
        }
      });
    });

    let formatedData = {
      allSkills: allSkillsData,
      businessFunctionShow: businessFunctionShow,
      businessVerticleShow: businessVerticleShow,
      company_desc: response?.company_description,
      currency: response?.currency,
      degree: response?.degree,
      experience: response?.preferred_overall_experience?.id,
      fieldOfStudy: response?.fieldOfStudy,
      jobTitle: response?.job_title,
      job_responsibility: response?.job_responsibility,
      location: response?.location,
      minSalary: response?.minimum_salary,
      others: response?.others_skill?.toString(),
      role: response?.role_title,
      selectedDomainSkill: selectedDomainSkill,
      selectedITSkill: selectedITSkill,
      showExperience: `${response?.preferred_overall_experience?.level}(${response?.preferred_overall_experience?.experiences_year} years)`,
    }
    this.setState({ fetchAllJDData: formatedData });
  }

  // To handle manager's data
  handleJDManagers = (data: any) => {
    let setManager: any[] = [];
    let managers: any = data.map((manager: any) => manager.email);
    this.state.managersList?.map((item: any) => {
      if (managers?.includes(item?.email)) {
        setManager?.push(item);
      }
    })
    this.setState((prev: any) => ({ jobDetailData: { ...prev?.jobDetailData, attributes: { ...prev?.jobDetailData?.attributes, managers: setManager } } }))
    this.setState({ compulsoryJDUpload: true });
  }

  handleIfChecked = (managers: any, compareWith: any, isJdAutomated: boolean) => {
    let comparedString = `${compareWith.name},(${compareWith.email})`;
    if (isJdAutomated) {
      return managers?.filter((e: any) => e == comparedString).length > 0
    } else {
      return managers?.filter((e: any) => e.email == comparedString).length > 0
    }

  }
  checkWindowResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  }
  // Customizable Area End

}
