import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { Context } from "react";
// @ts-ignore
import InterviewContext, { InterviewContextType } from "../../../web/src/Context/InterviewContext.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  handleCloseModal: any;
  showEditProfileModal: boolean;
  handleCloseEditProfileModal: () => void;
  sendRequestForUpdateProfile: (data: any) => void;
  showEditOtherDetailModal: boolean;
  handleCloseEditOtherDetailModal: () => void;
  sendRequestForUpdateOtherDetail: (data: any, type: string) => void;
  showEditRoleModal: boolean;
  handleCloseEditRoleModal: () => void;
  sendRequestForUpdateRole: (data: any, type: string) => void;
  profileData: any;
  currancyData: any;
  isOpen?: boolean;
  toggleModal?: (data?: any) => void;
  slotDetails?: any;
  history?: any;
  refreshData?: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  snackBar: {
    show: boolean;
    message: string;
    type: any
  };
  profileEditShow: boolean;
  profileData: any;
  currancyData: any;
  phone: any;
  phoneError: boolean;
  updateProfileValidationSchema: any;
  otherDetailEditShow: boolean;
  updateOtherDetailValidationSchema: any;
  roleEditShow: boolean;
  requestType: string;
  prefferedRolesArray: any;
  appliedJobList: any;
  pastAppliedJobList: any;
  savedJobList: any;
  availableMatchingJobsList: any;
  isLoading: boolean;
  tabIndex: number;
  isModalOpen: boolean;
  slotSelected: number;
  modalData: any;
  isInterviewSet: {
    isSet: boolean,
    interviewId: number | null,
    slots: { first_slot: string; second_slot: string; third_slot: string; preffered_slot: string }
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuestionBankController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProfileDataCallId: string;
  updateProfileDataCallId: string;
  updateOtherDetailsCallId: string;
  updateRolesCallId: string;
  getAppliedJobListCallId: string;
  getPastAppliedJobListCallId: string;
  getSavedJobListCallId: string;
  getAllAvailableMatchingJobsApiCallId: string;
  favouriteJobApiCallId: string;
  unlisten: any;

  // Customizable Area Start
  resumeUploadApiId: string;
  removeUploadApiId: string;
  favouriteJobRealTimeHandleApiId: string;
  applyForJobsApiCallId: string;
  avaliableJobRealTimeApiId: string;
  selectInterviewSlotApiId: string;
  static contextType?: Context<InterviewContextType> = InterviewContext;
  createConversationAPIId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    const validationSchema = Yup.object({
      first_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for first name ")
        .required("Please Enter First name"),
      last_name: Yup.string()
        .trim()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for last name ")
        .required("Please Enter Last name"),
      email: Yup.string()
        .trim()
        .email("Email is invalid")
        .max(250, "Email should be of maximum 250 characters length")
        .required("Please Enter Email"),
      current_city: Yup.string()
        .trim()
        .matches(/^[a-zA-Z.]+(?:[\s-][a-zA-Z.]+)*$/, "Only alphabets are allowed for city name ")
        .required("Please Enter Current City Name"),
      phone_number: Yup.string()
        .trim()
        .required("Please Enter Phone Number")
    });

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      snackBar: {
        show: false,
        message: "",
        type: "",
      },
      profileEditShow: false,
      profileData: null,
      currancyData: null,
      phone: null,
      phoneError: false,
      updateProfileValidationSchema: validationSchema,
      otherDetailEditShow: false,
      updateOtherDetailValidationSchema: '',
      roleEditShow: false,
      requestType: "",
      prefferedRolesArray: [],
      appliedJobList: null,
      pastAppliedJobList: null,
      savedJobList: null,
      availableMatchingJobsList: null,
      isLoading: false,
      tabIndex: 0,
      isModalOpen: false,
      slotSelected: 2,
      modalData: null,
      isInterviewSet: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  toggleModal = (data: any) => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
    if (data) {
      if (data.id)
        this.setState({ modalData: data });
    }
  }

  async receive(from: string, message: Message) {

    // runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId != null) {
        // Handle Select Interview Slot
        if (this.selectInterviewSlotApiId === apiRequestCallId) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.props?.refreshData?.();
            const interviewResponse = {
              isSet: true,
              interviewId: responseJson.data.attributes.id as number,
              slots: {
                first_slot: responseJson.data.attributes.first_slot,
                second_slot: responseJson.data.attributes.second_slot,
                third_slot: responseJson.data.attributes.third_slot,
                preffered_slot: responseJson.data.attributes.preferred_slot
              }
            }
            this.setState({
              isInterviewSet: this.state.isInterviewSet.concat(interviewResponse),
              slotSelected: 2,
              snackBar: {
                show: true,
                message: "Interview slot selected successfully",
                type: "success"
              },
            }, () => {
              this.context.setInterview(interviewResponse);
            });
          } else {
            this.setState({ snackBar: { show: true, message: "Someting Went Wrong ...", type: 'error' } });
          }
        }

        // Handle Favorite Job API Call
        if (this.favouriteJobRealTimeHandleApiId === apiRequestCallId) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.getSavedJobList();
          }
        }
        if (this.avaliableJobRealTimeApiId === apiRequestCallId) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.getAllAvailableMatchingJobs();
          }
        }
        // Upload Profile Picture
        if (apiRequestCallId === this.resumeUploadApiId) {
          this.setState({ isLoading: false });
          if (responseJson.status === 500) {
            this.openSnackBarHandler("error", responseJson?.errors || "Resume Upload Failed");
          } else {
            this.openSnackBarHandler("success", "Resume Upload Successfully");
            this.getProfileData();
          }
        }
        // Remove Profile Picture
        if (apiRequestCallId === this.removeUploadApiId) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.openSnackBarHandler('success', "Resume has been deleted successfully.");
            this.getProfileData();
          } else {
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get Profile API
        if (
          apiRequestCallId === this.getProfileDataCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ profileData: responseJson?.data?.attributes, currancyData: responseJson?.currency });
            sessionStorage.setItem("first_name", responseJson?.data?.attributes?.full_name.split(" ")[0]);
            sessionStorage.setItem("last_name", responseJson?.data?.attributes?.full_name.split(" ")[1]);
            sessionStorage.setItem("email", responseJson?.data?.attributes?.email);
            sessionStorage.setItem("current_city", responseJson?.data?.attributes?.current_city);
            sessionStorage.setItem("phone_number", responseJson?.data?.attributes?.phone_number);
            sessionStorage.setItem("profile_image", responseJson?.data?.attributes?.photo);
            sessionStorage.setItem("resume", responseJson?.data?.attributes?.resume);

            this.context.setCandidateName(responseJson?.data?.attributes?.full_name);
            this.getAllAvailableMatchingJobs();
          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Update Profile API
        else if (
          apiRequestCallId === this.updateProfileDataCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.handleEditProfile();
            this.getProfileData();
            this.openSnackBarHandler('success', "Successfully updated profile details");
          } else {
            this.handleEditProfile();
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Update Other Details API
        else if (
          apiRequestCallId === this.updateOtherDetailsCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            if (this.state.requestType == "roles") {
              this.handleEditRole();
            } else if (this.state.requestType == "other") {
              this.handleEditOtherDetail();
            }
            this.getProfileData();
            this.openSnackBarHandler('success', "Profile Updated Successfully");
          } else {
            if (this.state.requestType == "roles") {
              this.handleEditRole();
            } else if (this.state.requestType == "other") {
              this.handleEditOtherDetail();
            }
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get Available Matching Jobs
        else if (
          apiRequestCallId === this.getAllAvailableMatchingJobsApiCallId
        ) {
          if (responseJson !== undefined && !responseJson.errors) {
            console.log("responseJsonresponseJsonresponseJsonresponseJson", responseJson);

            this.setState({ availableMatchingJobsList: responseJson?.shortlisted_for?.data });

          } else {
            const errors = responseJson?.errors;

            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get All Applied Jobs API
        else if (
          apiRequestCallId === this.getAppliedJobListCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ appliedJobList: responseJson?.data || [] });
          } else {
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get All Past Applied Jobs API
        else if (
          apiRequestCallId === this.getPastAppliedJobListCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ pastAppliedJobList: responseJson?.data || null });
          } else {
            const errors = responseJson?.errors;
            if (errors) {
              this.openSnackBarHandler('error', errors);
            }
          }
        }
        //Get All Saved Jobs API
        else if (
          apiRequestCallId === this.getSavedJobListCallId
        ) {
          if (responseJson !== undefined && !responseJson?.errors) {
            this.setState({ savedJobList: responseJson?.data || null });
          } else {
            const errors = responseJson?.errors;
            if (errors) {
              this.setState({ snackBar: { show: true, message: errors, type: 'error' } });
            }
          }
        }
        // conversation creation
        else if (apiRequestCallId === this.createConversationAPIId) {
          if (responseJson) {
            if (responseJson?.table?.errors.includes("already")) {
              this.openSnackBarHandler("success", "Redirecting to chatting.....");
            } else {
              this.openSnackBarHandler("success", "Conversation Created, Redirecing.....");
            }
            setTimeout(() => {
              this.props?.history?.push(
                "/profile-setting/chats?email=" + sessionStorage.getItem("candidate_email")
              );
            }, 1000);
          } else {
            this.openSnackBarHandler("error", "Someting went wrong ....!");
          }
        }
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  // Customizable Area Start

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  // Customizable Area End


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  // handle interview slot selected by candidate
  handleSelectInterview = (slotData: any) => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };
    const httpBody = {
      schedule: {
        preferred_slot: this.state.slotSelected === 1 ?
          "first" : this.state.slotSelected === 2
            ? "second" : this.state.slotSelected === 3 ?
              "third" : "first",
        is_accepted_by_candidate: true, // it'll always be true, because we are not showing reject button to candidate
      },
      zoom: {
        duration: 60,  // fixed
        password: '123456', // fixed
        start_time: this.state.slotSelected === 1 ?
          slotData.first_slot : this.state.slotSelected === 2
            ? slotData.second_slot : this.state.slotSelected === 3 ?
              slotData.third_slot : slotData.first_slot,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectInterviewSlotApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.selectSlotApiEndPoint + slotData.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To get user profile data
  getProfileData = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const httpBody = {
      token: sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileDataAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To update user profile data
  updateProfileData = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const httpBody = {
      data: { attributes: values }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProfileDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileDataAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To update user's data related to job
  updateOtherDetails = (values: any, type: string): boolean => {
    this.setState({ requestType: type });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const httpBody = {
      data: {
        attributes: {
          currency: values.currency,
          current_compensation: values.current_compensation,
          expected_compensation: values.expected_compensation,
          notice_period: { data: values.days, month: values.months },
          location_preference: values.location_preference,
          preferred_role_ids: values.preferred_role_ids
        }
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateOtherDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateOtherDetailsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get "CV" base available jobs list
  getAllAvailableMatchingJobs = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const httpBody = {
      "roles_type": "cv_based",
      "page": 1,
      "per_page": 2
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllAvailableMatchingJobsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAvailableMatchingJobsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get list of applied jobs by candidate
  getAppliedJobList = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppliedJobListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAppliedJobListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get list of past jobs, which are either closed or candidate is rejected from it
  getPastAppliedJobList = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPastAppliedJobListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPastAppliedJobListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To get the list of svaed jobs by the candidate
  getSavedJobList = (): boolean => {
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSavedJobListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSavedJobListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To mark a job favourite
  handleFavorite = (data: { role_id: number, favourite: boolean }) => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteJobApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To mark a job favourite
  handleFavoriteRealTime = (data: { role_id: number, favourite: boolean }) => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouriteJobRealTimeHandleApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAvaliableRealTime = (data: { role_id: number, favourite: boolean }) => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.avaliableJobRealTimeApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: data })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To upload candiadte resume
  uploadResume = (event: any) => {
    const file = event!.target!.files[0];

    const header = {
      'token': sessionStorage.getItem('Token')
    };

    const formData = new FormData();
    formData.append("resume", file);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resumeUploadApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadResumeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    this.setState({ isLoading: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To delete uploaded resume
  removeResume = () => {
    const header = {
      'token': sessionStorage.getItem('Token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.removeUploadApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeResumeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // To apply for a job
  applyForJob = (values: any): boolean => {
    const header = {
      "Content-Type": "application/json",
      "Token": sessionStorage.getItem("Token")
    };

    const data = values;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.applyForJobsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.applyForJobAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // To handle edit profile popup
  handleEditProfile = () => this.setState((prev: any) => ({ profileEditShow: !prev.profileEditShow }));

  // To handle edit role popup
  handleEditRole = () => this.setState((prev: any) => ({ roleEditShow: !prev.roleEditShow }));

  // To handle edit other details popup
  handleEditOtherDetail = () => this.setState((prev: any) => ({ otherDetailEditShow: !prev.otherDetailEditShow }));

  onKeyDownCheckForRole = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  // To handle skill chips
  handleDeleteChip = (chip: any, setFieldValue: any, initiaValue?: any, deletedRole?: any) => () => {
    if (this.state.prefferedRolesArray.includes(chip) && !initiaValue.includes(chip)) {
      let deletedData = [...deletedRole];
      deletedData.push(chip);
      setFieldValue("deleted_role", deletedData);
    }

    if (initiaValue.length > 0) {
      let data = initiaValue.filter((item: any) => item != chip);
      setFieldValue("initial_preferred_role_ids", data);
    }
    this.setState((prev) => ({ prefferedRolesArray: prev.prefferedRolesArray.filter((item: any) => item != chip) }), () => setFieldValue("preferred_role_ids", this.state.prefferedRolesArray))
  }

  // To handle tab change
  getTabDetails = (tab: string | number) => {
    if (tab == 1) {
      this.getAppliedJobList();
    } else if (tab == 2) {
      this.getPastAppliedJobList();
    } else if (tab == 3) {
      this.getSavedJobList();
    }
  }

  // Chat Integration
  createCoversation = (candidate_email: string) => {
    this.openSnackBarHandler("success", "Search in the space...");
    if (candidate_email) {
      sessionStorage.setItem("candidate_email", candidate_email);
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        'token': sessionStorage.getItem('Token')
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createConversationAPIId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_twilio/create_conversations"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ "email": candidate_email })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    } else {
      this.openSnackBarHandler("success", "Email not found ...");
    }
  }
  // Customizable Area End

}
