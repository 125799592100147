import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import CloseIcon from '@material-ui/icons/Close';
import { Box, Button, createTheme, withStyles, ThemeProvider, Typography, Dialog, DialogContent, Grid, TextField, Container } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LandingPage2Controller, {
    Props,
} from "./LandingPage2Controller.web";
import { Link } from "react-router-dom";
import ContactSuccessModal from "./ContactSuccessModal.web";
//@ts-ignore
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'


const theme = createTheme({
    typography: {
        allVariants: {
            textTransform: 'none'
        },
        h1: {
            fontFamily: "Poppins-Medium",
            fontSize: "24px"
        },
        h2: {
            fontFamily: "Poppins-Semibold",
            fontSize: "22px"
        },
        h3: {
            fontFamily: "Poppins-Medium",
            fontSize: "16px"
        },
        h4: {
            fontFamily: "Poppins-Medium",
            fontSize: "14px"
        },
        subtitle1: {
            fontFamily: "Poppins-Regular",
            fontSize: "16px"
        }
    },
    palette: {
        primary: {
            main: "#171725"
        },
        secondary: {
            main: "#a8d0e7"
        },
        text: {
            primary: "#24345f",
            secondary: '#e2e2ea'
        }
    },
});

const useStyles = ({
    fullBtn: {
        width: '100%'
    },
    displayNone: {
        display: "none",

    },
    displayNormal: {
        display: "",
        color: "green",
    },

    link: {
        textDecoration: 'none'
    },
    phoneFieldContainer: {
        "& .PhoneInputInput": {
            padding: 3,
            borderBottom: "1px solid #949494",
            border: 0,
            fontFamily: "Poppins-Regular",
            fontSize: "16px",
            color: "#a3aabc",
            letterSpacing: "0.15008px"
        },

        "& .PhoneInput": {
            "& input": {
                [theme.breakpoints.down("xs")]: {
                    fontSize: "13px",
                },

                "&::placeholder": {
                    color: "currentColor",
                }
            }
        }
    },
    mModal: {
        [theme.breakpoints.down("sm")]: {
            padding: "20px 10px",
        }
    },
    textSecondary: {
        [theme.breakpoints.down("sm")]: {
            fontSize: ".9rem",
        }
    },
    textPrimary: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        }
    },
});

// Regular expression for phone number
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Contact us form validation schema
const validationSchema = Yup.object({
    first_name: Yup.string()
        .trim()
        .min(3, "First name should be of minimum 3 characters length")
        .max(10, "First name should be of maximum 10 characters length")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("First name is a required field"),
    last_name: Yup.string()
        .trim()
        .min(3, "Last name should be of minimum 3 characters length")
        .max(10, "Last name should be of maximum 10 characters length")
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .required("Last name is a required field"),
    phone: Yup.string()
        .trim()
        .matches(phoneRegExp, "Phone number is not valid")
        .max(10, "Phone number should be of maximum 10 number length")
        .required("Phone Number is a required field"),
    email: Yup.string()
        .trim()
        .email("Email is invalid")
        .max(250, "Email should be of maximum 250 characters length")
        .required("Email is a required field"),
    message: Yup.string()
        .trim()
        .min(10, "Message should be of minimum 10 characters")
        .max(200, "Message should be of maximum 200 characters")
        .required("Message is a required field"),
});

class ContactUs extends LandingPage2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={theme} >
                <Dialog open={this.props.open} maxWidth="sm" fullWidth={true}>
                    <DialogContent className={classes.mModal}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box flex="1">
                                <Typography color="primary" align="center" variant="h2">
                                    Contact Us
                                </Typography>
                            </Box>
                            <IconButton onClick={this.props.onClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Container maxWidth="sm">
                            <Box my={2}>
                                <Typography color="textPrimary" align="center" variant="h3" className={classes.textSecondary}>
                                    If you are an employer please fill out this form and we will get back to you as soon as possible
                                </Typography>
                            </Box>
                            <Box>
                                {/* Contact us form start */}
                                <Formik
                                    initialValues={{
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        phone: '',
                                        message: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => { this.contactUs(values) }}
                                >
                                    {({ values, handleChange, errors, dirty, isSubmitting, setFieldTouched, setFieldValue, handleBlur, touched, isValid, handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <Box my={5}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            placeholder="First Name"
                                                            id="first_name"
                                                            name="first_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.first_name}
                                                            error={touched.first_name && Boolean(errors.first_name)}
                                                            helperText={touched.first_name && errors.first_name}
                                                            InputProps={{
                                                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/name.png')} />,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            placeholder="Last Name"
                                                            id="last_name"
                                                            name="last_name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.last_name}
                                                            error={touched.last_name && Boolean(errors.last_name)}
                                                            helperText={touched.last_name && errors.last_name}
                                                            InputProps={{
                                                                startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/name.png')} />,
                                                            }}
                                                        />
                                                    </Grid>

                                                </Grid>
                                            </Box>
                                            <Box my={5}>
                                                <TextField
                                                    fullWidth
                                                    placeholder="Enter Your Email ID"
                                                    id="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    error={touched.email && Boolean(errors.email)}
                                                    helperText={touched.email && errors.email}
                                                    InputProps={{
                                                        startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/email.png')} />,
                                                    }}
                                                />
                                            </Box>
                                            <Box my={5}>
                                                <Box className={classes.phoneFieldContainer}>
                                                    <PhoneInput
                                                        countryOptionsOrder={["US", "GB", "IN"]}
                                                        name="phone"
                                                        autoComplete="off"
                                                        className={classes.PhoneInputInput}
                                                        placeholder="Phone Number"
                                                        onBlur={handleBlur}
                                                        onChange={(e: any) => {
                                                            handleChange;
                                                            this.setState({
                                                                phone: e,
                                                                phoneError: e
                                                                    && !isValidPhoneNumber(String(e))
                                                                    ? true : false
                                                            }, () => { setFieldValue('phone', this.state.phone) })
                                                        }} />
                                                    <Typography variant="caption" style={{ color: "#f44336", display: !this.state.phoneError ? 'none' : 'flex' }}>
                                                        {this.state.phoneError ? "Phone Number is invalid" : ""}
                                                    </Typography>
                                                    <Typography variant="caption" style={{ color: "#f44336", display: Boolean(errors.phone) && !this.state.phone && touched.phone ? 'flex' : 'none' }}>
                                                        Phone Number is required
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box my={5}>
                                                <TextField
                                                    fullWidth
                                                    multiline
                                                    maxRows={4}
                                                    placeholder="Enter Your Message"
                                                    id="message"
                                                    name="message"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                    error={touched.message && Boolean(errors.message)}
                                                    helperText={touched.message && errors.message}
                                                    InputProps={{
                                                        startAdornment: <img width="16px" style={{ marginRight: 15 }} src={require('../assets/message.png')} />,
                                                    }}
                                                />
                                            </Box>
                                            {/* <Typography style={{ color: "green", textAlign: "center" }} className={responseOk ? classes.displayNone : classes.displayNormal = ""}>Your response has been successfully submitted.</Typography> */}
                                            <Box display="flex" justifyContent="center">
                                                <Button
                                                    disabled={!dirty && isValid}
                                                    onClick={() => this.contactUs(values)}
                                                    type="submit" disableRipple variant="contained" color="secondary">
                                                    <Typography variant="h4">
                                                        Submit
                                                    </Typography>
                                                </Button>
                                            </Box>
                                        </form>
                                    )}
                                </Formik>
                                {/* Contact us form end */}
                            </Box>
                            <Box my={2}>
                                <Typography color="textPrimary" align="center" variant="h3" className={classes.textSecondary}>
                                    If you are a candidate searching for job Please share your CV at
                                </Typography>
                            </Box>
                            <Box my={2}>
                                <Typography color="textPrimary" align="center" variant="h1" className={classes.textPrimary}>
                                    info@xcelyst.com
                                </Typography>
                            </Box>
                            <Box my={2}>
                                <Typography align="center" variant="subtitle1" color="textSecondary">
                                    or
                                </Typography>
                            </Box>
                            <Box mb={8} display="flex" justifyContent="center">
                                <Link className={classes.link} to="/jobs" onClick={this.props.onClose}>
                                    <Button disableRipple variant="contained" color="secondary" className={classes.fullBtn} >
                                        <Typography variant="h4">Apply for Open Positions</Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Container>
                    </DialogContent>
                </Dialog>
                {/* To show success pop up */}
                {this.state.openContactSuccessModal ? (
                    //@ts-ignore
                    <ContactSuccessModal showContactSuccessModal={this.state.openContactSuccessModal} handleCloseContactSuccessModal={this.handleCloseSuccessContactModal} />
                ) : ""}
            </ThemeProvider >
        )
    }
}
export default withStyles(useStyles)(ContactUs)