import React from "react";

// Customizable Area Start

import {
  StyleSheet, Platform,
} from "react-native";
import {
  Box,
  Container,
  Typography,
  Card,
  withStyles,
  Button,
  Grid,
  TextField,
  Checkbox,
  CircularProgress,
  Theme
} from "@material-ui/core";

import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { BrowserRouter as Router, Link, withRouter } from "react-router-dom";
import { banner } from "./assets";
import { Formik } from "formik";
import * as Yup from "yup";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import EmailAccountRegistrationVerifyWeb from "./EmailAccountRegistrationVerify.web";
import EmailAccountRegistrationOTPWeb from "./EmailAccountRegistrationOTP.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: "10px",
  boxShadow: 24,
  paddingLeft: "20px",
  paddingRight: "20px",
  height: '80%',
  display: 'block'
};
const blockStyle = (theme: Theme) => ({
  banner: {
    padding: "1rem 2rem",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPositionY: "-15rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0",
    },
  },
  textWhite: {
    color: "#ffffff",
  },
  loginCard: {
    paddingTop: "2em",
    padding: "1.3em",
    border: "2px solid #eaeaea"
  },
  fullBtn: {
    width: "100%",
  },
  container: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "#ffffff",
  },
  input: {
    display: "none",
  },
  uploadBoxError: {
    color: " #f44336",
    margin: "0",
    fontSize: "0.75rem",
    marginTop: "3px",
    textAlign: "left",
    fontFamily: "Poppins-Regular",
    fontWeight: "400",
    lineHeight: "1.5rem",
    letterSpacing: "0.03333em",
    textTransform: "none",
  },
  mContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",

      "& > div": {
        maxWidth: "100%",
      },

      "& .MuiTextField-root": {
        width: "100%"
      }
    }
  },
  phoneInput: {
    "& input": {
      border: "none",
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
      paddingBottom: "0.4rem",
      paddingLeft: "0.5rem",
      color: "inherit",
      fontSize: "1rem",
      outline: "none",
    },
    "& input:focus": {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    "& input::placeholder": {
      color: "#C8C8CB",
      fontSize: "1rem",
      letterSpacing: "0.05rem",
    },
  },
  errorInput: {
    "& input": {
      borderBottom: "2px solid red !important",
    }
  }
});

// Validation schema for signup form
const validationSchema = Yup.object({
  first_name: Yup.string()
    .trim()
    .required("Please Enter First Name")
    .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/, "Only alphabets are allowed for this field "),
  last_name: Yup.string()
    .trim()
    .required("Please Enter Last Name")
    .matches(/^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/, "Only alphabets are allowed for this field "),
  email: Yup.string()
    .trim()
    .email("Invalid email")
    .required("Please Enter Email Address")
    .max(250, "Email should be of maximum 250 characters length"),
  phone_number: Yup.string()
    .trim()
    .required("Phone is a required field"),
  password: Yup.string()
    .trim()
    .required("Please Enter Password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
      "Pasword must contain minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character"
    ),
  repeat_pass: Yup.string()
    .trim()
    .required("Please re-enter your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  city: Yup.string()
    .trim()
    .required("Please Enter Current Location")
    .max(200, "Current Location name should be of maximum 200 characters length")
    .matches(/^[a-zA-Z.]+(?:[\s-][a-zA-Z.]+)*$/, "Only alphabets are allowed for current location"),
  agree: Yup.boolean().oneOf([true], 'Please accept Privacy Policy and Terms & Conditions'),
  resume: Yup
    .mixed()
    .required("Please upload resume")
});
// Customizable Area End

export var email1: String = "";


class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start

    window.scrollTo(0, 0);

    // this.doPresignedUrl();
    // Customizable Area End
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return (
      <>
        {this.state.signupStep == "signup" ? (
          <Box className={classes.banner}>
            <Box mb={8}>
              <Typography align="center" className={classes.textWhite} variant="h2">
                Welcome to Xcelyst
              </Typography>
            </Box>
            <Container maxWidth="sm">
              <Card className={classes.loginCard}>
                <Typography align="center" color="primary" variant="body1">
                  CANDIDATE SIGNUP
                </Typography>
                <Box my={3}>
                  <Typography color="primary" align="center" variant="h5">
                    Sign Up to Find Your
                  </Typography>
                  <Typography color="primary" align="center" variant="h5">
                    Next Dream Job
                  </Typography>
                </Box>
                <Box>
                  {/* Signup form start */}
                  <Formik
                    initialValues={{
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      repeat_pass: "",
                      city: "",
                      agree: false,
                      resume: "",
                      phone_number: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values: any) => {
                      this.doCreateAccountWithEmail(values, this.state.presigned_url[0]?.public_url, this.state.presigned_url[1]?.public_url)
                    }}
                  >
                    {(formikProps) => (
                      <form onSubmit={formikProps.handleSubmit}>
                        <Box my={5}>
                          <Grid container spacing={3} className={classes.mContainer}>
                            <Grid item xs={6}>
                              <TextField
                                placeholder="First Name"
                                id="first_name"
                                name="first_name"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values.first_name}
                                error={
                                  formikProps.touched.first_name &&
                                  Boolean(formikProps.errors.first_name)
                                }
                                helperText={
                                  formikProps.touched.first_name &&
                                  formikProps.errors.first_name
                                }
                                InputProps={{
                                  startAdornment: (
                                    <img
                                      width="16px"
                                      style={{ marginRight: 15 }}
                                      src={require("../assets/name.png")}
                                    />
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                placeholder="Last Name"
                                id="last_name"
                                name="last_name"
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values.last_name}
                                error={
                                  formikProps.touched.last_name &&
                                  Boolean(formikProps.errors.last_name)
                                }
                                helperText={
                                  formikProps.touched.last_name &&
                                  formikProps.errors.last_name
                                }
                                InputProps={{
                                  startAdornment: (
                                    <img
                                      width="16px"
                                      style={{ marginRight: 15 }}
                                      src={require("../assets/name.png")}
                                    />
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box my={5}>
                          <TextField
                            fullWidth
                            placeholder="Enter Your Email ID"
                            id="email"
                            name="email"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.email}
                            error={
                              formikProps.touched.email &&
                              Boolean(formikProps.errors.email)
                            }
                            helperText={
                              formikProps.touched.email && formikProps.errors.email
                            }
                            InputProps={{
                              startAdornment: (
                                <img
                                  width="16px"
                                  style={{ marginRight: 15 }}
                                  src={require("../assets/email.png")}
                                />
                              ),
                            }}
                          />
                        </Box>
                        <Box my={5}>
                          <PhoneInput
                            countryOptionsOrder={["US", "GB", "IN"]}
                            name="phone_number"
                            autoComplete="off"
                            placeholder="Phone Number"
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.phone_number}
                            className={`${classes.phoneInput} ${Boolean(formikProps.touched.phone_number && formikProps.errors.phone_number)
                              ? classes.errorInput : ""}`}
                            onChange={(e: any) => {
                              formikProps.handleChange;
                              this.setState({
                                phone: e,
                                phoneError: e
                                  && !isValidPhoneNumber(String(e))
                                  ? true : false
                              }, () => { formikProps.setFieldValue('phone_number', this.state.phone) })
                            }} />
                          <Typography variant="caption" style={{ color: "#f44336", display: !this.state.phoneError ? 'none' : 'flex' }}>
                            {this.state.phoneError ? "Phone Number is invalid" : ""}
                          </Typography>
                          <Typography variant="caption" style={{
                            color: "#f44336", display:
                              Boolean(formikProps.touched.phone_number && formikProps.errors.phone_number)
                                && !this.state.phone ? 'flex' : 'none'
                          }}>
                            Phone Number is required
                          </Typography>
                        </Box>
                        <Box my={5}>
                          <Grid container spacing={3} className={classes.mContainer}>
                            <Grid item xs={6}>
                              <TextField
                                placeholder="Create Password"
                                id="password"
                                name="password"
                                type={
                                  this.state.enablePasswordField
                                    ? "password"
                                    : "text"
                                }
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values.password}
                                error={
                                  formikProps.touched.password &&
                                  Boolean(formikProps.errors.password)
                                }
                                helperText={
                                  formikProps.touched.password &&
                                  formikProps.errors.password
                                }
                                InputProps={{
                                  startAdornment: (
                                    <img
                                      width="16px"
                                      style={{ marginRight: 15 }}
                                      src={require("../assets/password.png")}
                                    />
                                  ),
                                  endAdornment: (
                                    <img
                                      width="24px"
                                      onClick={() => {
                                        this.setState({
                                          enablePasswordField: !this.state
                                            .enablePasswordField,
                                        });
                                      }}
                                      style={{ marginRight: 15 }}
                                      src={
                                        this.state.enablePasswordField
                                          ? require("../assets/show_password.png")
                                          : require("../assets/hide_password.png")
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                placeholder="Repeat Password"
                                id="repeat_pass"
                                name="repeat_pass"
                                type={
                                  this.state.enableConfirmPasswordField
                                    ? "password"
                                    : "text"
                                }
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                value={formikProps.values.repeat_pass}
                                error={
                                  formikProps.touched.repeat_pass &&
                                  Boolean(formikProps.errors.repeat_pass)
                                }
                                helperText={
                                  formikProps.touched.repeat_pass &&
                                  formikProps.errors.repeat_pass
                                }
                                InputProps={{
                                  startAdornment: (
                                    <img
                                      width="16px"
                                      style={{ marginRight: 15 }}
                                      src={require("../assets/password.png")}
                                    />
                                  ),
                                  endAdornment: (
                                    <img
                                      width="24px"
                                      onClick={() => {
                                        this.setState({
                                          enableConfirmPasswordField: !this.state
                                            .enableConfirmPasswordField,
                                        });
                                      }}
                                      style={{ marginRight: 15 }}
                                      src={
                                        this.state.enableConfirmPasswordField
                                          ? require("../assets/show_password.png")
                                          : require("../assets/hide_password.png")
                                      }
                                    />
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box my={5}>
                          <TextField
                            fullWidth
                            placeholder="Current Location"
                            id="city"
                            name="city"
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.city}
                            error={
                              formikProps.touched.city &&
                              Boolean(formikProps.errors.city)
                            }
                            helperText={
                              formikProps.touched.city && formikProps.errors.city
                            }
                            InputProps={{
                              startAdornment: (
                                <img
                                  width="16px"
                                  style={{ marginRight: 15 }}
                                  src={require("../assets/current_city.png")}
                                />
                              ),
                            }}
                          />
                        </Box>
                        <Box mt={5} >
                          <Typography variant="body2" color="primary">
                            {this.state.fileName}
                          </Typography>
                        </Box>
                        <Box mb={5} mt={3}>
                          <input
                            accept="application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => this.fileHandler(event, formikProps.setFieldValue, "resume")}

                          />
                          <label htmlFor="contained-button-file">
                            <Button className={classes.fullBtn} disableRipple variant="contained" component="span" color="secondary">
                              <Typography variant="body2" color="primary">
                                Upload Resume
                              </Typography>
                            </Button>
                          </label>
                          {formikProps.errors.resume ? (<p className={classes.uploadBoxError}>{formikProps.errors.resume}</p>) : null}
                          {this.state.uploadRBError && (
                            <p className={classes.uploadBoxError}>File Size Exceeded 2MB</p>
                          )}
                          {/* this code is commented by Surbhi jain as discussed with vishwa 
                          this functionality not needed now */}
                          {/* <Box my={2}>
                            <input
                              accept="application/pdf,application/msword,
                              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              className={classes.input}
                              id="contained-button-coverletter"
                              type="file"
                              onChange={(event) => this.fileHandler(event, formikProps.setFieldValue, "coverLetter")}
                            />
                            <label htmlFor="contained-button-coverletter">
                              <Typography
                                align="center"
                                variant="body1"
                                color="primary"
                              >
                                Upload Cover Letter(Optional)
                              </Typography>
                            </label>
                            {this.state.uploadCBError && (
                              <p className={classes.uploadBoxError}>File Size Exceeded 2MB</p>
                            )}
                          </Box> */}
                          <Box my={2}>
                            <Typography
                              align="center"
                              variant="h6"
                              color="textPrimary"
                            >
                              doc, docx, rtf, pdf - 2MB max.
                            </Typography>

                          </Box>
                        </Box>
                        <Box my={2} px={6} display="flex" justifyContent="space-between" flexDirection="row" style={{ paddingLeft: "32px", paddingRight: "32px" }}>
                          <Typography align="center" variant="h6" style={{ color: "grey" }}>
                            <Checkbox
                              id="agree"
                              name="agree"
                              onChange={formikProps.handleChange}
                              onBlur={formikProps.handleBlur}
                              value={formikProps.values.agree}
                            />
                            I agree that I have read, understand and agree to the <br /> <Link to="/privacy-policy"><Typography variant="overline" color="primary">'Privacy Policy'</Typography></Link> and <Link to="/terms-and-conditions"><Typography variant="overline" color="primary">'Terms and Conditions'</Typography></Link>
                          </Typography>
                        </Box>
                        {formikProps.errors.agree ? (<p className={classes.uploadBoxError}>{formikProps.errors.agree}</p>) : null}
                        <Button type="submit" className={classes.fullBtn} disableRipple variant="contained" color="primary" disabled={this.state.loading}>
                          <Typography variant="body2">
                            Sign Up
                          </Typography>
                          {this.state.loading && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#24325f",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Button>
                        <Box my={2}>
                        </Box>
                      </form>
                    )}
                  </Formik>
                  {/* Signup form end */}
                </Box>
              </Card>
              <Box
                mt={3}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box display="flex" justifyContent="center">
                  <Link
                    to="/login/?type=candidate"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography color="primary" variant="body1">
                      Already Have an Account?&nbsp;
                      <Typography
                        style={{ fontWeight: 700 }}
                        color="primary"
                        variant="body2"
                        component="span"
                      >
                        Click Here To Login
                      </Typography>
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Container>
          </Box>
        ) : ""}
        {/* For email verification */}
        {this.state.signupStep == "verifyEmail" ? (
          //@ts-ignore
          <EmailAccountRegistrationVerifyWeb verifyIt={this.handleToOtpScreen} accountEmail={this.state.accountEmail} alreadyExistsMsg={this.state.alreadyExistsMsg} showLoader={this.state.loading}
          />
        ) : ""}

        {/* For OTP verification */}
        {this.state.signupStep == "otpVerify" ? (
          // @ts-ignore
          <EmailAccountRegistrationOTPWeb verifyIt={this.handleToOtpScreen} />
        ) : ""}

        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
    // Customizable Area End
  }


}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10,
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold",
  },
});
// Customizable Area End
// @ts-ignore
export default withStyles(blockStyle)(withRouter(EmailAccountRegistration));
