// Customizable Area Start
import * as React from 'react';
import { usePagination } from '@material-ui/lab';
import { styled } from '@material-ui/styles';
import PaginationStyle from './Styles.module.scss';
const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
});

export default function Pagination(props: any) {

    const { items } = usePagination({
        count: props.totalPages,
        defaultPage: props.defaultPages

    });
    return (
        <nav className={PaginationStyle.pagination}>
            <List>
                {
                    items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;
                        if (props.totalPages === 1) {
                            return ""
                        }
                        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                            children = '…';
                        } else if (type === 'page' && items[index ? index - 1 : 0].type !== 'end-ellipsis') {
                            let pageVal = page < 10 ? "0" + page : page;
                            children = (
                                <button
                                    type="button"
                                    style={{
                                        fontWeight: selected ? 'bold' : undefined,
                                        color: selected ? 'inherit' : '#96969f',
                                        cursor: "pointer"
                                    }}
                                    {...item}
                                >

                                    {pageVal}
                                </button>
                            );
                        } else {

                            if (props.totalPages > 1) {
                                if (type == 'next') {
                                    children = (
                                        items?.length > 1 &&
                                        <button type="button" {...item}
                                        >
                                            Next
                                        </button>
                                    );
                                }
                                if (type == 'previous') {
                                    children = (
                                        items?.length > 1 &&
                                        <button type="button" {...item}
                                        >
                                            Prev
                                        </button>
                                    );
                                }
                            }

                        }

                        return <li className={PaginationStyle.listItem} key={index} onClick={() => type === "end-ellipsis" || type === "start-ellipsis" ? "" : props.onPageNumSelect(page)}>{children}</li>;
                    })}
            </List>
        </nav>
    );
}
// Customizable Area End