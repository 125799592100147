import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Context } from "react";
// @ts-ignore
import LoaderContext, { LoaderontextType } from "../../../web/src/Context/LoaderContext.web";
import _ from "lodash";
import { v4 as uuid } from 'uuid';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match?: { params: { id: string | number } };
    location?: any;
    history?: any;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    currentIndex: number;
    maxItems: number;
    windowWidth: number;
    databaseList: any;
    totalDatabaseCount: number;
    totalPages: number;
    defaultPages: number;
    selectedDBData: any;
    showDatabaseData: boolean;
    onPageLoad: boolean;
    suggesionType: string;
    locationList: any,
    titleList: any;
    companyList: any;
    keywordsList: any;
    eSParams: any;
    loading: boolean;
    minExperience: number | string;
    maxExperience: number | string;
    location: string;
    skills: string;
    job_description: string;
    keywords: string[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CandidateDatabaseController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    static contextType?: Context<LoaderontextType> = LoaderContext;
    getDatabaseListApiCallId: string;
    elasticSearchCallId: string;
    advanceSearchCallId: string;
    watchedCandidateDataCallId: string;
    getFilterSuggessionListApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            currentIndex: 0,
            maxItems: 4,
            windowWidth: window.innerWidth,
            databaseList: [],
            totalDatabaseCount: 0,
            totalPages: 1, // Change it to 0 Later
            defaultPages: 1,
            selectedDBData: {},
            showDatabaseData: true,
            onPageLoad: true,
            suggesionType: "",
            locationList: null,
            titleList: null,
            companyList: null,
            keywordsList: [],
            eSParams: {},
            loading: false,
            minExperience: "",
            maxExperience: "",
            location: '',
            skills: '',
            job_description: '',
            keywords: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        // runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                //Get Database List API
                if (
                    apiRequestCallId === this.getDatabaseListApiCallId
                ) {
                    this.setState({ loading: false });
                    if (responseJson !== undefined && !responseJson?.errors) {
                        // 16 Jan comment && responseJson?.data?.length > 0
                        if (responseJson?.data) {

                            let validCandidate = responseJson?.data?.filter((item: any) => this.isDataEmpty(item.attributes));
                            console.log("THIS IS DATA =======> ",validCandidate )
                            console.log("COUNTT =======> ",responseJson?.data )
                            
                            this.setState({
                                databaseList: validCandidate ? [...validCandidate] : [],
                                totalDatabaseCount: responseJson?.meta?.total_profile.toLocaleString() || "0",
                                totalPages: responseJson?.meta?.total_pages || 0
                            }, ()=> {
                                console.log("databaseList ======", this.state.databaseList)
                            } );
                            if (this.state.onPageLoad) {
                                this.setState({
                                    selectedDBData: validCandidate ? responseJson?.data[0]?.attributes : {},
                                    // onPageLoad: false
                                });
                            }
                        } else {
                            this.setState({
                                showDatabaseData: false
                            });
                        }
                    } else {
                        const errors = responseJson?.errors;

                        if (errors) {
                            this.openSnackBarHandler('error', errors);
                        }
                    }
                }

                if (
                   
                    apiRequestCallId === this.advanceSearchCallId
                ) {
                    this.setState({ loading: false });
                    if (!responseJson?.errors) {
                        // 16 Jan comment && responseJson?.data?.length > 0
                        if (responseJson?.data) {

                            console.log("COUNTT advanceSearchCallId=======> ",responseJson?.data )
                            
                            this.setState({
                                databaseList: responseJson?.data,
                                totalDatabaseCount: responseJson?.meta?.total_record?.toLocaleString() || "0",
                                totalPages: responseJson?.meta?.total_pages || 0
                            }, ()=> {
                                console.log("databaseList advanceSearchCallId ======>>>>", this.state.databaseList)
                            } );
                            if (this.state.onPageLoad) {
                                this.setState({
                                    selectedDBData: responseJson?.data[0] ? responseJson?.data[0]?.attributes : {},
                                    // onPageLoad: false
                                });
                            }
                        } else {
                            this.setState({
                                showDatabaseData: false
                            });
                        }
                    } else {
                        const errors = responseJson?.errors;

                        if (errors) {
                            this.openSnackBarHandler('error', errors);
                        }
                    }
                }


                 if (
                    apiRequestCallId === this.elasticSearchCallId
                ) {
                    this.setState({ loading: false });
                    if (responseJson !== undefined && !responseJson?.errors) {
                        // 16 Jan comment && responseJson?.data?.length > 0
                        if (responseJson?.data) {

                            let validCandidate = responseJson?.data;
                            console.log("THIS IS DATA =======> ",validCandidate )
                            console.log("COUNTT =======> ",responseJson?.data )
                            
                            this.setState({
                                databaseList: validCandidate ? validCandidate : [],
                                totalDatabaseCount: responseJson?.meta?.total_profile.toLocaleString() || "0",
                                totalPages: responseJson?.meta?.total_pages || 0
                            }, ()=> {
                                console.log("databaseList ======", this.state.databaseList)
                            } );
                            if (this.state.onPageLoad) {
                                this.setState({
                                    selectedDBData: validCandidate ? responseJson?.data[0]?.attributes : {},
                                    // onPageLoad: false
                                });
                            }
                        } else {
                            this.setState({
                                showDatabaseData: false
                            });
                        }
                    } else {
                        const errors = responseJson?.errors;

                        if (errors) {
                            this.openSnackBarHandler('error', errors);
                        }
                    }
                }

                //Watched Candidate API
                if (
                    apiRequestCallId === this.watchedCandidateDataCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                    } else {
                    }
                }
                //Filter Suggestion API
                if (
                    apiRequestCallId === this.getFilterSuggessionListApiCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                        this.setState({ ...this.state, [`${this.state.suggesionType}List`]: responseJson?.sugg || [] })

                    } else {
                    }
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start

    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    checkWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    //Get Database results
    getDatabaseList = (page: number): boolean => {
        const header = {
            "Content-Type": "application/json",
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDatabaseListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDatabaseListAPiEndPoint + "?page=" + page
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Elastic Search
    watchedCandidateData = (id: any): boolean => {
        const header = {
            "Content-Type": "application/json",
        };

        let httpBody = {
            temporary_user_database_id: id,
            ip_address: localStorage.getItem("currentIPA")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.watchedCandidateDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.watchedCandidateDataAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Elastic Search
    elasticSearch = (page: any): boolean => {
        let currentIPA = localStorage.getItem("currentIPA");
        if (!currentIPA) {
            currentIPA = uuid();
            localStorage.setItem("currentIPA", currentIPA);
        }
        const header = {
            "Content-Type": "application/json",
            'Token': sessionStorage.getItem('Token')
        };

        let httpBody: any = { ...this.state.eSParams, page: page };
        if (this.state.eSParams?.current == "Current") {
            httpBody['current'] = true;
        } else if (this.state.eSParams?.current == "Past") {
            httpBody['current'] = false;
        } else {
            delete httpBody['current']
        }
        if (this.state.eSParams?.watched == 'Show "Watched"') {
            httpBody['watched'] = true;
        } else if (this.state.eSParams?.watched == 'Exclude "Watched"') {
            httpBody['watched'] = false;
        } else {
            delete httpBody['watched'];
        }
        httpBody['ip_address'] = currentIPA;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.elasticSearchCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDatabaseListAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    //Advance Search
    AdvanceSearch = (page: any): boolean => {
        this.setState({loading: true})
        let currentIPA = localStorage.getItem("currentIPA");
        if (!currentIPA) {
            currentIPA = uuid();
            localStorage.setItem("currentIPA", currentIPA);
        }
        const header = {
            "Content-Type": "application/json",
            'Token': sessionStorage.getItem('Token')
        };

        let httpBody = {
            minExperience: this.state.minExperience,
            maxExperience: this.state.maxExperience,
            skills: this.state.skills?.split(",")?.map(item => item.trim()) || [],
            location: this.state.location,
            job_description: this.state.job_description,
            keywords: this.state.keywords
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.advanceSearchCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.advanceSearchApiEndPoint}?page=1`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // To check if the received data is empty
    isDataEmpty = (candidate: any) => {
        if (_.isEmpty(candidate.full_name) || _.isEmpty(candidate.temporary_user_profile)) {
            return false
        } else {
            return true
        }
    }

    //Get Filter suggesion results
    getFilterSuggessionList = (type: string, searchText: string): boolean => {
        const header = {
            "Content-Type": "application/json",
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFilterSuggessionListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getFilterSuggessionListAPiEndPoint + `?${type}=` + searchText
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        // this.setState({ ...this.state, [type]: searchText });
        this.setState({ suggesionType: type });

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

      handleSelectedCandidateData = (data: any) => {
        console.log("Selected candidate data:", data);
        window.scroll(0, 0);
        let currentList: any = [...this.state.databaseList];
        // let newList = currentList.map((item: any) => data?.id == item?.id ? { ...item, attributes: { ...item.attributes, watched_records: true } } : { ...item });
        this.setState({ selectedDBData: data, 
          // databaseList: [...newList] 
        }, () => {
          console.log(this.state.selectedDBData, "selectedDBData")
        });
        this.watchedCandidateData(data?.id);
}
    // Customizable Area End

}
