'use strict';

// const baseURL = '__MARKER_FOR_BACKEND_URL_REPLACEMENT';
// const baseURL = 'https://hiringplatform-74392-ruby.b74392.dev.us-east-1.aws.svc.builder.cafe';
// const baseURL = 'https://padmin.xcelyst.ai';
// const baseURL = ENV.BE_API_ENDPOINT;
const baseURL = process.env.REACT_APP_BE_API_ENDPOINT






Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.baseURL = baseURL;