import React from 'react';
import { Box, Button, Divider, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { LocalAtmOutlined, InfoOutlined } from '@material-ui/icons';
import { LocationIcon } from '../../../components/src/assets';
import JobList from './JobList';
import { dummyJobs } from './mock/dummyJobs';
import JobListing2Controller, { Props } from './JobListing2Controller.web';
import { withRouter, Link } from "react-router-dom";
import { ReactComponent as WorkOutlineOutlined } from "../assets/job_type.svg";
import { createMarkup } from '../../LandingPage2/src/LandingPage2.web';
import ShareModal from './ShareModal.web';
import AppSidebar from '../../../components/src/AppSidebar';

const dummyData = {
    title: 'Software Engineer',
    company: 'Apple Inc.',
    location: 'Mountain View, CA',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    responsibilities: ["Lorem ipsum dolor sit amet, sed do eiusmod tempor.", "Lorem ipsum dolor sit eiusmod tempor.", "Lorem ipsum dolor sit amet, sed do eiusmod tempor vaena.", "Lorem ipsum amet, sed do eiusmod tempor.", "Lorem ipsum dolor sit amet, consectetur adipiscing.", "Lorem ipsum dolor sit amet, consectetur adipiscing irure dolor in reprehenderit."],
    skills: ["Prototyping", "Sketching", "InDesign", "After Effects", "Premiere Pro", "Adobe XD", "Photoshop"],
    expricence: "2-3 Years Experience",
    salary: "$10000 - $125000",
    benefits: ["Flexible Shift", "Perfomance Bonus", "Quarterly bonus", "Work from home", "Paid sick time", "Food allowance"]
}

export const formatData = (str: string) => {
    str = str.replaceAll("\r\n\r\n\r\n", "\r\n\r\n");
    const splitData = str.split("\r\n");
    const data = str.split("\r\n");
    //const data = str.split("\r\n\r\n");
    data.forEach((each) => {
        const heading = each.split(":")[0] + ":";
        const index = splitData.indexOf(heading);
        splitData[index] = `<b style="font-size:1.2rem; letter-spacing:.1rem; color: #24325F;">${heading}</b>`;     // format the
    });
    return splitData.join("\r\n");
};

const useStyles = (theme: any) => ({
    mainContainer: {
        margin: "5rem 8rem",
        "& h4": {
            marginBottom: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            margin: "1rem"
        }
    },
    container: {
        background: '#fff',
        padding: "2rem",
        borderRadius: "1rem",

        [theme.breakpoints.down('sm')]: {
            "& > div:first-child": {
                "& > div:last-child": {
                    // marginLeft: "auto",

                    "& button span": {
                        fontSize: "1rem",
                    }
                }
            }
        }
    },
    button: {
        fontWeight: 600,
        padding: ".5rem 2rem",
        fontFamily: 'Poppins-Regular',
        fontSize: "0.9rem",
        "&:not(:last-child)": {
            marginRight: ".8rem",
        }
    },
    msgButton: {
        background: "#F1F1F5",
        color: "#696974"
    },
    applyButton: {
        background: "#24325F",
        color: "#fff",
        textDecoration: "none",
        borderRadius: ".5rem",
        cursor: "pointer",
        transition: "all .3s ease-in-out",
        "&:hover": {
            opacity: ".9",
        },
        "&:active": {
            opacity: ".2",
        }
    },
    ButtonAlreadyApplied: {
        "&.Mui-disabled": {
            backgroundColor: "#fff",
            color: "#24325F",
            boxShadow: "none",
            borderRadius: ".8rem",
            border: "2px solid #24325F",
            "&:hover": {
                backgroundColor: "#fff",
            }
        }
    },
    companyName: {
        fontSize: "1.4rem",
        margin: ".8rem 0 1.5rem",
    },
    details: {
        marginBottom: "2rem",

        "& div": {
            display: "flex",
            alignItems: "center",

            "&:not(:last-child)": {
                marginRight: "3rem",
            },

            "& svg,& img": {
                marginRight: ".6rem",
            }
        }
    },
    description: {
        margin: "2rem 0",
    },
    list: {
        paddingLeft: "1.5em",
        "& li::marker": {
            fontSize: "1.5rem",
            color: "#24325F",
        },

        "& li": {
            "&:not(:last-child)": {
                marginBottom: "1rem",
            }
        }
    },
    skills: {
        margin: "2rem 0 1rem",

        "& div": {
            display: "flex",
            marginTop: ".4rem",

            "& div": {
                borderRadius: ".5rem",
                marginBottom: ".5rem",
                padding: ".5rem .8rem",
                background: "#D8D8D8",
                color: "#44444F",

                "&:not(:last-child)": {
                    marginRight: ".8rem",
                },

                "& p": {
                    marginRight: ".5rem",
                },

                "& svg": {
                    cursor: "pointer",

                    "&:hover": {
                        color: "#000",
                    }
                }
            }
        }
    },
    jobs: {
        margin: "2rem 0 0",

        [theme.breakpoints.down('sm')]: {
            "& span": {
                fontSize: "1rem",
            }
        }
    }
});

class JobDetails extends JobListing2Controller {

    constructor(props: Props) {
        super(props);

    }
    async componentDidMount() {
        this.handlePageLoad();
        window.scrollTo(0, 0);
        window.addEventListener('resize', this.checkWindowResize);
    }
    async componentDidUpdate(prevProps: any) {
        if (prevProps.location != this.props.location) {
            this.handlePageLoad();
        }

    }

    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }
    render() {
        const { classes } = this.props;
        let jobMetaData: any = this.jobDtaFunction();
        const user_role = this.state.jobDetailData?.attributes?.user_role;

        if (this.state.jobDetailData === undefined) {
            this.props.history.push('/route-not-found');
        }

        let min = this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MinimumYears?.Value;
        let max = this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value;

        return (
            <>
                {this.state.isShareModalOpen && (
                    <ShareModal
                        open={this.state.isShareModalOpen}
                        onClose={this.toggleShareModal}
                    />
                )}
                {/* <AppSidebar /> */}
                <Box className={classes.mainContainer} style={{ marginTop: "5rem" }}>
                    <Box className={classes.container} style={{ marginBottom: `${user_role === "client" ? "2em" : "0"}` }}>
                        <Grid container justifyContent='space-between' alignItems='center'>
                            <Grid item xl={6} lg={6} md={6} xs={12} sm={12}>
                                <Typography variant='h4'>
                                    {this.state.jobDetailData?.attributes?.name || "-"}
                                </Typography>
                            </Grid>
                            <Box display="flex" alignItems="center" flexWrap='wrap'>
                                {!this.state.jobDetailData?.attributes?.is_closed &&
                                    <Grid item style={{ marginBottom: "1rem", marginRight: "1rem" }}>
                                        <Link to={'/open-roles'}
                                            className={`${classes.applyButton} ${classes.button}`}
                                            href={'/open-roles'}
                                        >
                                            Open Roles
                                        </Link>
                                    </Grid>
                                }
                                {!this.state.jobDetailData?.attributes?.is_closed &&
                                    <Grid item style={{ marginRight: "1rem", marginBottom: "1rem" }}>
                                        <a className={`${classes.applyButton} ${classes.button}`}
                                            onClick={this.toggleShareModal}
                                        >
                                            Share Job
                                        </a>
                                    </Grid>}
                                {user_role === "candidate" &&
                                    <Grid item>
                                        <Button
                                            style={{ marginBottom: "1rem" }}
                                            className={`${classes.msgButton} ${classes.button}`}
                                            onClick={() => this.createCoversation(this.state.jobDetailData?.attributes?.company_email)}
                                        >
                                            Message
                                        </Button>
                                        <Button
                                            style={{ marginBottom: "1rem" }}
                                            onClick={() => user_role === "candidate" && this.applyForJob({
                                                applied_job: {
                                                    job_description_id: this.state.jobDetailData?.attributes?.job_description?.job_description_id
                                                }
                                            })}
                                            className={`${classes.button} ${this.state.isApplied ? classes.ButtonAlreadyApplied : classes.applyButton}`}
                                            disabled={this.state.isApplied}
                                        >
                                            {this.state.isApplied ? "Already Applied" : "Apply Now"}
                                        </Button>
                                    </Grid>
                                }
                                {(user_role === "client" && !this.state.jobDetailData?.attributes?.is_closed) &&
                                    <Grid item style={{ marginBottom: "1rem" }}>
                                        <Link to={`/editRole/${this.state.jobDetailData?.id}${this.state.userType == 'admin' && this.state.clientID ? "?clientId=" + this.state.clientID : ""}`}
                                            className={`${classes.applyButton} ${classes.button}`}
                                            href={`/editRole/${this.state.jobDetailData?.id}${this.state.userType == 'admin' && this.state.clientID ? "?clientId=" + this.state.clientID : ""}`}
                                        >
                                            Edit Job Description
                                        </Link>
                                    </Grid>
                                }
                                {user_role === "guest_user" &&
                                    <Grid item style={{ marginBottom: "1rem" }}>
                                        <Link to={`/login/?type=candidate`}
                                            className={`${classes.applyButton} ${classes.button}`}
                                            href={`/login/?type=candidate`}
                                            onClick={() => sessionStorage.setItem("redirect_url", `/job-detail/${this.state.jobDetailData?.id}`)}
                                        >
                                            Login to Apply
                                        </Link>
                                    </Grid>
                                }
                            </Box>
                        </Grid>
                        <Box>
                            <Typography variant="h5" className={classes.companyName}>
                                {this.state.jobDetailData?.attributes?.company_name || "-"}
                            </Typography>
                        </Box>
                        <Grid container className={classes.details}>
                            <Box>
                                <WorkOutlineOutlined />
                                <Typography>
                                    {(this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MinimumYears?.Value || this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value) ?
                                        <>
                                            {min ?
                                                max ?
                                                    min == max ?
                                                        max == 0 ?
                                                            "NA"
                                                            : min + " Yeras"
                                                        : min + " - " + max + " Years"
                                                    : min == 0 ?
                                                        "NA"
                                                        : min + " Years and above"
                                                : max
                                                    ? "up to " + max + " Years"
                                                    : "NA"}


                                            {/* {this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MinimumYears?.Value ? this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MinimumYears?.Value : ""} */}
                                            {/* {this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MinimumYears?.Value ? this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value ? "-" + this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value : "" : this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value ? this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.MaximumYears?.Value : ""} */}
                                        </> :
                                        <>
                                            {this.state.jobDetailData?.attributes?.job_description?.preferred_overall_experience?.experiences_year == 0 ? "NA"
                                                : this.state.jobDetailData?.attributes?.job_description?.preferred_overall_experience?.experiences_year == "NA" ? "NA"
                                                    : this.state.jobDetailData?.attributes?.job_description?.preferred_overall_experience?.experiences_year + " Years" || 0}
                                        </>
                                    }
                                </Typography>
                            </Box>
                            <Box >
                                <img src={LocationIcon} />
                                <Typography>{this.state.jobDetailData?.attributes?.job_description?.location}</Typography>
                                <Tooltip title="The AI may pick only one location and which is mentioned first.">
                                    <InfoOutlined style={{ fontSize: '15px', marginLeft: '5px' }} />
                                </Tooltip>
                            </Box>
                            <Box className='iconInfo'>
                                <LocalAtmOutlined />
                                <Typography>{this.state.jobDetailData?.attributes?.job_description?.currency} {this.state.jobDetailData?.attributes?.job_description?.minimum_salary || 0}</Typography>
                                <Tooltip title="The AI may pick only minimum salary in case where salary is mentioned in range.">
                                    <InfoOutlined style={{ fontSize: '15px', marginLeft: '5px' }} />
                                </Tooltip>
                            </Box>
                        </Grid>
                        <Divider />
                        {!this.state.jobDetailData?.attributes?.jd_automatic ?
                            <>
                              <Box className={classes.description}>
                                   <Typography variant="h5">
                                        Role
                                    </Typography>
                                    <Typography variant="h6" style={{ whiteSpace: "break-spaces" }}>
                                        {this.state.jobDetailData?.attributes?.job_description?.role_title || "-"}
                                    </Typography>
                                </Box>
                                <Box className={classes.description}>
                                   <Typography variant="h5">
                                        Company Description
                                    </Typography>
                                    <Typography variant="h6" style={{ whiteSpace: "break-spaces" }}>
                                        {this.state.jobDetailData?.attributes?.job_description?.company_description || "-"}
                                    </Typography>
                                </Box>
                                <Box marginBottom='1rem'>
                                    <Typography variant="h5" style={{ marginBottom: "1em" }}>
                                        Responsibilities and Duties
                                    </Typography>
                                    <Typography variant="h6" style={{ whiteSpace: "break-spaces" }}>
                                        {this.state.jobDetailData?.attributes?.job_description?.job_responsibility || "-"}
                                    </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="h5">
                                      Skills Preferred
                                  </Typography>
                                  <ul className={classes.list}>
                                      {this.state.jobDetailData?.attributes?.job_description?.skills?.map((item: any, index: any) => {
                                          const skillName = item?.skill_name;
                                          const skillLevel = item?.skill_level.length > 0 ? item?.skill_level[0]?.level : "Intermediate";

                                          return (
                                              <li key={item?.id}>
                                                  <Typography>{`Candidate should have ${skillLevel} knowledge of ${skillName}`}</Typography>
                                              </li>
                                          );
                                      })}
                                  </ul>
                              </Box>
                                 
                                <Box marginBottom='1rem'>
                                    <Typography variant="h5">
                                        Education
                                    </Typography>
                                    <Typography variant="h6">{this.state.jobDetailData?.attributes?.job_description?.fieldOfStudy || "-"}</Typography>   
                                </Box> 

                                <Box marginBottom='1rem'>
                                    <Typography variant="h5">
                                        Location
                                    </Typography>
                                    <Typography variant="h6">{this.state.jobDetailData?.attributes?.job_description?.location}</Typography>   
                                </Box>


                                <Box marginBottom='1rem'>
                                    <Typography variant="h5">
                                        Minimum Salary
                                    </Typography>
                                    <Typography variant="h6">{this.state.jobDetailData?.attributes?.job_description?.minimum_salary}</Typography>   
                                </Box>

                            </> :
                            <Typography
                                variant='body1'
                                style={{ whiteSpace: "pre-line", marginTop: "1rem" }}
                                dangerouslySetInnerHTML={createMarkup(formatData(this.jobDtaFunction()))}
                            />
                        }
                    </Box>
                    {
                        user_role !== "client" &&
                        <Box className={classes.jobs}>
                            {/* For similar jobs */}
                            <JobList
                                heading='Similar Jobs'
                                noJobText="No similar jobs are available"
                                jobs={this.state.similarJobList}
                                handleFavorite={() => { }}
                                isAppliedForJob={this.applyForJob}
                                sendEmailToParent={this.createCoversation}
                            />
                        </Box>
                    }
                </Box >
            </>
        );
    }

    private jobDtaFunction() {
        let jobMetaData: any = "<></>";
        if (this.state.jobDetailData?.attributes?.jd_automatic) {
            jobMetaData = this.state.jobDetailData?.attributes?.job_description?.skills?.JobData?.JobMetadata?.PlainText;
            jobMetaData = jobMetaData.substring(jobMetaData.indexOf("SUMMARY"));
        }
        return jobMetaData;
    }
}
//@ts-ignore
export default withRouter(withStyles(useStyles)(JobDetails)) as any;