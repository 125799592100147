import React from "react";

import { Box, Typography, Container, Theme, withStyles } from "@material-ui/core";

import PrivacySettingsController, { Props, } from "./PrivacySettingsController";
import { createMarkup } from "../../LandingPage2/src/LandingPage2.web";


export const useStyles = (theme: Theme) => ({
    root: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: "1rem",
        },

        "& li": {
            fontSize: "1rem"
        },

        "& h5": {
            fontSize: "1.2rem",
        }
    },
    heading: {
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.5rem",
        },
    }
});

class Cookies extends PrivacySettingsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getCookiesPolicy();
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        if (!this?.state?.cookie) return null;

        return (
            <Container maxWidth="lg">
                <Box pt={8} pb={8} className={classes.root}>
                    <Box my={2}>
                        <Typography className={classes.heading} variant="h3">{this?.state?.cookie?.attributes?.title}</Typography>
                    </Box>

                    <Box my={4}>
                        <Typography
                            style={{ whiteSpace: "pre-line" }}
                            dangerouslySetInnerHTML={createMarkup(this?.state?.cookie?.attributes?.description)}
                        />
                    </Box>

                </Box>
            </Container >
        );
    }
}

// Customizable Area Start
export default withStyles(useStyles)(Cookies);
// Customizable Area End
