import React, { Component } from "react";
import { Box, Button, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import moment from "moment";

const muiStyles = (theme: Theme) => ({
    container: {
        background: "#fff",
        padding: "1rem",
        borderRadius: "1rem",
        cursor: "pointer",
        boxShadow: "0 1px 4px #0003",
        // width: '20%',

        "& img": {
            width: "3rem",
            height: "3rem",
            borderRadius: ".4rem",
        },

        "& .empty_img": {
            width: "3rem",
            height: "3rem",
        },

        "&:last-child": {
            marginBottom: "3px",
        },

        "& h5": {
            margin: "0 0 .5rem",
            fontSize: "1rem",
        },

        "& span": {
            fontSize: "0.8rem",
            color: "#96969f",
        },

        "& button": {
            padding: ".5rem 2rem",
            margin: "1rem 0",
            borderRadius: ".8rem",

            "& span": {
                color: "#fff",
                fontWeight: "bold",
                fontSize: "0.65rem",
            }
        },

        "& .title": {
            fontSize: ".9rem",
            fontWeight: "bold",
            width: "2rem",
        },

        "& .more": {
            marginTop: ".2rem",
            fontSize: ".8rem",
            color: theme.palette.primary.main
        },

        "&.selectedCandidateSt": {
            border: "4px solid #1049ff45"
        },
        "& .watched-datab-data": {
            background: "#283662",
            color: "#fff",
            padding: "1px 4px",
            borderRadius: "4px",
            height: "1.5em"
        }
    }
});

interface CDprops {
    id: number,
    name: string,
    higest_education: string,
    image: string,
    summary: string,
    prev: {
        data: string,
        more: number
    },
    skills: {
        data: string,
        more: number
    }
}

interface Props {
    classes: any;
    // candidateData: CDprops[];
    candidateData: any;
    isLoggedIn?: boolean;
    marginBottom?: number;
    showEmptyImg?: boolean;
    isSelectedCan?: boolean;
    sendSelectedCandidateData?: any;
    filterScreen?: boolean;
}
export interface CardState {
    skillsShowAll: boolean;
    prevShowAll: boolean;
    selectedCanID: any;
}

class CandidateCards extends Component<Props, CardState> {
    state: CardState = {
        skillsShowAll: false,
        prevShowAll: false,
        selectedCanID: null
    };


    handleCardClick = () => {
        const { sendSelectedCandidateData, candidateData } = this.props;
        if (sendSelectedCandidateData) {
            sendSelectedCandidateData(candidateData);
        }
        this.setState({ selectedCanID: candidateData?.temporary_user_profile?.id });
    }

    render() {
         const { classes, candidateData, isLoggedIn, marginBottom, showEmptyImg, isSelectedCan } = this.props;

        let currentPositionData;
        let previousPositions: any = [];

        candidateData?.temporary_user_profile?.work_experience?.forEach((item: any) => {
            if (item?.current) {
                currentPositionData = `${item?.position}${item.company ? " at " + item.company : ""}${item.location ? ", " + item.location + " " : ""}${candidateData?.experience ? ", " + candidateData?.experience + " of experience" : ""}`;
            } else {
                if (item?.position) {
                    previousPositions.push(`${item?.position}${item.company ? " at " + item.company : ""}`);
                }
            }
        });

        return (
            <>
            {this.props.filterScreen ? <div> {this.props.candidateData}</div> :
                <Box
                className={`${classes.container} ${isSelectedCan ? "selectedCandidateSt" : ""}`}
                mb={marginBottom}
                onClick={this.handleCardClick}>
                {isLoggedIn ?
                    <img src={candidateData?.photo_url?.url} alt="user_avatar" /> :
                    showEmptyImg && <Box className="empty_img" />
                }
                <Box display='flex' justifyContent="space-between">
                    <Typography variant="h5">
                        {candidateData?.full_name || '-'}
                    </Typography>
                    {candidateData?.watched_records &&
                        <Typography component="span" className="watched-datab-data">
                            Watched
                        </Typography>
                    }
                </Box>
                <Typography component="span">
                    {currentPositionData}
                </Typography>
                {isLoggedIn &&
                    <Button style={{ visibility: isLoggedIn ? "visible" : "hidden" }} variant="contained" color="primary">
                        Reveal Contacts
                    </Button>
                }
                <Box mb={2} mt={2}>
                    {previousPositions.length !== 0 &&
                        <Box display="flex">
                            <Box pr={2}>
                                <Typography className="title">Prev</Typography>
                            </Box>
                            <Box>
                                <Typography component="span">
                                    {previousPositions.length > 0 ? [...previousPositions].splice(0, this.state.prevShowAll ? previousPositions?.length : 4)?.join(", ") : "-"}
                                </Typography>
                                {(previousPositions.length > 0 && previousPositions.length > 4) && (
                                    <Typography variant='h6' className="more" onClick={() => this.setState((prev) => ({ prevShowAll: !prev.prevShowAll }))}>
                                        {this.state.prevShowAll ? "Show less" : `${previousPositions.length - 4} more`}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    }
                </Box>
                <Box>
                    {candidateData?.temporary_user_profile?.skills && 
                        <Box display="flex">
                            <Box pr={2}>
                                <Typography className="title">Skills</Typography>
                            </Box>
                            <Box>
                                <Typography component="span">
                                    {candidateData?.temporary_user_profile?.skills ?
                                        [...candidateData?.temporary_user_profile?.skills].splice(0, this.state.skillsShowAll ? candidateData?.temporary_user_profile?.skills?.length : 8)?.join(", ")
                                        : "-"
                                    }
                                </Typography>
                                {(candidateData?.temporary_user_profile?.skills?.length > 0 && candidateData?.temporary_user_profile?.skills?.length > 8) && (
                                    <Typography variant='h6' className="more" onClick={() => this.setState((prev) => ({ skillsShowAll: !prev.skillsShowAll }))}>
                                        {this.state.skillsShowAll ? "Show less" : `${candidateData?.temporary_user_profile?.skills?.length - 8} more`}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
}
            </>
        )

    }
}

export default withStyles(muiStyles)(CandidateCards);