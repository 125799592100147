import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Context } from "react";
// @ts-ignore
import LoaderContext, { LoaderontextType } from "../../../web/src/Context/LoaderContext.web";
import _ from "lodash";
import { v4 as uuid } from 'uuid';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match?: { params: { id: string | number } };
    location?: any;
    history?: any;
    classes?: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    currentIndex: number;
    maxItems: number;
    windowWidth: number;
    // databaseList: any;
    totalDatabaseCount: number;
    totalPages: number;
    defaultPages: number;
    selectedDBData: any;
    showDatabaseData: boolean;
    onPageLoad: boolean;
    suggesionType: string;
    locationList: any,
    titleList: any;
    titleListLoading: boolean;
    companyList: any;
    keywordsList: any;
    eSParams: any;
    loading: boolean;
    companyListLoading: boolean;
    locationListLoading: boolean;
    // Customizable Area End


    // new keys
    JobsList: any;
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class CandidateDatabaseController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    static contextType?: Context<LoaderontextType> = LoaderContext;
    getDatabaseListApiCallId: string;
    elasticSearchCallId: string;
    watchedCandidateDataCallId: string;
    getFilterSuggessionListApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            currentIndex: 0,
            maxItems: 4,
            windowWidth: window.innerWidth,
            totalDatabaseCount: 0,
            totalPages: 1, // Change it to 0 Later
            defaultPages: 1,
            selectedDBData: {},
            showDatabaseData: true,
            onPageLoad: true,
            suggesionType: "",
            locationList: null,
            titleList: null,
            titleListLoading: true,
            companyListLoading: true,
            locationListLoading: true,
            companyList: null,
            keywordsList: [],
            eSParams: {},
            loading: false,
            // Customizable Area End

            // new keys
            JobsList: [],
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    onResetAuto = (type: string) => {
        this.setState({ ...this.state, [`${type}List`]: [] });
        this.setState({ ...this.state, [`${type}ListLoading`]: true })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (apiRequestCallId != null) {
                //Get Database List API
                if (
                    apiRequestCallId === this.getDatabaseListApiCallId ||
                    apiRequestCallId === this.elasticSearchCallId
                ) {
                    this.setState({ loading: false });
                    if (responseJson !== undefined && !responseJson?.errors) {
                        // 16 Jan comment && responseJson?.data?.length > 0s
                        if (responseJson?.data) {
                            let validCandidate = responseJson?.data?.filter((item: any) => this.isDataEmpty(item.attributes));
                            var numberOFpages = Math.floor((responseJson?.total / 6) + 1)
                            this.setState({
                                JobsList: validCandidate ? [...validCandidate] : [],
                                totalDatabaseCount: responseJson?.meta?.total_record || 0,
                                totalPages: responseJson?.meta?.total_pages || 0
                            });
                            if (this.state.onPageLoad) {
                                this.setState({
                                    selectedDBData: validCandidate ? validCandidate?.[0] : {},
                                    // onPageLoad: false
                                });
                            }
                        } else {
                            this.setState({
                                showDatabaseData: false
                            });
                        }
                    } else {
                        const errors = responseJson?.errors;

                        if (errors) {
                            this.openSnackBarHandler('error', errors);
                        }
                    }
                }

                //Watched Candidate API
                else if (
                    apiRequestCallId === this.watchedCandidateDataCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                    } else {
                    }
                }
                //Filter Suggestion API
                else if (
                    apiRequestCallId === this.getFilterSuggessionListApiCallId
                ) {
                    if (responseJson !== undefined && !responseJson?.errors && !responseJson?.error) {
                        this.setState({ ...this.state, [`${this.state.suggesionType}ListLoading`]: false })
                        this.setState({ ...this.state, [`${this.state.suggesionType}List`]: responseJson?.sugg || [] })

                    } else {
                    }
                }
            }
            else {
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End

    }
    // Customizable Area Start

    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    checkWindowResize = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    //Get Database results
    getDatabaseList = (page: number): boolean => {

        const header = {
            "Content-Type": "application/json",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDatabaseListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getJobListAPiEndPoint + "?page=" + page
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // Elastic Search
    watchedCandidateData = (id: any): boolean => {
        const header = {
            "Content-Type": "application/json",

        };

        let httpBody = {
            temporary_user_database_id: id,
            ip_address: localStorage.getItem("currentIPA")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.watchedCandidateDataCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.watchedCandidateDataAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Elastic Search
    elasticSearch = (page: any): boolean => {

        // let currentIPA = localStorage.getItem("currentIPA");
        // if (!currentIPA) {
        //     currentIPA = uuid();
        //     localStorage.setItem("currentIPA", currentIPA);
        // }
        const header = {
            "Content-Type": "application/json",
        };

        // let httpBody: any = { page: page };
        let httpBody: any = { ...this.state.eSParams, page: page };

        // if (this.state.eSParams?.current == "Current") {
        //     httpBody['current'] = true;
        // } else if (this.state.eSParams?.current == "Past") {
        //     httpBody['current'] = false;
        // } else {
        //     delete httpBody['current']
        // }
        // if (this.state.eSParams?.watched == 'Show "Watched"') {
        //     httpBody['watched'] = true;
        // } else if (this.state.eSParams?.watched == 'Exclude "Watched"') {
        //     httpBody['watched'] = false;
        // } else {
        //     delete httpBody['watched'];
        // }
        // httpBody['ip_address'] = currentIPA;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.elasticSearchCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getJobListAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    // To check if the received data is empty
    isDataEmpty = (candidate: any) => {
        if (_.isEmpty(candidate.job_title)) {
            return false
        } else {
            return true
        }
    }

    //Get Filter suggesion results
    getFilterSuggessionList = (type: string, searchText: string): boolean => {
        const header = {
            "Content-Type": "application/json",
            "Token": sessionStorage.getItem("Token")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getFilterSuggessionListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getJobFilterSuggessionListAPiEndPoint + `?${type}=` + searchText
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        // this.setState({ ...this.state, [type]: searchText });
        this.setState({ suggesionType: type });

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    // Customizable Area End
}
