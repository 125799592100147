import React from "react";
import { Box, Typography, withStyles, Grid, Button, Theme, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { SearchIcon, FilterBtn } from "./assets";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AppSidebar from "../../../components/src/AppSidebar";
import { createTheme } from "@material-ui/core/styles";
import OpenRolesController, { Props } from "./OpenRolesController.web";
import { withRouter } from "react-router-dom";
import OpenRolesListViewWeb from "./OpenRolesListView.web";
import OpenRolesGridViewWeb from "./OpenRolesGridView.web";
import ShimmerLoading from "../../../components/src/ShimmerLoading";
import ConfirmToCloseRole from "./ConfirmToCloseRole.web";
import RolesLoading from "../../../components/src/RolesLoading";

const OpenrolesStyles = (theme: Theme) => ({
  root: {
    display: "flex",
    backgroundColor: "grey",
  },
  textWhite: {
    color: "#ffffff",
  },

  drawerContainer: {
    overflow: "auto",
  },
  listIconeWidth: {
    minWidth: "47",
    color: "White",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3), // 3 * 8 = 24px 
  },
  mobileContent: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  BgHighlightBottom: {
    width: "100%",
    backgroundColor: "#24325f",
    height: "15px",
  },
  BgCardHeader: {
    backgroundColor: "#a8d0e7",
    color: '#24325F',

    "& h6": {
      fontWeight: 600
    }
  },
  BtnCreateNewRole: {
    backgroundColor: "#24325f",
    color: "white",
  },
  BgHighlightInterviewsBottom: {
    backgroundColor: "#e19954",
    height: "15",
    width: "100%",
  },
  InterveiwTextColor: {
    color: "#e19954",
  },
  CandidatesTextColor: {
    color: "#5f72b9",
  },
  BgHighlightcandidatesBottom: {
    backgroundColor: "#5f72b9",
    height: "15",
    width: "100%",
  },
  BgHighlightClosedRolesBottom: {
    backgroundColor: "#ff4e6d",
    height: "15",
    widht: "100%",
  },
  ClosedRolesTextColor: {
    color: "#ff4e6d",
  },
  inputTextField: {
    "& .MuiFilledInput-root": {
      background: "rgb(255, 255, 255)",
      color: "#000",
      padding: 0,
    },
    "& .MuiFilledInput-input": {
      padding: 0
    },
  },
  SelectFilterWidth: {
    backgroundColor: '#fff',
    padding: ".4rem 0.3rem .4rem 1rem",
    alignItems: "center",
    borderRadius: "1rem",
    "& .MuiSelect-select": {
      paddingRight: 35
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#fff"
    },

    "& svg": {
      borderLeft: "2px solid #F1F1F5",
    },
  },
  filter: {
    "& .MuiInput-underline::before": {
      border: 0
    },
    "& .MuiInput-underline::after": {
      border: 0
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: 0
    }
  },
  topAppBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#ffff",
  },
  ContentTopMargin: {
    marginTop: "4.4rem",
    width: "100%",
  },
  ContentTopMarginMobile: {
    marginTop: "3rem",
    width: "100%",
  },
  search: {
    backgroundColor: '#fff',
    padding: ".6rem .8rem",
    alignItems: "center",
    borderRadius: ".8rem",
    "& img": {
      marginRight: "1rem"
    }
  },

  fallBackText: {
    padding: ".5rem"
  },

  emptyContainer: {
    backgroundColor: "#fff",
    borderRadius: "1rem",

    "& h6": {
      fontWeight: 600,
      padding: "1rem 2rem",
    },

    "& h5": {
      padding: "2rem 3rem",
    }
  },
  details: {
    background: "#fff",
    margin: "1rem 0 2.5rem",
    padding: "2rem 1.5rem",
    borderRadius: ".5rem",

    "& > div": {
      "& h6": {
        marginBottom: ".7rem"
      },

      "&:last-child": {
        marginRight: 0,
        marginLeft: "auto",
        alignItems: "flex-end",

        "& button": {
          justifyContent: "end",
          padding: 0,
          minWidth: "fit-content",
          margin: "0 1em 1em 0",

          "&:first-child": {
            color: theme.palette.primary.main,
          },
          "&:last-child": {
            color: theme.palette.error.main
          },
          "& span": {
            fontSize: "0.875rem",
          }
        }
      }
    }
  },
  wrapActionSection: {
    "& > div": {
      marginLeft: "1em",
      marginBottom: "1em"
    },
    "& search": {
      width: "40%"
    }
  },
  formobileCardContainer: {
    justifyContent: "center"
  },
  formobileCardItem: {
    width: "100%",
  },
  roleTableStyle: {
    width: "100%",
    "& > div": {
      "&::-webkit-scrollbar": {
        height: "0.2em",
        background: "#e2e2ea",
        borderRadius: "2.5px"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#24325f8c",
        borderRadius: "2.5px",
      }
    }
  },
  roleNameAlign: {
    wordBreak: "break-all"
  },
  redDeleteBtn: {
    color: "#f44336"
  }
});
const theme = createTheme({
  typography: {
    fontFamily: "Poppins-Regular",
    h1: {
      fontSize: 24,
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
      fontSize: 16,
    },
    h3: {
      fontWeight: 400,
      fontSize: 14,
    },
  },
});

class OpenRoles extends OpenRolesController {
  constructor(props: Props) {
    super(props);
  }

  // SelectChangeEvent is not available in mui verison 4, so i used any.
  handleViewChange = (event: any) => {
    this.props.history.push({
      search: `?view=${event.target.value}&filter=${this.state.sort}${this.clientIDLink ? "&" + this.clientIDLink : ""}`
    });

    if (event.target.value === "list")
      this.setState({ view: "list" });
    else
      this.setState({ view: "grid" });
  };

  componentDidMount(): Promise<void> {
    this.startLoading();
    window.scrollTo(0, 0);
    window.addEventListener('resize', this.checkWindowResize);
    let clientID = +this.props.location?.search?.split("clientId=")[1];
    this.setState({ userType: sessionStorage.getItem("user_role") || "", clientID: clientID }, () => {
      if (this.state.userType == 'admin') {
        if (clientID) {
          this.checkURLParamsAndId();
        } else {
          this.props.history.push(`/clients-list`);
        }
      } else if (this.state.userType == 'client') {
        this.checkURLParamsAndId();
      }
      this.clientIDLink = this.state.userType == "admin" && this.state.clientID ? "clientId=" + this.state.clientID : "";
    });
    return super.componentDidMount();
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.location?.pathname != prevProps.location?.pathname) {
      window.scrollTo(0, 0);
      window.addEventListener('resize', this.checkWindowResize);
      this.checkURLParamsAndId();
    }
  }
  async componentWillUnmount() {
    window.removeEventListener('resize', this.checkWindowResize);
  }

  // To open confirmation pop up based on role availibility for candiadte while accepting/rejecting a role
  handleCandidateStatus = (profileData: any, position: string | number, accepted_candidate: string | number) => {
    this.setState({ profileDataToConfirm: profileData }, () => {
      let openPosition = +position || 0;
      let acceptedPosition = +accepted_candidate || 0;
      if (profileData?.isAccepted) {
        if (acceptedPosition + 1 === openPosition) {
          this.handleCloseConfirmToCloseRole("toCloseRole");
        } else if (acceptedPosition >= openPosition) {
          this.handleCloseConfirmToCloseRole("cantAccept");
        } else {
          this.handleAcceptRejectRole(profileData);
        }
      } else {
        this.handleAcceptRejectRole(profileData);
      }
    })
  }

  searchByName = (keyword: string) => {
    const filterRoles: any = [];

    this.state.rolesData.data.map((role: any) => {
      role.attributes.candidate.map((candidate: any) => {
        if (candidate.name.toLowerCase().includes(keyword.toLowerCase())) {
          const _role = filterRoles.filter(
            (filter_role: any) => filter_role.id === role.id
          );
          if (_role.length) {
            _role[0].attributes.candidate.push(candidate);
          } else {
            filterRoles.push({ ...role, attributes: { ...role.attributes, candidate: [candidate] } });
          }
        }
      })
    });

    this.setState({ rolesData: { data: filterRoles } });
  }

  searchWhenExpand = (keyword: string) => {
    if (keyword.trim() === "") {
      this.setState({ rolesData: this.state.expandRole });
      return;
    }

    const filterData: any = { data: [] };
    this.state.expandRole.data[0].attributes.candidate.map((candidate: any) => {
      if (candidate.name.toLowerCase().includes(keyword.toLowerCase())) {
        if (filterData.data.length) {
          filterData.data[0].attributes.candidate.push(candidate);
        } else
          filterData.data.push({
            ...this.state.expandRole.data[0],
            attributes: { ...this.state.expandRole.data[0].attributes, candidate: [candidate] }
          });
      }
    });
    this.setState({ rolesData: filterData });
  }

  // To handle "View More" and "View Less"
  handleViewAll = (id: number | undefined) => {
    this.setState({ viewAll: { ...this.state.viewAll, role_id: id } }
      // ,() => this.setRoleParameters()
    );
    this.setState({ searchText: "" });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Box display="flex">
          <AppSidebar />
          <Box color="primary" className={`${this.state.windowWidth > 1000 ? classes.ContentTopMargin : classes.ContentTopMarginMobile}`}>
            <main className={`${this.state.windowWidth > 959 ? classes.content : classes.mobileContent}`}>
              {/* Dashboard User Details start */}
              <Box mb={3}>
                <Grid container>
                  <Grid item xl={5} lg={2} md={3} sm={12} xs={12}>
                    <Box mb={3}>
                      <Box mb={1}>
                        <Typography variant="h5">Open Roles</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={7} lg={10} md={9} sm={12} xs={12}>
                    <Box
                      display={this.state.windowWidth > 959 ? "flex" : "block"}
                      justifyContent={this.state.windowWidth > 1260 ? "flex-end" : "flex-start"}
                      flexWrap="wrap"
                      alignItems="center"
                      className={classes.wrapActionSection}
                    >
                      <div className={classes.margin}>
                        <Grid container className={classes.search}>
                          <Grid item>
                            <img src={SearchIcon} width="18px" />
                          </Grid>
                          <TextField
                            variant="filled"
                            placeholder="Search here"
                            className={classes.inputTextField}
                            InputProps={{ disableUnderline: true }}
                            value={this.state.searchText}
                            onChange={(event) => {
                              this.setState({ searchText: event.target.value });
                              this.searchRoles(event.target.value.toLowerCase());
                            }}
                          />
                        </Grid>
                      </div>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexWrap="wrap"
                        alignItems="center">
                        <Box
                          className={classes.filter}
                          width={this.state.windowWidth < 960 ?
                            (this.state.windowWidth < 765 ?
                              (this.state.windowWidth < 420 ? "41%" : "43%")
                              : "45%")
                            : "unset"}>
                          <Select
                            labelId="product-subcategory"
                            className={classes.SelectFilterWidth}
                            id="demo-simple-select"
                            displayEmpty
                            onChange={this.handleViewChange}
                            value={this.state.view}
                            style={{ width: `${this.state.windowWidth < 960 ? "100%" : "unset"}` }}
                          >
                            <MenuItem value="grid">
                              View: <span style={{ color: "#000", fontWeight: 600 }}>Grid</span>
                            </MenuItem>
                            <MenuItem value="list" >
                              View: <span style={{ color: "#000", fontWeight: 600 }}>List</span>
                            </MenuItem>
                          </Select>
                        </Box>
                        <Box className={classes.filter}
                          style={{ margin: "0 1em" }}
                          width={this.state.windowWidth < 960 ?
                            (this.state.windowWidth < 765 ?
                              (this.state.windowWidth < 420 ? "41%" : "43%")
                              : "45%")
                            : "unset"}
                        >
                          <Select
                            className={classes.SelectFilterWidth}
                            labelId="product-subcategory"
                            id="demo-simple-select"
                            displayEmpty
                            value={this.state.sort}
                            onChange={this.filterRoles}
                            style={{ width: `${this.state.windowWidth < 960 ? "100%" : "unset"}` }}
                          >
                            <MenuItem value="default">Sort By: <span style={{ color: "#000", fontWeight: 600 }}>Default</span></MenuItem>
                            <MenuItem value="asc">Sort By: <span style={{ color: "#000", fontWeight: 600 }}>A to Z</span></MenuItem>
                          </Select>
                        </Box>
                        <Box className={classes.filterIcon}>
                          <img src={FilterBtn} width="20px" style={{ background: "#fff", padding: ".9rem .6rem", borderRadius: "1rem" }} />
                        </Box></Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {this.state.viewAll.isActive && this.state.rolesData && (
                <Grid container className={classes.details}>
                  <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
                    <Box display='flex' flexWrap="wrap">
                      <Box mr="3em" mb="1em">
                        <Typography variant="h6" className={classes.roleNameAlign}>Role Name</Typography>
                        <Typography
                          variant="h5"
                          style={{ textTransform: "capitalize" }}
                        >
                          {this.state.rolesData?.length > 0 ? this.state.rolesData[0]?.attributes?.role_name : <ShimmerLoading width="4rem" />}
                        </Typography>
                      </Box>
                      <Box mr="3em" mb="1em">
                        <Typography variant="h6">Open Positions </Typography>
                        <Typography
                          variant="h5"
                          style={{ textTransform: "capitalize" }}
                        >
                          {this.state.rolesData?.length > 0 ? this.state.rolesData[0]?.attributes?.position : <ShimmerLoading width="3rem" />}
                        </Typography>
                      </Box>
                      <Box mr="3em" mb="1em">
                        <Typography variant="h6">Accepted Candidates</Typography>
                        <Typography variant="h5">
                          {this.state.rolesData?.length > 0 ? this.state.rolesData[0]?.attributes?.accepted_candidate : <ShimmerLoading width="3rem" />}
                        </Typography>
                      </Box>
                      <Box mr="3em" mb="1em">
                        <Typography variant="h6">Shortlisted Candidates</Typography>
                        <Typography variant="h5">
                          {this.state.rolesData?.length > 0 ? this.state.rolesData[0]?.attributes?.total_shortlisted_candidate : <ShimmerLoading width="3rem" />}
                        </Typography>
                      </Box>
                      <Box mr="3em" mb="1em">
                        <Typography variant="h6">Candidates Applied </Typography>
                        <Typography variant="h5">
                          {this.state.rolesData?.length > 0 ? this.state.rolesData[0]?.attributes?.total_applied_candidate : <ShimmerLoading width="3rem" />}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xl={2} lg={2} md={12} sm={12} xs={12}>
                    <Box display='flex' flexDirection={`${this.state.windowWidth > 1279 ? "column" : "row"}`} flexWrap="wrap">
                      <Button onClick={() => this.props.history.push(`/editRole/${this.state.rolesData[0]?.id}${this.clientIDLink ? "?" + this.clientIDLink : ""}`)}>Edit Role</Button>
                      <Button className={classes.redDeleteBtn} onClick={() => this.handleCloseConfirmToDeleteRole('toDeleteJobRole')}>Delete Role</Button>
                      <Button onClick={() => this.handleCloseConfirmToDeleteRole('toCloseTheJobRole')}>Close Role</Button>
                    </Box>
                  </Grid>
                </Grid>
              )}

              {/* Conditionally Render List & Grid View Of open roles */}
              {
                this.state.view === "list" &&
                <OpenRolesListViewWeb {...this.props}
                  jobRoles={this.state.rolesData}
                  handleSendStatus={this.handleCandidateStatus}
                  handleViewAll={this.handleViewAll}
                  isExpand={this.state.viewAll.isActive}
                  roleListScroll={this.handleListInfiniteScroll}
                  hasMoreRoleData={this.state.hasMoreRole}
                  infiniteRoleLoading={this.state.infiniteRoleLoading}
                  hasMoreCandidateData={this.state.hasMoreCandidate}
                  infiniteCandidateLoading={this.state.infiniteCandidateLoading}
                  candidateListScroll={this.handleListInfiniteScroll}
                  startLoading={this.startLoading}
                  createCoversation={this.createCoversation}
                  clientIDLink={this.clientIDLink}
                />}

              {
                this.state.view === "grid" &&
                <OpenRolesGridViewWeb {...this.props}
                  jobRoles={this.state.rolesData}
                  handleSendStatus={this.handleCandidateStatus}
                  handleViewAll={this.handleViewAll}
                  isExpand={this.state.viewAll.isActive}
                  roleListScroll={this.handleListInfiniteScroll}
                  hasMoreRoleData={this.state.hasMoreRole}
                  infiniteRoleLoading={this.state.infiniteRoleLoading}
                  hasMoreCandidateData={this.state.hasMoreCandidate}
                  infiniteCandidateLoading={this.state.infiniteCandidateLoading}
                  candidateListScroll={this.handleListInfiniteScroll}
                  startLoading={this.startLoading}
                  createCoversation={this.createCoversation}
                  windowWidth={this.state.windowWidth}
                  clientIDLink={this.clientIDLink}
                />}

              {
                this.state.rolesData?.length === 0 &&
                !this.state.viewAll.isActive && !this.state.isLoading &&
                <Grid container className={classes.emptyContainer}>
                  <Typography variant="h5">No Roles Found</Typography>
                </Grid>
              }
              {
                this.state.rolesData?.length === 0 &&
                this.state.viewAll.isActive && !this.state.isLoading &&
                <Grid container className={classes.emptyContainer}>
                  <Typography variant="h6">No Candidate Found.</Typography>
                </Grid>
              }

              {/* Loader untill the data is loaded */}
              {this.state.isLoading && (
                <RolesLoading count={4} />
              )}
            </main>
          </Box >
        </Box >

        {/* Snackbar for display success and failed messages. */}
        {this.state.snackBar.show && (
          <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
        )}

        {/* Close Job Role */}
        <ConfirmToCloseRole
          showConfirmToCloseRole={this.state.showConfirmToCloseRoleModal}
          onCloseshowConfirmToCloseRole={this.handleCloseConfirmToCloseRole}
          confirmType={this.state.confirmType || ""}
          profileData={this.state.profileDataToConfirm}
          callAcceptRole={this.handleToCloseRoleOrNot} />

        {/* Delete Job Role */}
        <ConfirmToCloseRole
          showConfirmToCloseRole={this.state.showConfirmToDeleteRoleModal}
          onCloseshowConfirmToCloseRole={this.handleCloseConfirmToDeleteRole}
          confirmType={this.state.confirmType || ""}
          roleID={this.props.match?.params?.id}
          callDeleteJobRole={(id) => this.state.confirmType == 'toDeleteJobRole' ? this.handleToDeleteJobRole(id) : this.closeJobRole(id)} />
      </>
    );
  }
}

// Customizable Area Start
// Customizable Area End
// @ts-ignore
export default withStyles(OpenrolesStyles)(withRouter(OpenRoles));
