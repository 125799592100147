import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { SearchIcon, FilterBtn } from './assets';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Box, Grid, MenuItem, Select, Typography, TextField } from '@material-ui/core';

interface Column {
  id: 'id' | 'candidate_name' | 'role' | 'initial_score';
  label: any;
  minWidth?: number;
  align?: any;
  format?: (value: number) => string;

}
const columns: Column[] = [
  { id: 'id', label: 'No.', minWidth: 30 },
  { id: 'candidate_name', label: 'Candidate Name', minWidth: 100 },
  {
    id: 'role',
    label: 'Role',
    minWidth: 170,
    align: 'left',

  },
  {
    id: 'initial_score',
    label: 'Initial Score',
    minWidth: 170,
    align: 'right',
  },

];

interface Data {
  id: string;
  candidate_name: any;
  role: any;
  initial_score: any;

}
function createData(id: string, candidate_name: any, role: any, initial_score: number): Data {
  return { id, candidate_name, role, initial_score };
}
const rows = [
  createData('1', 'Daniel Ricciardo', 'IOS DEVELOPER', 3287263),
  createData('2', 'Daniel Ricciardo', 'IOS DEVELOPER', 9596961),
  createData('3', 'Daniel Ricciardo', 'IOS DEVELOPER', 301340),
  createData('4', 'Daniel Ricciardo', 'IOS DEVELOPER', 9833520),
  createData('5', 'Daniel Ricciardo', 'IOS DEVELOPER', 9984670),
  createData('6', 'Daniel Ricciardo', 'IOS DEVELOPER', 7692024),
  createData('7', 'Daniel Ricciardo', 'IOS DEVELOPER', 357578),
  createData('8', 'Daniel Ricciardo', 'IOS DEVELOPER', 70273),
  createData('9', 'Daniel Ricciardo', 'IOS DEVELOPER', 1972550),
  createData('10', 'Daniel Ricciardo', 'IOS DEVELOPER', 377973),
  createData('11', 'Daniel Ricciardo', 'IOS DEVELOPER', 640679),
  createData('12', 'Daniel Ricciardo', 'IOS DEVELOPER', 242495),
  createData('13', 'Daniel Ricciardo', 'IOS DEVELOPER', 17098246),
  createData('14', 'Daniel Ricciardo', 'IOS DEVELOPER', 923768),
  createData('15', 'Daniel Ricciardo', 'IOS DEVELOPER', 8515767),
];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: "auto",
  },
  head: {
    backgroundColor: "#dcecf5",
  },
  headings: {
    "& th:first-child": {
      borderRadius: "50px 0 0 50px",
    },
    "& th:last-child": {
      borderRadius: "0 50px 50px 0",
    }
  },
  inputTextField: {
    fontSize: "10px"
  },
  SelectFilterWidth: {
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "50px"
    },
  }
});


export default function LeadManagement() {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box m={5}>
      <Box>
        <Grid container >
          <Grid item lg={5} sm={12}>
            <Box mb={3}>
              <Box mb={1}><Typography variant="h5">Candidates Scoring</Typography></Box>
            </Box>
          </Grid>
          <Grid item lg={7} sm={6}>
            <Box display="flex" justifyContent="space-evenly" flexWrap="wrap" alignItems="center" alignContent="center">
              <Box>
                <div >
                  <Grid container spacing={1} alignItems="center" >
                    <Grid item>
                      <img src={SearchIcon} width="20px" />
                    </Grid>
                    <Grid item>
                      <TextField color="secondary" variant="outlined" id="input-with-icon-grid" placeholder="Search here" size="small" className={classes.inputTextField} />
                    </Grid>
                  </Grid>
                </div>
              </Box>

              <Box>
                <Select
                  className={classes.SelectFilterWidth}
                  labelId="product-subcategory"
                  id="demo-simple-select"
                  displayEmpty>
                  <MenuItem >Sort By: Default</MenuItem>
                  <MenuItem value="4">Sort By: A to Z</MenuItem>
                </Select>
              </Box>
              <Box>
                <img src={FilterBtn} width="20px" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box >
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table aria-label="sticky table" >
              <TableHead className={classes.head}>
                <TableRow className={classes.headings}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.candidate_name}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Box>
  );
}
