// Customizable Area Start
import { Box, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import JobsController, { Props } from './JobsController.web';
import CatalogueStyles from "./Styles.module.scss";
import JobFilter from './JobFilter.web';
import CandidateCard from "../../JobListing2/src/common/CandidateCard.web";
import JobsCard from "../../JobListing2/src/common/JobsCard.web";
import DetailCandidateParent from './DetailCandidateParent.web';
import Pagination from './Pagination.web';
import Carousel from 'react-elastic-carousel';
import { ArrowLeftSharp, ArrowRightSharp } from '@material-ui/icons';
import DetailJobCard from './DetailJobCard.web';
import { ArrowBackSharp } from '@material-ui/icons';

function WarningButton() {
    // return React.createElement(CustomButton, {color: 'red'}, null);
    return <>Next</>;
}
const breakPoints = [
    { width: 600, itemsToShow: 1 },
    { width: 900, itemsToShow: 2 },
    { width: 1000, itemsToShow: 3 },
];
interface ArrowType {
    type: any;
    onClick: any; isEdge: any;
}

const carouselStyle = (theme: any) => ({
    carousel: {
        "& button": {
            backgroundColor: '#FFF',
        },

        "& .rec-arrow": {
            width: "2em",
            height: "2em",
            minWidth: "2em",
            lineHeight: "1em",
            fontSize: "0.8em",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
    },
});

class Jobs extends JobsController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): Promise<void> {
        window.addEventListener('resize', this.checkWindowResize)
        window.scrollTo(0, 0);
        this.setState({ loading: true }, () => this.elasticSearch(0));

        return super.componentDidMount();
    }

    async componentWillUnmount() {
        window.removeEventListener('resize', this.checkWindowResize);
    }

    render() {
        const { classes } = this.props;


        return (
            <Box className={CatalogueStyles.continerWrapper}>
                <Grid container spacing={1} >
                    <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className={sessionStorage.getItem('user_role') === 'candidate' ? CatalogueStyles.containerItemsSecond : CatalogueStyles.containerItems}>
                        {sessionStorage.getItem('user_role') === 'candidate' && <Box style={{ marginBottom: '1.2em' }} display={'flex'} alignItems={'center'} >
                            <ArrowBackSharp /> <Typography onClick={() => window.location.replace("/profile-setting")} style={{ fontSize: '0.85rem', textDecoration: 'underline', paddingLeft: '5px', cursor: 'pointer', fontWeight: 700, color: "#283662" }} component="span">Go Back To Home</Typography>
                        </Box>}
                        {/* Database filter component */}
                        <JobFilter onSearch={(state) => {
                            let allPrams: any = {}
                            if (state.employment_type) {
                                allPrams['employment_type'] = state.employment_type;
                            }
                            if (state.location) {
                                if (state.location_boolean_search) {
                                    if (state.location.endsWith('OR') || state.location.endsWith('or')) {
                                        let str = state.location.trim();
                                        let lastInd = str.lastIndexOf(' ');
                                        allPrams['location'] = state.location.substring(0, lastInd);
                                    }
                                    else allPrams['location'] = state.location;
                                }
                                else allPrams['location'] = state.location;
                            }
                            if (state.title) {
                                if (state.title_boolean_search) {
                                    if (state.title.endsWith('AND') || state.title.endsWith('OR') || state.title.endsWith('NOT') ||
                                        state.title.endsWith('and') || state.title.endsWith('or') || state.title.endsWith('not')) {
                                        let str = state.title.trim();
                                        let lastInd = str.lastIndexOf(' ');
                                        allPrams['title'] = state.title.substring(0, lastInd);
                                    }
                                    else allPrams['title'] = state.title;
                                }
                                else allPrams['title'] = state.title;
                            }
                            if (state.company) {
                                allPrams['company'] = state.company;
                            }
                            if (state.keyword_OR_name) {
                                allPrams['keywords'] = state.keyword_OR_name;
                            }
                            if (state.startDateRange) {
                                allPrams['start_date_published'] = state.startDateRange;
                            }
                            if (state.endDateRange) {
                                allPrams['end_date_published'] = state.endDateRange;
                            }
                            // if (state.title_boolean_search) {
                            //     if (state.showBooleanTitle) {
                            //         allPrams['title'] = state.showBooleanTitle;
                            //     }
                            // }
                            // else {
                            //     if (state.title) {
                            //         allPrams['title'] = state.title;
                            //     }
                            // }
                            this.setState({
                                loading: true,
                                onPageLoad: true,
                                eSParams: {
                                    ...allPrams
                                }
                            }, () => this.elasticSearch(0));
                        }}
                            locationList={this.state.locationList}
                            companyList={this.state.companyList}
                            titleList={this.state.titleList}
                            titleListLoadin={this.state.titleListLoading}
                            companyListLoadin={this.state.companyListLoading}
                            locationListLoadin={this.state.locationListLoading}
                            keywordsList={this.state.keywordsList}
                            onResetAuto={this.onResetAuto}
                            onSuggessionSearch={this.getFilterSuggessionList}
                            onReset={() => {
                                this.setState({
                                    loading: true,
                                    onPageLoad: true,
                                    eSParams: {}
                                }, () => this.elasticSearch(0))
                            }}
                        />
                    </Grid>
                    {!this.state.loading ?
                        this.state.JobsList && this.state.JobsList.length > 0 ? <>
                            <Grid item xl={4} lg={4} md={3} sm={12} xs={12} className={CatalogueStyles.containerItemsSecond} style={this.state.totalPages === 1 ? { marginTop: "1.7em" } : { marginTop: "0.5em" }}>
                                {/* Candidate card Pagination handler */}
                                <Pagination totalPages={this.state.totalPages} onPageNumSelect={(page: any) => this.elasticSearch(page - 1)} />
                                {/* Candidate cards for screen size >= 960 */}
                                {this.state.windowWidth >= 960 ?
                                    <Box className={CatalogueStyles.candidatecardListWrap} >
                                        {this.state.JobsList?.map((candidate: any, index: any) => {
                                            return (
                                                <JobsCard
                                                    key={index}
                                                    candidateData={candidate}
                                                    isLoggedIn={false}
                                                    marginBottom={2}
                                                    showEmptyImg={false}
                                                    isSelectedCan={candidate?.id === this.state.selectedDBData?.id}
                                                    sendSelectedCandidateData={(data) => {
                                                        window.scroll(0, 0);
                                                        let currentList = [...this.state.JobsList];
                                                        let newList = currentList.map(item => candidate?.id == item?.id ? { ...item, attributes: { ...item.attributes } } : { ...item });
                                                        this.setState({ selectedDBData: data, JobsList: [...newList] });
                                                    }} />
                                            );
                                        })}
                                    </Box>
                                    :
                                    (<Carousel isRTL={false} breakPoints={breakPoints} className={classes.carousel}>
                                        {/* Candidate cards in Catousel /Slider for screen size < 960 */}
                                        {
                                            this.state.JobsList?.map((candidate: any, index: any) => {
                                                return (
                                                    <JobsCard
                                                        key={index}
                                                        candidateData={candidate}
                                                        isLoggedIn={false}
                                                        marginBottom={2}
                                                        showEmptyImg={false}
                                                        sendSelectedCandidateData={(data) => {
                                                            let currentList = [...this.state.JobsList];
                                                            let newList = currentList.map(item => candidate?.id == item?.id ? { ...item, attributes: { ...item.attributes, watched_records: true } } : { ...item });
                                                            this.setState({ selectedDBData: data, JobsList: [...newList] });
                                                            this.watchedCandidateData(candidate?.id);
                                                        }} />
                                                );
                                            })
                                        }
                                    </Carousel>)
                                }

                            </Grid>
                            <Grid item xl={5} lg={5} md={6} sm={12} xs={12} className={CatalogueStyles.containerItemsSecond}>
                                <Box className={CatalogueStyles.count}>
                                    <Typography component="span">
                                        Total Jobs ~ {this.state.totalDatabaseCount}
                                    </Typography>
                                </Box>
                                {this.state.selectedDBData ? <DetailJobCard candidateData={this.state.selectedDBData} /> : ""}
                            </Grid></>
                            : <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                                <Box className={CatalogueStyles.noDataFound}>
                                    <Typography variant='h5'>No Data Found</Typography>
                                </Box>
                            </Grid> :
                        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                            <Box className={CatalogueStyles.noDataFound} height='100%'>
                                <Box position='relative'>
                                    <CircularProgress
                                        size={24}
                                        style={{
                                            color: "#24325f",
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </Box>
        );
    }
}

export default withStyles(carouselStyle)(Jobs);
// Customizable Area End