// Customizable Area Start
import React, { Component } from "react";
import { Box, Checkbox, Chip, Divider, FormControl, FormControlLabel, Grid, InputAdornment, InputBase, MenuItem, Select, TextField, Theme, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { SearchIcon } from "../../EmailNotifications/src/assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PersonIcon from '@material-ui/icons/Person';
import { JobIcon } from "../../JobListing2/src/assets";
import CatalogueStyles from "./Styles.module.scss";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { ReactComponent as WorkOutlineOutlined } from "../../JobListing2/assets/job_type.svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { title } from "process";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import '../src/style.css';
import moment from "moment";

// Customizable Area End

// Customizable Area Start
const dummyKms = [
    { value: 15, label: "15km" },
    { value: 40, label: "40km" },
    { value: 55, label: "55km" },
    { value: 80, label: "80km" },
    { value: 120, label: "120km" },
    { value: 160, label: "160km" },
]

const dummyStatus = ['Permanent'];
const dummyCP = ['Current and Past', 'Current', 'Past'];
// Customizable Area End

// Customizable Area Start
export interface TPState {
    location: string;
    titleTest: string;
    title: string;
    titleLoc: string;
    keyword_OR_name: string;
    employment_type: string | 'Full Time' | 'Part Time';
    title_boolean_search: boolean;
    location_boolean_search: boolean;
    company: string;
    showText: string;
    showTextLoc: string;
    showkeyword_OR_nameText: string;
    showLocation: any;
    showTitle: any;
    showBooleanTitle: any;
    showBooleanLoc: any;
    showNonBooleanTitle: any;
    showCompany: any;
    orOption: boolean;
    orOptionComp: boolean;
    orOptionLoc: boolean;
    endDateRange: any;
    startDateRange: any;
    dateVal: any;
    locationTest: any;
    companyTest: any;
}

interface Props {
    // classes: any;
    onSearch: (state: any) => void;
    showSaveSearch?: boolean;
    locationList?: any[];
    companyList?: any[];
    titleList?: any[];
    keywordsList?: any[];
    onSuggessionSearch: (type: string, searchText: string) => void;
    onResetAuto: (type: string) => void;
    onReset: () => void;
    titleListLoadin?: boolean;
    companyListLoadin?: boolean;
    locationListLoadin?: boolean;

}
// Customizable Area End
class JobFilter extends Component<Props, TPState> {
    // Customizable Area Start
    initialState: TPState = {
        location: '',
        titleTest: '',
        locationTest: '',
        companyTest: "",
        title: "",
        titleLoc: "",
        keyword_OR_name: "",
        endDateRange: '',
        startDateRange: '',
        employment_type: "",
        title_boolean_search: false,
        location_boolean_search: true,
        company: "",
        showText: "",
        showTextLoc: "",
        showkeyword_OR_nameText: "",
        showLocation: [],
        showTitle: [],
        showBooleanTitle: [],
        showBooleanLoc: [],
        showNonBooleanTitle: [],
        showCompany: [],
        orOption: true,
        orOptionComp: true,
        orOptionLoc: true,
        dateVal: "",

    };

    state: TPState = { ...this.initialState };
    // Customizable Area End
    // Customizable Area Start
    onSearch = () => {
        this.props.onSearch(this.state);
    }
    onReset = () => {
        this.setState({ ...this.initialState });
        this.props.onReset();
        this.setState({ dateVal: "" })
    }

    apply = (event: any, picker: any) => {
        picker.element.val(
            picker.startDate.format('MM/DD/YYYY') +
            ' - ' +
            picker.endDate.format('MM/DD/YYYY')
        );
        this.setState({
            endDateRange: moment(new Date(picker.endDate._d)).format('YYYY-MM-DD HH:mm:ss UTC'),
            startDateRange: moment(new Date(picker.startDate._d)).format('YYYY-MM-DD HH:mm:ss UTC'),
            dateVal: moment(new Date(picker.startDate._d)).format('MM/DD/YYYY') + " - " + moment(new Date(picker.endDate._d)).format('MM/DD/YYYY'),
        });

        this.props.onSearch(this.state);
    }

    handleCancel = (event: any, picker: any) => {
        picker.element.val('');
        this.setState({
            endDateRange: '',
            startDateRange: '',
            dateVal: ""
        })
        this.props.onSearch(this.state);
    }



    // Customizable Area End
    render() {
        // Customizable Area Start
        let timeoutVar: any;

        return (
            <Box className={`${CatalogueStyles.card} ${CatalogueStyles.filterWrap}`}>
                <Box className={CatalogueStyles.filterCard}>
                    <Box flex={1} className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Title</Typography>
                        {this.state.title_boolean_search ?
                            <Box display="flex" position='relative' alignItems='center' >
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <JobIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showText }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHide}
                                        multiline
                                        placeholder="Enter title"
                                        onChange={(event) => {
                                            let receivedText: any = event.target.value;
                                            let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item: any) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'> ${item} </span>`
                                                } else return item
                                            });
                                            this.setState({ showText: forT?.join(" ") || "", showBooleanTitle: receivedText, title: receivedText });
                                        }}
                                        onBlur={() => { this.props.onSearch(this.state); }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.showBooleanTitle}
                                    />
                                </Box>
                            </Box> :
                            <Box display="flex" width='100%' justifyContent='center' alignItems='center' className={CatalogueStyles.titleInput}>
                                <Box className={CatalogueStyles.filterIcon}>
                                    <JobIcon /></Box>
                                <Autocomplete
                                    className={CatalogueStyles.search2}
                                    classes={{ root: CatalogueStyles.inputRoot }}
                                    multiple
                                    disableClearable
                                    noOptionsText={this.state.titleTest.length < 2 ? 'Please enter atleast 2 characters' : "No Data Found"}
                                    onFocus={() => {
                                        this.setState({ orOption: true });
                                        //   this.props.onSuggessionSearch("title", "");
                                    }}
                                    onBlur={() => { this.setState({ orOption: false }) }}
                                    loading={this.state.titleTest.length < 2 ? false : this.props.titleListLoadin}
                                    options={this.state.titleTest.length < 2 ? [] : this.props.titleList?.map((option: any) => option) || []}
                                    onInputChange={(event, newInputValue) => {
                                        this.setState({ titleTest: newInputValue })
                                        if (newInputValue.length > 1) {
                                            this.props.onSuggessionSearch("title", newInputValue);
                                        } else {
                                            this.props.onResetAuto('title');
                                        }
                                    }}
                                    onChange={(event, value) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        this.setState({ title: value?.join(" OR "), showTitle: [...value], showNonBooleanTitle: value?.join(" OR "), });
                                        clearTimeout(timeoutVar);
                                        timeoutVar = setTimeout(() => {
                                            this.props.onSearch(this.state);
                                        }, 700)
                                    }}
                                    renderTags={(value, getTagProps) => {
                                        if (this.state.orOption) {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                        else {
                                            return value?.map((option: string, index: number) => (
                                                <>
                                                    <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                    {index < value.length - 1 &&
                                                        <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                </>
                                            ))
                                        }
                                    }
                                    }
                                    renderInput={(params) => <TextField {...params}
                                        multiline
                                        maxRows={3}
                                        minRows={1}
                                        placeholder="Enter title"
                                        inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (event) => {
                                                if (event.key === 'Enter') {
                                                    event.stopPropagation();
                                                    event.preventDefault();
                                                }
                                            },
                                        }}
                                    />}
                                    value={this.state.showTitle}
                                />
                            </Box>
                        }


                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.title_boolean_search}
                                        onChange={(event) => {
                                            this.setState((prev) => ({ title_boolean_search: !prev.title_boolean_search, }));
                                            event.target.checked ? this.setState({ title: this.state.showBooleanTitle }) : this.setState({ title: this.state.showNonBooleanTitle });
                                        }}
                                        name="title"
                                        color="primary"
                                    />
                                }
                                label="Title boolean search"
                            />
                            {/* <Divider
                                orientation="vertical"
                                style={{ height: "1.6em", width: ".14em", backgroundColor: "#d6d6e5", marginRight: "1em" }}
                            />
                            <FormControl>
                                <Select
                                    disabled={this.state.title_boolean_search ? this.state.showBooleanTitle.length === 0 ? true : false : this.state.showNonBooleanTitle.length === 0 ? true : false}
                                    IconComponent={ExpandMoreOutlined}
                                    className={CatalogueStyles.filterDropDown}
                                    value={this.state.cp}
                                    onChange={(e) => this.setState({ cp: e.target.value as string })}
                                >
                                    {dummyCP.map((cp, index) => (
                                        <MenuItem key={index} value={cp}>{cp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Box>
                    </Box>

                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Company</Typography>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center' className={CatalogueStyles.titleInput}>
                            <Box className={CatalogueStyles.filterIcon}>
                                <WorkOutlineOutlined /></Box>
                            <Autocomplete
                                className={CatalogueStyles.search}
                                classes={{ root: CatalogueStyles.inputRoot }}
                                multiple
                                disableClearable
                                noOptionsText={this.state.companyTest.length < 2 ? 'Please enter atleast 2 characters' : "No Data Found"}
                                loading={this.state.companyTest.length < 2 ? false : this.props.companyListLoadin}
                                options={this.state.companyTest.length < 2 ? [] : this.props.companyList?.map((option: any) => option) || []}
                                onFocus={() => {
                                    this.setState({ orOptionComp: true });
                                    //     this.props.onSuggessionSearch("company", "");
                                }}
                                onBlur={() => { this.setState({ orOptionComp: false }) }}
                                onInputChange={(event, newInputValue) => {
                                    this.setState({ companyTest: newInputValue })
                                    if (newInputValue.length > 1) {
                                        this.props.onSuggessionSearch("company", newInputValue);
                                    } else {
                                        this.props.onResetAuto('company');
                                    }
                                }}
                                onChange={(event, value) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    this.setState({ company: value?.join(" OR "), showCompany: [...value] });
                                    clearTimeout(timeoutVar);
                                    timeoutVar = setTimeout(() => {
                                        this.props.onSearch(this.state);
                                    }, 700)

                                }}
                                renderTags={(value, getTagProps) => {
                                    if (this.state.orOptionComp) {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                    else {
                                        return value?.map((option: string, index: number) => (
                                            <>
                                                <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                {index < value.length - 1 &&
                                                    <span className={CatalogueStyles.boolKeys}> OR </span>}
                                            </>
                                        ))
                                    }
                                }
                                }
                                renderInput={(params) => <TextField {...params}
                                    multiline
                                    maxRows={3}
                                    minRows={1}
                                    placeholder="Enter company name"
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (event) => {
                                            if (event.key === 'Enter') {
                                                event.stopPropagation();
                                                event.preventDefault();
                                            }
                                        },
                                    }}
                                />}
                                value={this.state.showCompany}
                            />
                        </Box>
                    </Box>

                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Location</Typography>
                        {this.state.location_boolean_search ?
                            <Box display="flex" position='relative' alignItems='center' >
                                <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                    <SearchIcon /></Box>
                                <Box className={CatalogueStyles.searchBiBox}>
                                    <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showTextLoc }}></Box>
                                    <TextField
                                        className={CatalogueStyles.searchHide}
                                        multiline
                                        placeholder="Enter location"
                                        onChange={(event) => {
                                            let receivedText: any = event.target.value;
                                            let boolArray = ['OR', 'or'];
                                            let forT = receivedText?.trim()?.split(" ")?.map((item: any) => {
                                                if (boolArray.includes(item)) {
                                                    return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                                } else return item
                                            });
                                            this.setState({ showTextLoc: forT?.join(" ") || "", showBooleanLoc: receivedText, location: receivedText });
                                        }}
                                        onBlur={() => { this.props.onSearch(this.state); }}
                                        onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                        value={this.state.showBooleanLoc}
                                    />
                                </Box>
                            </Box>
                            :
                            <Box display="flex" justifyContent="space-between" alignItems="center" className={CatalogueStyles.titleInput}>
                                <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                                    <Box className={CatalogueStyles.filterIcon}>
                                        <SearchIcon />
                                    </Box>
                                    <Autocomplete
                                        className={CatalogueStyles.search2}
                                        classes={{ root: CatalogueStyles.inputRoot }}
                                        multiple
                                        disableClearable
                                        noOptionsText={this.state.locationTest.length < 2 ? 'Please enter atleast 2 characters' : "No Data Found"}
                                        value={this.state.showLocation}
                                        loading={this.state.locationTest.length < 2 ? false : this.props.locationListLoadin}
                                        options={this.state.locationTest.length < 2 ? [] : this.props.locationList?.map((option: any) => option) || []}
                                        onFocus={() => {
                                            this.setState({ orOptionLoc: true });
                                            //   this.props.onSuggessionSearch("location", "");
                                        }}
                                        onBlur={() => { this.setState({ orOptionLoc: false }) }}
                                        onInputChange={(event, newInputValue) => {
                                            this.setState({ locationTest: newInputValue })
                                            if (newInputValue.length > 1) {
                                                this.props.onSuggessionSearch("location", newInputValue);
                                            } else {
                                                this.props.onResetAuto('location');
                                            }
                                        }}
                                        onChange={(event, value) => {

                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.setState({ location: value?.join(" OR "), showLocation: [...value] });
                                            clearTimeout(timeoutVar);
                                            timeoutVar = setTimeout(() => {
                                                this.props.onSearch(this.state);
                                            }, 700)
                                        }}
                                        renderTags={(value, getTagProps) => {
                                            if (this.state.orOptionLoc) {
                                                return value?.map((option: string, index: number) => (
                                                    <>
                                                        <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                        {index < value.length &&
                                                            <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                    </>
                                                ))
                                            }
                                            else {
                                                return value?.map((option: string, index: number) => (
                                                    <>
                                                        <Typography variant='body1' className={CatalogueStyles.searchTextTags}>{option}</Typography>
                                                        {index < value.length - 1 &&
                                                            <span className={CatalogueStyles.boolKeys}> OR </span>}
                                                    </>
                                                ))
                                            }
                                        }
                                        }
                                        renderInput={(params) => <TextField {...params}
                                            multiline
                                            maxRows={3}
                                            placeholder="Enter location"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (event) => {
                                                    if (event.key === 'Enter') {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                    }
                                                },
                                            }}
                                        />}
                                    />
                                </Box>
                            </Box>}
                        <Box display="flex" alignItems="center">
                            <FormControlLabel
                                className={CatalogueStyles.filterSubTitle}
                                control={
                                    <Checkbox
                                        checked={this.state.location_boolean_search}
                                        onChange={(event) => {
                                            this.setState((prev) => ({ location_boolean_search: !prev.location_boolean_search, }));
                                            event.target.checked ? this.setState({ location: this.state.showBooleanTitle }) : this.setState({ location: this.state.showNonBooleanTitle });
                                        }}
                                        name="location"
                                        color="primary"
                                    />
                                }
                                label="Location boolean search"
                            />
                        </Box>
                    </Box>


                    <Box className={CatalogueStyles.filterCardBox} >
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle} style={{ margin: "0px 0px 12px 0px" }}>Keyword</Typography>
                        <Box className={CatalogueStyles.searchBiBox}>
                            <Box className={CatalogueStyles.showSeachText} dangerouslySetInnerHTML={{ __html: this.state.showkeyword_OR_nameText }}></Box>
                            <TextField
                                className={CatalogueStyles.searchHide}
                                multiline
                                maxRows={3}
                                minRows={1}
                                placeholder="Enter Keyword"
                                onChange={(event) => {
                                    let receivedText = event.target.value;
                                    let boolArray = ['AND', 'OR', 'NOT', 'and', 'or', 'not'];
                                    let forT = receivedText?.trim()?.split(" ")?.map((item) => {
                                        if (boolArray.includes(item)) {
                                            return `<span style='color: #24325f;font-weight:600'>${item}</span>`
                                        } else return item
                                    });
                                    this.setState({ showkeyword_OR_nameText: forT?.join(" ") || "", keyword_OR_name: receivedText })
                                }}
                                onBlur={() => { this.props.onSearch(this.state); }}
                                onKeyPress={(event) => event.key == "Enter" && event.preventDefault()}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                                value={this.state.keyword_OR_name}
                            />
                        </Box>
                    </Box>

                    <Grid container className={CatalogueStyles.filterCardBox}>
                        {/* <Grid item lg={5} md={5} sm={5} xs={5}>
                            <Box>
                                <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle} style={{ margin: "0px 0px 12px 0px" }}>Currency</Typography>
                                <Box className={CatalogueStyles.filterIcon} display='flex' alignItems='center'>
                                    <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                        <JobIcon /></Box>
                                    <FormControl>
                                        <Select
                                            className={CatalogueStyles.filterDropDown}
                                            IconComponent={ExpandMoreOutlined}
                                            displayEmpty
                                            renderValue={this.state.status !== null ?
                                                undefined : () => "Choose Currency"}
                                            value={this.state.status !== null ? this.state.status : dummyStatus[0]}
                                            onChange={(e) => this.setState({ status: e.target.value as string })}
                                        >
                                            <MenuItem value={""}>Choose Currency</MenuItem>
                                            {dummyStatus.map((status, index) => (
                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid> */}
                        <Grid item lg={7} md={7} sm={7} xs={7}>
                            <Box style={{ marginRight: "4em" }}>
                                <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle} style={{ margin: "0px 0px 12px 0px" }}>Employment Type</Typography>
                                <Box className={CatalogueStyles.filterIcon} display='flex' alignItems='center'>
                                    <Box className={CatalogueStyles.filterIcon} height='1rem'>
                                        <JobIcon /></Box>
                                    <FormControl>
                                        <Select
                                            className={CatalogueStyles.filterDropDown}
                                            IconComponent={ExpandMoreOutlined}
                                            displayEmpty
                                            renderValue={this.state.employment_type !== null ?
                                                undefined : () => "Choose Type"}
                                            value={this.state.employment_type !== null ? this.state.employment_type : dummyStatus[0]}
                                            onChange={(e) => {
                                                this.setState({ employment_type: e.target.value as string });
                                                clearTimeout(timeoutVar);
                                                timeoutVar = setTimeout(() => {
                                                    this.props.onSearch(this.state);
                                                }, 700)
                                            }}>
                                            <MenuItem value={""}>Choose Type</MenuItem>
                                            {dummyStatus.map((status, index) => (
                                                <MenuItem key={index} value={status}>{status}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                    <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Job Posting Date</Typography>
                        <Box width='100%' >
                            <DateRangePicker
                                key={this.state.dateVal}
                                onApply={this.apply}
                                //onCancel={this.handleCancel}
                                initialSettings={{ maxDate: new Date(), autoUpdateInput: false, locale: { cancelLabel: 'Cancel', } }}
                            >
                                <input readOnly value={this.state.dateVal} type="text" placeholder="MM/DD/YYYY - MM/DD/YYYY" defaultValue="" className={CatalogueStyles.formControl} />
                            </DateRangePicker>
                        </Box>
                    </Box>

                    {/* <Box className={CatalogueStyles.filterCardBox}>
                        <Typography variant="h6" gutterBottom className={CatalogueStyles.filterTitle}>Salary Range</Typography>
                        <Box display="flex" width='100%' justifyContent='center' alignItems='center'>
                            <TextField
                                value={this.state.years_of_exp_from}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_from: '' })
                                    } else {
                                        this.setState({ years_of_exp_from: Number(e.target.value) })
                                    }

                                }}
                                type="number"
                                placeholder="Minimum" fullWidth
                                className={CatalogueStyles.yearsExp}
                            />
                            <TextField
                                value={this.state.years_of_exp_to}
                                onChange={(e) => {
                                    if (!e.target.value.trim().length) {
                                        this.setState({ years_of_exp_to: '' })
                                    } else {
                                        this.setState({ years_of_exp_to: Number(e.target.value) });
                                    }
                                }}
                                type="number"
                                placeholder="Maximum" fullWidth
                                className={CatalogueStyles.yearsExp}
                            />
                        </Box>
                            </Box> */}
                </Box>
                <Box
                    className={CatalogueStyles.filterReset}
                    display="flex"
                    alignItems='center'
                    justifyContent="center"
                    onClick={this.onReset}
                >
                    <Typography>
                        Reset
                    </Typography>
                </Box>
                <Box
                    className={CatalogueStyles.filterSearch}
                    display="flex"
                    flexDirection="column"
                    justifyContent={this.props.showSaveSearch ? "flex-end" : "center"}
                    onClick={this.onSearch}
                >
                    {this.props.showSaveSearch && (
                        <Box>
                            <Typography>
                                Save Search
                            </Typography>
                        </Box>
                    )}
                    <Box
                        className="search"
                        style={{
                            flex: this.props.showSaveSearch ? .5 : 1,
                            borderTopRightRadius: !this.props.showSaveSearch ? "1rem" : 0,
                        }}

                    >
                        <Typography>
                            Search
                        </Typography>
                    </Box>
                </Box>
            </Box >
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default JobFilter;
// Customizable Area End